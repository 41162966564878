import React, { useEffect, useState } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import SearchTerms from "@/app/modules/home/main/components/SearchTerms";

export default function SearchTermsModal({ show, onHide, reportProperties, reportFavorites }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [show]);
    
    return (
        <Modal title="Editar Filtros" show={show} onHide={onHide} isLoading={loading}>
            <SearchTerms
                reportProperties={(properties) => reportProperties(properties)}
                reportFavorites={(favorites) => reportFavorites(favorites)} />
        </Modal>
    );
}
