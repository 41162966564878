import {
  collection,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  writeBatch,
  doc,
  Timestamp,
  getDocs,
  getCountFromServer,
} from "firebase/firestore";
import Storage from "../../util/Storage";
import { Firebase } from "../BaseFirebase";
import { FireHelper } from "../../util/helpers/FireHelper";
import { ErrorHelper } from "../../util/helpers/ErrorHelper";
import { toast } from "react-toastify";
import { DateHelper } from "../../util/helpers/DateHelper";

export const ChatController = {
  db: getFirestore(Firebase),

  ToFirestoreMessage: function (data) {
    const isMissingContent =
      !data.text && (!Array.isArray(data.media) || data.media.length === 0);

    const isMissingSender =
      !data.sender || !data.sender.id || !data.sender.name;

    if (!data.chatId || isMissingContent || isMissingSender) {
      throw Error("ChatController: Invalid message payload");
    }

    const { sender, media } = data;

    let updatedData = {
      chatId: data.chatId,
      text: data.text,
      sender: sender
        ? {
            id: sender.id,
            name: sender.name,
            picture: sender.picture,
          }
        : null,
      media: Array.isArray(media)
        ? media.map((metadata) => ({
            url: metadata.url,
            name: metadata.name,
            contentType: metadata.contentType,
            fullPath: metadata.fullPath,
            bucket: metadata.bucket,
          }))
        : [],
      createdDate: data.createdDate ?? Timestamp.now(),
    };
    return FireHelper.ToFirestoreDoc(updatedData);
  },

  ToFirestoreChat: function (data) {
    let updatedData = {
      propertyId: data.propertyId,
      participantsIds: Array.isArray(data.participants)
        ? data.participants.map((participant) => participant.id)
        : [],
      participants: Array.isArray(data.participants)
        ? data.participants.map((participant) => ({
            id: participant.id,
            name: participant.name,
            picture: participant.picture,
          }))
        : [],
      lastMessage: data.lastMessage
        ? this.ToFirestoreMessage(data.lastMessage)
        : null,
      createdDate: data.createdDate ?? Timestamp.now(),
      updatedDate: Timestamp.now(),
    };
    return FireHelper.ToFirestoreDoc(updatedData);
  },

  GetChats: function (onChats) {
    const userData = Storage.getUserData();
    if (!userData) {
      onChats({ chats: [] });
      return;
    }
    let chatsQuery = query(
      collection(this.db, "chats"),
      where("lastMessage", "!=", null),
      where("participantsIds", "array-contains", userData.uid),
      orderBy("updatedDate", "desc")
    );

    return onSnapshot(
      chatsQuery,
      (snapshot) => {
        const chats = snapshot.docs.map((doc) => ({
          id: doc.id,
          peer: doc.data().participants.filter((p) => p.id !== userData.uid)[0],
          ...doc.data(),
        }));
        onChats({ chats });
      },
      (error) => {
        console.log("ChatController error", error);
        toast.error(ErrorHelper.HandleMessage(error));
      }
    );
  },

  GetOrCreateChat: async function (propertyId, peerId) {
    try {
      const userData = Storage.getUserData();
      if (!userData || userData.uid === peerId) {
        throw Error("Participantes inválidos");
      }
      const participantsIds = [userData.uid, peerId];
      const chatsQuery = query(
        collection(this.db, "chats"),
        participantsIds.map((pId) =>
          where("participantsIds", "array-contains", pId)
        )
      );

      // Found the chat, return it
      const chatSnapshots = await getDocs(chatsQuery);
      if (!chatSnapshots.empty) {
        const doc = chatSnapshots.docs[0];
        return doc.id;
      }

      // Query user docs
      const userDocs = await getDocs(
        query(collection(this.db, "users"), where("uid", "in", participantsIds))
      );
      const participants = userDocs.docs.map((doc) => ({
        id: doc.data.id,
        name: doc.data().name,
        picture: doc.data().picture,
      }));

      if (participants.length !== participantsIds.length) {
        throw new Error("Usuário não encontrado");
      }

      let chatData = this.ToFirestoreChat({
        participantsIds,
        participants,
        propertyId,
      });
      let chatRef = doc(collection(this.db, "chats"));
      let batch = writeBatch(this.db);

      batch.set(chatRef, chatData);
      await batch.commit();

      return chatRef.id;
    } catch (error) {
      console.log("ChatController error", error);
      toast.error(ErrorHelper.HandleMessage(error));
      return undefined;
    }
  },

  GetMessages: function (chatId, onMessages) {
    const userData = Storage.getUserData();
    if (!userData) {
      onMessages({ messages: [] });
      return;
    }
    let messagesQuery = query(
      collection(this.db, "messages"),
      where("chatId", "==", chatId),
      orderBy("createdDate", "desc"),
      limit(1000)
    );

    return onSnapshot(
      messagesQuery,
      (snapshot) => {
        const messages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        onMessages({ messages });
      },
      (error) => {
        console.log("ChatController error", error);
        toast.error(ErrorHelper.HandleMessage(error));
      }
    );
  },

  SendMessage: async function (data) {
    try {
      let messageData = this.ToFirestoreMessage(data);
      let messageRef = doc(collection(this.db, "messages"));
      let chatRef = doc(collection(this.db, "chats"), messageData.chatId);

      let batch = writeBatch(this.db);
      batch.set(messageRef, messageData);
      batch.update(chatRef, {
        lastMessage: messageData,
        updatedDate: Timestamp.now(),
      });

      await batch.commit();
      return true;
    } catch (error) {
      console.log("ChatController error", error);
      toast.error(ErrorHelper.HandleMessage(error));
      return false;
    }
  },

  GetAdminReportsByDate: async function (startDate, endDate) {
    const chatsQuery = query(
      collection(this.db, "chats"),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        chats: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(chatsQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        currentValue.chats += 1;
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },

  GetRealEstateReportsByDate: async function (realEstateId, startDate, endDate) {
    const userData = Storage.getUserData();
    if (!userData || !realEstateId) {
      return [];
    }

    const properties = await getDocs(
      query(
        collection(this.db, "properties"),
        where("realEstate", "==", realEstateId)
      )
    );
    const propertiesIds = properties.docs.map((u) => u.id);
    const chatsQuery = query(
      collection(this.db, "chats"),
      where("propertyId", "in", propertiesIds),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        chats: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(chatsQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        currentValue.chats += 1;
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },

  GetPropertyReportsByDate: async function (propertyId, startDate, endDate) {
    const userData = Storage.getUserData();
    if (!userData) {
      return [];
    }
    
    const chatsQuery = query(
      collection(this.db, "chats"),
      where("propertyId", "==", propertyId),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        chats: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(chatsQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        currentValue.chats += 1;
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },

  GetRealEstateChatsCount: async function (realEstateId) {
    const userData = Storage.getUserData();
    if (!userData || !realEstateId) {
      return 0;
    }
    const properties = await getDocs(
      query(
        collection(this.db, "properties"),
        where("realEstate", "==", realEstateId)
      )
    );
    const propertiesIds = properties.docs.map((doc) => doc.id);
    const snapshot = await getCountFromServer(
      query(
        collection(this.db, "chats"),
        where("propertyId", "in", propertiesIds)
      )
    );
    return snapshot.data().count;
  },
};
