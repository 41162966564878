import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import Field from "@/app/core/components/input/Field";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { AccountController } from "@/app/core/base/controllers/AccountController";

export default function SignIn(props) {
  const [authenticate, setAuthenticate] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmationToken, setConfirmationToken] = useState("");

  async function onSubmit(form) {}

  function tryTokenSubmit(key) {
    if (key === "Enter") {
      submitToken();
    }
  }

  function submitToken() {
    if (confirmationToken.length > 0) {
    }
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function login(e) {
    e.preventDefault();

    if (!data.email) {
      toast.error(Message.PendingData);
      return;
    }
    
    if (!data.password) {
      toast.error(Message.PendingData);
      return;
    }

    setLoading(true);
    await AccountController.Login(data);
    setLoading(false);
  }

  if (authenticate) return <Redirect to="/app" />;
  return (
    <div id="page-container">
      <main id="main-container">
        <div>
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a
                    className="link-fx font-w700 font-size-h1"
                    href="javascript:void()"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/media/logo.png"}
                      style={{ maxWidth: "150px" }}
                    />
                  </a>
                </div>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-8">
                    <form
                      className="js-validation-signin"
                      onSubmit={(e) => login(e)}
                    >
                      <div className="py-3">
                        <Field
                          name="email"
                          onChange={(evt) => handleChange(evt)}
                          title={"Email"}
                          value={data && data.email}
                          type={FieldTypeObj.Text}
                        />
                        <Field
                          name="password"
                          onChange={(evt) => handleChange(evt)}
                          title={"Senha"}
                          value={data && data.password}
                          type={FieldTypeObj.Password}
                        />
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-block btn-hero-lg btn-hero-warning"
                        >
                          <i
                            className={
                              loading === true
                                ? "fa fa-circle-notch fa-spin"
                                : "fa fa-fw fa-sign-in-alt mr-1"
                            }
                          />{" "}
                          Login
                        </button>
                        <p className="mt-3 mb-0 text-center">
                          <a
                            className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
                            onClick={() => (window.location = "#/recover")}
                          >
                            <i className="si si-lock text-muted mr-1" />{" "}
                            Recuperar senha
                          </a>
                        </p>
                        <p className="mt-3 mb-0 text-center">
                          <a
                            className="btn btn-sm btn-light d-block d-lg-inline-block mb-1"
                            onClick={() => (window.location = "#/register")}
                          >
                            <i className="fa fa-plus text-muted mr-1" /> Não tem
                            conta? Cadastre-se
                          </a>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center bg-image"
              style={{
                backgroundImage: "url('assets/media/photos/bg-intro.jpg')",
              }}
            >
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                  Bem Vindo!
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Habitaki &copy;{" "}
                  <span className="js-year-copy">
                    {new Date().getFullYear()}
                    -Desenvolvido pela{" "}
                    <a
                      className="text-white"
                      href="https://navigate.one"
                      target="_blank" rel="noreferrer"
                    >
                      Navigate
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer className="toastr" />
    </div>
  );
}
