import React from "react";
import Storage from "@/app/core/util/Storage";
import "../assets/footer.css";
import { MenuBase, MenuService } from "@/app/core/config/menu";

export default function Footer() {
  return (
    <footer
      id="page-footer"
      className={`bg-black text-body-${Storage.getTheme()} mt-5`}
    >
      <div className="content py-0">
        <div className="row py-5">
          <div className="col-md-4 border-right">
            <h3 className="text-white font-w700">Encontre imóveis</h3>
            {MenuBase.map(item => (
              <div key={item.id}><a className="btn btn-black mr-1 ml-1  text-white font-w500" href={item.routerLink}>{item.icon.length > 0 ? <i className={item.icon} /> : ""} {item.name}</a></div>
            ))}
          </div>
          <div className="col-md-4 border-white border-right">
            <h3 className="text-white font-w700">Atendimento</h3>
            {MenuService.map(item => (
              <div key={item.id}><a className="btn btn-black mr-1 ml-1  text-white font-w500" href={item.routerLink}>{item.icon.length > 0 ? <i className={`mr-2 ${item.icon}`} /> : ""} {item.name}</a></div>
            ))}
          </div>
          <div className="col-md-4 p-3">
            <img src={process.env.PUBLIC_URL + "/assets/media/logo-white.png"} style={{ height: 37 }} />

            <p className="text-white font-w500 mt-3">Realizando os sonhos de todo brasileiro</p>

            <div className="bg-white cursor img-avatar img-avatar48 text-center m-2">
              <i className="fab mt-1 fa-instagram text-dark btn-lg" />
            </div>
            <div className="bg-white cursor img-avatar img-avatar48 text-center m-2">
              <i className="fab mt-1 fa-youtube text-dark btn-lg" />
            </div>
          </div>
        </div>
        <div className="row font-size-sm mb-3">
          <div className="col-sm-4 order-sm-1 text-center text-sm-left">
            &copy; Habitaki{" "}
            <span data-toggle="year-copy">{new Date().getFullYear()}</span> - Todos os direitos reservados
          </div>
          <div className="col-sm-4 order-sm-1 text-center text-white">
            Desenvolvido pela <a href="https://navigate.one" className="text-white" target="_blank" rel="noreferrer">Navigate</a>
          </div>
          <div className="col-sm-4 order-sm-1 text-center text-sm-right">
            versão 0.0.0.1
          </div>
        </div>
      </div>
    </footer >
  );
}
