import Modal from "@/app/core/layouts/main/components/Modal";
import React, { useEffect, useState } from "react";
import Field from "@/app/core/components/input/Field";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { CategoriesController } from "@/app/core/base/controllers/CategoriesController";
import { FileController } from "@/app/core/base/controllers/FileController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { PlansController } from "@/app/core/base/controllers/PlanController";

export default function PlanModal({ show, onHide, plan }) {
    const date = new Date();
    const [url, setUrl] = useState("");
    const [optional, setOptional] = useState({});
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({ optionals: [] });
    const [change, setChange] = useState(date.getTime());

    useEffect(() => {
        if (show && plan && plan.id) {
            setData(plan);
        } else {
            setData({ optionals: [] })
        }
    }, [show]);

    function notifyChange() {
        setChange(date.getTime());
    }

    function handleRealChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }
    function handleOptionalChange(evt, type) {
        setOptional(OnDataChange(optional, evt, type));
    }

    async function save() {
        let error = false, message = "";

        if (!data.name) {
            error = true;
            message += "Nome; ";
        }
        if (error) {
            toast.error("Esta faltando preencher: " + message);
        } else {
            console.log("DAdos", data);
            let base = data;
            if (base.optionals === undefined || base.optionals.length === 0) {
                delete base.optionals;
            }
            setLoading(true);
            if (!data.id) {
                await PlansController.Insert(base, res => {
                    toast.success("Salvo com sucesso");
                });
            } else {
                await PlansController.Update(base, res => {
                    toast.success("Salvo com sucesso");
                });
            }
            onHide()
            setLoading(false);
        }
    }

    async function uploadIcon(file) {
        setLoading(true);
        await FileController.Upload(`categorias/opcionais/${file.name}`, file, response => {
            setUrl(response);
            setLoading(false);
        })
    }
    async function addOnBody(e) {
        e.preventDefault();
        let newData = data;
        if (newData.optionals === undefined) {
            newData.optionals = [];
        }
        newData.optionals.push({ ...optional, icon: url });
        setData(newData);
        notifyChange()
    }

    function removeItem(index) {
        if (window.confirm(Message.DeleteConfirm)) {
            let old = data;
            let newOptionals = [];
            for (let s in old.optionals) {
                if (parseInt(s) !== index) {
                    newOptionals.push(old.optionals[s]);
                }
            }

            setData({ ...old, optionals: newOptionals });
            notifyChange()
        }
    }

    return (
        <Modal title="Plano" show={show} onHide={onHide} isLoading={loading} onSubmit={() => save()}>
            <Field name="name" onChange={evt => handleRealChange(evt)} title={"Nome do plano"} value={data && data.name} type={FieldTypeObj.Text} />
            <Field name="value" onChange={evt => handleRealChange(evt, FieldTypeObj.Currency)} title={"Valor do plano"} value={data && data.value} type={FieldTypeObj.Currency} />
            <div className="block-header">
                <div className="block-title">
                    <div className="content-heading">
                        <h3>Opcionais</h3>
                    </div>
                </div>
                <div className="block-tools">
                    <Dropdown>
                        <Dropdown.Toggle className="btn btn-warning mt-4">
                            <i className="si si-plus" /> Adicionar diferencial
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <form onSubmit={(e) => addOnBody(e)}>
                                <Field type={FieldTypeObj.Text} name={"name"} value={optional && optional.name} title={"Nome"} onChange={evt => handleOptionalChange(evt)} />
                                {/* <span>Ícone</span> */}
                                {/* <input type="file" className="form-control" onChange={evt => uploadIcon(evt.target.files[0])} /> */}
                                <button type="submit" className="btn btn-success btn-block">Adicionar</button>
                            </form>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className="block-content">
                {change && data && data.optionals && data.optionals.map((item, index) => (
                    <div className="block block-rounded block-bordered">
                        <div className="block-header">
                            <div className="block-title"><img src={item.icon} style={{ width: 38 }} /> {item.name}</div>
                            <div className="block-tools"><button className="btn btn-dual" onClick={() => removeItem(index)}><i className="si si-close text-danger" /></button></div>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
}
