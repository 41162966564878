import React, { useEffect, useState } from "react";
import { ResumeCard } from "./administratorElements/ResumeCard";
import UsersReport from "./administratorElements/UsersReport";
import RealEstateAgencyReport from "./administratorElements/RealEstateAgencyReport";
import AdsReport from "./administratorElements/AdsReport";
import AdsStatusReport from "./administratorElements/AdsStatusReport";
import ProspectsAndConversionsReport from "./administratorElements/ProspectsAndConversionsReport";
import { DateHelper } from "@/app/core/util/helpers/DateHelper";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

export default function AdministratorPanel() {
  const [resume, setResume] = useState({
    ads: 0,
    users: 0,
    realtor: 0,
    realEstateAgency: 0,
  });

  useEffect(() => {
    loadCounter();
  }, []);

  async function loadCounter() {
    /* Usuários, Corretores, Imobiliárias, Anúncios */
    const results = await Promise.all([
      AccountController.GetTotalCountByLevel(UserHelper.UserLevel.User),
      AccountController.GetTotalCountByLevel(UserHelper.UserLevel.Realtor),
      RealEstateAgencyController.GetTotalCount(),
      PropertiesController.GetTotalCount(),
    ]);
    setResume({
      users: results[0],
      realtor: results[1],
      realEstateAgency: results[2],
      ads: results[3],
    });
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Contadores
            Gerais
          </h2>
        </div>
      </div>
      <div className="col-md-3">
        <ResumeCard title="Usuários" current={resume && resume.users} />
      </div>
      <div className="col-md-3">
        <ResumeCard title="Corretores" current={resume && resume.realtor} />
      </div>
      <div className="col-md-3">
        <ResumeCard
          title="Imobiliárias"
          current={resume && resume.realEstateAgency}
        />
      </div>
      <div className="col-md-3">
        <ResumeCard title="Anúncios" current={resume && resume.ads} />
      </div>

      <div className="col-12">
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Dashboard
          </h2>
        </div>
      </div>
      <div className="col-12">
        <UsersReport />
        <ProspectsAndConversionsReport />
        <RealEstateAgencyReport />
        <AdsReport />
        <AdsStatusReport />
      </div>
    </div>
  );
}
