import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import EditAgencyModal from "./components/EditAgencyModal";
import React, { useState, useEffect, useRef } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import { OnlyDateTranslate } from "@/app/core/util/DateTranslater";
import { ContactHelper } from "@/app/core/util/helpers/ContactHelper";
import { FileController } from "@/app/core/base/controllers/FileController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

export default function EmpresaComponent() {
  const userData = Storage.getUserData();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const [offices, setOffices] = useState([]);
  const inputFile = useRef(null);

  useEffect(() => {
    loadCompany();
  }, []);

  function onButtonClick() {
    inputFile.current.click();
  }

  async function uploadLogo(file) {
    FileController.Upload(
      `realEstateAgencies/logos/${data.id}/${file.name}`,
      file,
      async (res) => {
        const updatedData = { ...data, logo: res };
        await RealEstateAgencyController.Update(updatedData, (result) => {
          toast.success(Message.Saved);
          loadCompany();
        });
      }
    );
  }

  async function loadCompany() {
    // setLoading(true);
    await RealEstateAgencyController.Get(userData.realEstate, (res) => {
      console.log("Imóvel", res);
      if (res !== null) {
        setData({ ...res, id: userData.realEstate });
      }
    });
  }

  return (
    <>
      <div className="content">
        <div className="row mr-0 pr-0">
          <div className="col-md-4">
            <div className="block block-rounded block-bordered">
              <div className="block-content">
                <div className="text-center py-3">
                  <img
                    className="img-avatar img-avatar72 mr-3 img-avatar-thumb"
                    src={
                      data && data.logo
                        ? data.logo
                        : "assets/media/avatars/avatar12.jpg"
                    }
                    alt="Logo da empresa"
                    title="Logo da empresa"
                    onClick={() => onButtonClick()}
                  />
                  <br />
                  <button
                    className="btn btn-link"
                    onClick={() => onButtonClick()}
                  >
                    Trocar logo
                  </button>
                </div>
                <hr />
                <h5>Informações gerais</h5>
                <div className="ml-2">
                  <p>
                    <i className="si si-calendar mr-2" /> Membro desde{" "}
                    {data && OnlyDateTranslate(data.createdDate)}
                  </p>
                  <p>
                    <i className="si si-clock mr-2" /> Responde em 1h
                  </p>
                  <p>
                    <i className="si si-envelope-letter mr-2" />{" "}
                    {userData && userData.email}
                  </p>
                  <p>
                    <i className="si si-screen-smartphone mr-2" />{" "}
                    {data && ContactHelper.PhoneMask(data.phone)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <h2>Olá, {userData && userData.name}!</h2>
            <p className="font-w700 mb-0">Apresentação da empresa:</p>

            <p style={{ whiteSpace: "pre-line" }}>
              {data && data.bio}{" "}
              {data === null ? "Edite ser perfil para preencher a bio" : ""}
            </p>

            <hr />
            {/* <h5><i className="far fa-building mr-2" /> Escritórios</h5>
                        <div className="row">

                            <a className="col-md-4 block block-bordered border-success block-rounded block-link-shadow" href="javascript:void(0)">
                                <div className="block-content block-content-full ribbon ribbon-dark ribbon-modern">
                        <div className="pt-3 mb-0 pb-0 text-center">
                            <i className="fa fa-location-arrow fa-2x text-gray"></i>
                            <p className="text-muted mb-3">
                                Rua, complemento
                            </p>
                            <p className="font-size-sm font-w700 text-muted mb-0">
                                São Paulo - SP
                            </p>
                        </div>
                    </div>
                    <div className="block-content block-content-full">
                        <div className="row">
                            <div className="col-6 m-0 p-0">
                                <button className="btn btn-sm btn-hero btn-noborder btn-secondary btn-block" onClick={() => editPlace()}><i className="far fa-edit mr-2" /> Editar</button>
                            </div>
                            <div className="col-6 m-0 p-0">
                                <button className="btn btn-sm btn-hero btn-noborder btn-primary btn-block" onClick={() => removePlace()}><i className="si si-close text-danger mr-2" /> Remover</button>
                            </div>
                        </div>
                    </div>
                </a>
                <a className="col-md-4 block block-bordered border-success block-rounded block-link-shadow" href="javascript:void(0)">
                    <div className="block-content block-content-full ribbon ribbon-dark ribbon-modern">
                        <div className="pt-3 mb-0 pb-0 text-center">
                            <i className="si si-plus fa-2x"></i>
                            <p className="font-size-sm font-w700 text-muted mb-0">
                                Adicionar Escritório
                            </p>
                        </div>
                    </div>
                </a>
            </div> */}

            {userData && userData.role === UserHelper.Roles.Administrator ? (
              <button
                className="btn btn-warning mt-4"
                onClick={() => setShowModal(true)}
              >
                Editar perfil
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <EditAgencyModal
        show={showModal}
        realEstate={data}
        onHide={() => setShowModal(false) | loadCompany()}
      />
      <Loading loading={loading} />
      <ToastContainer className="toastr" />
      <input
        type="file"
        id="file"
        accept="image/*"
        ref={inputFile}
        onChange={(event) => {
          const parent = event.target.files;
          Array.prototype.forEach.call(parent, (file) => {
            uploadLogo(file);
          });
        }}
        style={{ visibility: "hidden" }}
      />
    </>
  );
}
