import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";
import ContactsAndConversionsOfAd from "./ContactsAndConversionsOfAd";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function AdManagementModal({ show, onHide, ad }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [favorites, setFavorites] = useState([]);
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        if (show && ad && ad.id) {
            setData(ad);
            // loadInfos();
        } else {
            setData({})
        }
    }, [show]);

    async function loadInfos() {
        // PropertiesController.ListRealEstateByAngency(data.id, res => setProperties(res));
    }

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    async function saveNewStatus(sts){
        let old = data;
        old.status = parseInt(sts);
        await PropertiesController.Update(old)
        toast.success(Message.Saved);
        setData(old);
    }

    return (
        <Modal title="Gerenciar Anúncio" show={show} onHide={onHide} isLoading={loading}>
            <div className="block block-header">
                <div className="block-title">Relatório</div>
                <div className="block-tools">
                    <div className="form-group">
                        <span>Status do anúncio</span>
                        <select className="form-control" defaultValue={data && data.status} onChange={(e) => saveNewStatus(e.target.value)}>
                            {RealtyHelper.StatusList.map(item => (
                                <option value={item.value} selected={data && data.status === item.value ? true : false}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <ContactsAndConversionsOfAd propertyId={data && data.id}/>
        </Modal>
    );
}
