import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ModalComponent(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header className="bg bg-dark" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter text-white"
          style={{ color: "white" }}
        >
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`block  block-mode-loading-dark block-mode-loading-refresh ${
          props.isLoading ? "block-mode-loading" : ""
        }`}
      >
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onSubmit()} disabled={props.disableSave}>
          Salvar
        </Button>
        {props && props.hideButton !== undefined ? "":
          <Button onClick={props.onHide}>Fechar</Button>
        }
      </Modal.Footer>
    </Modal>
  );
}
