import { toast } from "react-toastify";
import { Firebase } from "@/app/core/base/BaseFirebase";
import { ErrorHelper } from "../../util/helpers/ErrorHelper";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  increment,
  writeBatch,
  Timestamp,
  orderBy,
} from "firebase/firestore";
import { DataHelper } from "../../util/helpers/DataHelper";
import Storage from "../../util/Storage";
import { FireHelper } from "../../util/helpers/FireHelper";
import { DateHelper } from "../../util/helpers/DateHelper";

export const FavoritesController = {
  db: getFirestore(Firebase),

  ToFirestoreFavorite: function (data) {
    if (!data.userId || !data.propertyId) {
      throw new Error(
        "FavoritesController: userId and propertyId be null or undefined"
      );
    }

    let updatedData = {
      userId: data.userId,
      propertyId: data.propertyId,
      createdDate: Timestamp.now(),
    };

    return FireHelper.ToFirestoreDoc(updatedData);
  },

  Insert: async function (propertyId, res) {
    try {
      const userId = Storage.getUserData()?.uid;
      const favoriteRef = doc(this.db, "favorites", `${userId}_${propertyId}`);
      const propertyRef = doc(this.db, "properties", propertyId);

      let batch = writeBatch(this.db);
      batch.set(
        favoriteRef,
        this.ToFirestoreFavorite({ propertyId, ...DataHelper.BaseModal })
      );
      batch.update(propertyRef, {
        favoritesCount: increment(1),
      });

      // Commit the batch
      await batch.commit();

      res(favoriteRef.id);
    } catch (e) {
      console.log("FavoriteController error", e);
      toast.error(ErrorHelper.HandleMessage(e));
    }
  },

  GetFavorite: async function (propertyId, res) {
    const userId = Storage.getUserData()?.uid;
    if (!userId) {
      return;
    }

    const favoriteRef = doc(this.db, "favorites", `${userId}_${propertyId}`);
    const favoriteDoc = await getDoc(favoriteRef);
    if (!favoriteDoc.exists()) {
      return;
    }

    const favorite = { ...favoriteDoc.data(), id: favoriteDoc.id };
    res(favorite);
    return favorite;
  },

  ListMyFavorites: async function (res) {
    const favoritesQuery = query(
      collection(this.db, "favorites"),
      where("userId", "==", Storage.getUserData().uid),
      orderBy("createdDate")
    );
    const favoritesSnap = await getDocs(favoritesQuery);
    const results = favoritesSnap.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
    return results;
  },

  ListUserFavorites: async function (userId, res) {
    const favoritesQuery = query(
      collection(this.db, "favorites"),
      where("userId", "==", userId),
      orderBy("createdDate")
    );
    const favoritesSnap = await getDocs(favoritesQuery);
    const results = favoritesSnap.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
    return results;
  },

  QueryFavorites: async function (propertyIds) {
    const userId = Storage.getUserData()?.uid;
    if (!userId) {
      return [];
    }
    const q = query(
      collection(this.db, "favorites"),
      where("propertyId", "in", propertyIds),
      where("userId", "==", userId)
    );

    const querySnapshot = await getDocs(q);
    let results = [];
    querySnapshot.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });
    return results;
  },

  Delete: async function (propertyId, res) {
    const userId = Storage.getUserData().uid;
    const favoriteRef = doc(this.db, "favorites", `${userId}_${propertyId}`);
    const propertyRef = doc(this.db, "properties", propertyId);

    let batch = writeBatch(this.db);
    batch.delete(favoriteRef);
    batch.update(propertyRef, {
      favoritesCount: increment(-1),
    });

    // Commit the batch
    await batch.commit();
    res();
  },

  // Relatórios do administrador
  GetReportsByDate: async function (startDate, endDate) {
    const favoritesQuery = query(
      collection(this.db, "favorites"),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        favorites: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(favoritesQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        currentValue.favorites += 1;
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },

  // Relatórios da imobiliária
  GetRealEstateReportsByDate: async function (realEstateId, startDate, endDate) {
    const userData = Storage.getUserData();
    if (!userData || !realEstateId) {
      return [];
    }

    const properties = await getDocs(
      query(
        collection(this.db, "properties"),
        where("realEstate", "==", realEstateId)
      )
    );
    const propertiesIds = properties.docs.map((p) => p.id);
    const favoritesQuery = query(
      collection(this.db, "favorites"),
      where("propertyId", "in", propertiesIds),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        favorites: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(favoritesQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        currentValue.favorites += 1;
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },

  // Relatórios do imóvel
  GetPropertyByDate: async function (propertyId, startDate, endDate) {
    const userData = Storage.getUserData();
    if (!userData) {
      return [];
    }

    const favoritesQuery = query(
      collection(this.db, "favorites"),
      where("propertyId", "==", propertyId),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        favorites: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(favoritesQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        currentValue.favorites += 1;
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },
};
