import {
  XAxis,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  YAxis,
  CartesianGrid,
} from "recharts";
import ReportHelper from "@/app/core/util/helpers/ReportHelper";
import React, { useEffect, useState } from "react";
import { DateHelper } from "@/app/core/util/helpers/DateHelper";
import { AccountController } from "@/app/core/base/controllers/AccountController";

export default function UsersReport({}) {
  const date = new Date();
  const [data, setData] = useState({ total: 0, ticket: 0, count: 0 });
  const [startDate, setStartDate] = useState(
    DateHelper.GetFirstDayOfMonth(date.getFullYear(), 0)
  );
  const [endDate, setEndDate] = useState(
    DateHelper.GetLastDayOfMonth(date.getFullYear(), 11)
  );
  const reportHelper = ReportHelper();

  useEffect(() => {
    loadReport();
  }, [startDate, endDate]);

  async function loadReport() {
    let results = await AccountController.GetAdminReportsByDate(startDate, endDate);
    let filter = [];
    
    results.forEach((result) => {
      filter.push({
        year: result.year,
        month: result.month,
        usuarios: result.usuarios,
        corretores: result.corretores,
      });
    });

    process(filter);
  }

  function process(res) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        if (res[i].month === responses[s].value) {
          responses[s].usuarios = parseInt(res[i].usuarios);
          responses[s].corretores = parseInt(res[i].corretores);
        }
      }
    }
    console.log("Tickets", responses);
    setData(responses);
  }

  function processDate(year) {
    setStartDate(DateHelper.GetFirstDayOfMonth(year, 0));
    setEndDate(DateHelper.GetLastDayOfMonth(year, 11));
  }

  return (
    <div className="col-md-12" key={"ticketsReport"}>
      <div className="block block-rounded block-bordered">
        <ul className="nav nav-tabs nav-tabs-block align-items-center border-bottom">
          <li className="nav-item">
            <a
              className="nav-link text-body-color active"
              href="javascript:void(0)"
            >
              Cadastro de usuários
            </a>
          </li>

          <li className="nav-item ml-auto">
            <div className="block-options mr-3">
              <button
                className="btn btn-dual"
                onClick={() => processDate(date.getFullYear() - 2)}
              >
                {date.getFullYear() - 2}
              </button>
              <button
                className="btn btn-dual"
                onClick={() => processDate(date.getFullYear() - 1)}
              >
                {date.getFullYear() - 1}
              </button>
              <button
                className="btn btn-dual"
                onClick={() => processDate(date.getFullYear())}
              >
                {date.getFullYear()}
              </button>
            </div>
          </li>
        </ul>
        {data && data ? (
          <div className="block-content block-content-full">
            <ResponsiveContainer height={300} key={"ativos"}>
              <AreaChart
                width={400}
                height={400}
                data={data}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey={"usuarios"}
                  stroke={reportHelper.colors[6]}
                  fill={reportHelper.colors[6]}
                />
                <Area
                  type="monotone"
                  dataKey={"corretores"}
                  stroke={reportHelper.colors[5]}
                  fill={reportHelper.colors[5]}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
