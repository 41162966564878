import Storage from "@/app/core/util/Storage";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { FavoritesController } from "@/app/core/base/controllers/FavoriteController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import VideoModal from "./components/VideoModal";
import { StringHelper } from "@/app/core/util/helpers/StringHelper";
import { ChatController } from "@/app/core/base/controllers/ChatController";
import { number } from "yup";
import { CurrencyHelper } from "@/app/core/util/helpers/CurrencyHelper";
import ComplaintModal from "./components/ComplaintModal";
import ContactsAndConversionsOfAd from "./components/ContactsAndConversionsOfAd";
import AdManagementModal from "./components/AdManagementModal";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

export default function ImovelComponent(props) {
  const date = new Date();
  const userData = Storage.getUserData();
  const [change, setChange] = useState(date.getTime());
  const [loading] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [favorite, setFavorite] = useState(false);
  const [realEstate, setRealEstate] = useState({});
  const [owner, setOwner] = useState({});
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showComplainModal, setShowComplainModal] = useState(false);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [selected, setSelected] = useState("");
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [proposalValue, setProposalValue] = useState(null);
  // const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    loadProperty();
    loadFavorite();
  }, []);

  useEffect(() => {
    recordVisit();
  }, [data]);

  function notifyChange() {
    setChange(date.getTime());
  }

  async function recordVisit() {
    if (userData && data && data.id) {
      if (data.realEstate !== userData.realEstate) {
        await PropertiesController.RecordVisit(data.id);
      }
    }
  }

  async function loadProperty() {
    const propertyData = await PropertiesController.Get(props.match.params.key);
    if (propertyData) {
      document.textContent = `${propertyData.description}`;
      document.title = `Habitaki - ${
        propertyData.title || propertyData.formattedAddress
      }`;

      let baseAmenities = [];
      let images = [];
      for (let amenity of propertyData.amenities) {
        for (let optional of amenity.optionals) {
          if (optional.active === true) {
            baseAmenities.push(optional);
          }
        }
      }

      for (let img of propertyData.images) {
        images.push(img.url);
      }

      setImagesUrl(images);
      setAmenities(baseAmenities);

      if (propertyData.realEstate) {
        loadRealEstare(propertyData.realEstate);
      } else {
        loadOwner(propertyData.userId);
      }
    }
    setData(propertyData);
    notifyChange();
  }

  async function loadRealEstare(id) {
    RealEstateAgencyController.Get(id, (res) => {
      setOwner(res);
    });
  }

  async function loadOwner(id) {
    const user = await AccountController.GetUser(id);
    setOwner(user);
  }

  function openImage(item, index) {
    setIsOpen(true);
    setPhotoIndex(index);
  }

  async function loadFavorite() {
    await FavoritesController.GetFavorite(props.match.params.key, (res) => {
      setFavorite(res !== null);
    });
  }

  async function saveFavorite() {
    if (!userData) {
      toast.info("Faça login para adicionar aos favoritos");
      return;
    }
    if (favorite === false) {
      setFavorite(true);
      setData({ ...data, favoritesCount: data.favoritesCount + 1 });
      await FavoritesController.Insert(props.match.params.key, (res) => {});
    } else {
      setFavorite(false);
      setData({ ...data, favoritesCount: data.favoritesCount - 1 });
      await FavoritesController.Delete(props.match.params.key, (res) => {});
    }
  }

  function shareProperty() {
    const link = `https://wa.me//?text=Olá, veja que imóvel incrível achei no Habitaki: ${GetBaseUrl()}%23%2Fimovel/${
      props.match.params.key
    }`;
    window.open(link, "_blank");
  }

  function presentStory(index) {
    setSelected(data.stories[index].url);
    setShowModal(true);
  }

  async function showProposalModalDialog() {
    if (!userData || !data.userId) {
      throw Error("Não é possível enviar proposta");
    }

    // Show the proposal modal
    setShowProposalModal(true);
  }

  async function handleProposalSubmit() {
    if (!proposalValue || proposalValue === 0) {
      toast.error("Por favor, insira um valor para a proposta.");
      return;
    }
    if (data.minimalValue && proposalValue < data.minimalValue) {
      toast.error("Por favor, insira um valor maior para a proposta.");
      return;
    }

    const chatId = await ChatController.GetOrCreateChat(data.id, data.userId);
    if (chatId) {
      // Change location
      window.location = `#/chat?chatId=${chatId}`;

      let messageText = "";
      if (data.type === RealtyHelper.AdType.Buy) {
        messageText = `Olá ${
          owner && owner.name
        }, estou interessado no imóvel que você está anunciando e gostaria de saber mais detalhes. Tenho uma proposta de compra no valor de ${CurrencyHelper.Format(
          proposalValue
        )}. Se o valor for adequado para você, podemos marcar uma visita para conversar melhor.`;
      } else if (data.type === RealtyHelper.AdType.Rent) {
        messageText = `Olá ${
          owner && owner.name
        }, estou interessado no imóvel que você está anunciando e gostaria de saber mais detalhes. Tenho uma proposta de aluguel no valor de ${CurrencyHelper.Format(
          proposalValue
        )}. Se o valor for adequado para você, podemos marcar uma visita para conversar melhor.`;
      } else {
        messageText = `Olá ${
          owner && owner.name
        }, estou interessado no imóvel que você está anunciando e gostaria de saber mais detalhes. Tenho uma proposta no valor de ${CurrencyHelper.Format(
          proposalValue
        )}. Se o valor for adequado para você, podemos marcar uma visita para conversar melhor.`;
      }

      await ChatController.SendMessage({
        chatId: chatId,
        text: messageText,
        sender: {
          id: userData.uid,
          name: userData.name,
          picture: userData.picture,
        },
      });
    }

    setShowProposalModal(false);
  }

  function legibleName(name) {
    if (name && name.length > 10) {
      let newName = name.substr(0, 10) + "...";
      return newName;
    } else {
      return name;
    }
  }

  return (
    <>
      <div className="content">
        {isOpen && (
          <Lightbox
            reactModalStyle={{
              zIndex: 1031000,
              marginTop: 300,
              paddingTop: 300,
              "z-index": 1031000,
            }}
            enableZoom={true}
            mainSrc={imagesUrl[photoIndex]}
            nextSrc={imagesUrl[(photoIndex + 1) % imagesUrl.length]}
            prevSrc={
              imagesUrl[(photoIndex + imagesUrl.length - 1) % imagesUrl.length]
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + imagesUrl.length - 1) % imagesUrl.length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % imagesUrl.length)
            }
          >
            <button className="btn btn-square">has</button>
          </Lightbox>
        )}
        <div className="row">
          {/* Banner */}
          {data &&
          (data.status === RealtyHelper.Status.Done ||
            data.status === RealtyHelper.Status.Canceled) ? (
            <div className="col-12">
              <div className="block block-fx-pop block-bordered border-info mt-5">
                <div className="block-header">
                  <div className="block-title">
                    <i className="far fa-check-circle text-info mr-2" /> Esse
                    anúncio foi encerrado
                    <br />
                    <small>
                      Poderá consultar outros imóveis incríveis esperando por
                      você!
                    </small>
                  </div>
                  <div className="block-tools">
                    <a className="btn btn-hero-info btn-sm" href="#/pesquisa">
                      Clique aqui
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-6">
            <img
              src={data && data.images && data.images[0].url}
              className="py-1 cursor"
              style={{ objectFit: "cover", width: "100%", height: 500 }}
              onClick={() => openImage(null, 0)}
            />
          </div>
          <div className="col-6 row">
            {data &&
              data.images &&
              data.images
                .filter((item, index) => {
                  return index > 0 && index <= 4;
                })
                .map((item, index) => {
                  return (
                    <img
                      className="col-6 m-0 pl-0 py-1 cursor"
                      key={item.url}
                      src={item.url}
                      style={{ objectFit: "cover", width: "100%", height: 250 }}
                      onClick={() => openImage(item, index)}
                    />
                  );
                })}
          </div>
          {/* Banner FIM */}
          <div className="col-md-8 mt-3">
            <h1 className="m-0 mt-3">{data && data.title}</h1>
            <p className="m-0">
              {data && data.formattedAddress}
              {" - "}
              {RealtyHelper.TranslateAdType(data.type || 0)}
            </p>
            <div className="row">
              {data &&
                data.stories &&
                data.stories.map((item, index) => (
                  <div
                    key={data.images[index].url}
                    className="text-center ml-1 mr-1"
                  >
                    <img
                      className="img-avatar img-avatar-thumb ml-3 mr-3 border border-3x border-danger cursor"
                      src={data.images[index].url}
                      onClick={() => presentStory(index)}
                    />
                    <br />
                    {legibleName(item.name)}
                  </div>
                ))}
            </div>
            <p className="mt-4 mb-4" style={{ whiteSpace: "pre-line" }}>
              {data && data.description}
            </p>
            {change && amenities && amenities.length > 0 ? (
              <h2>O que esse imóvel oferece?</h2>
            ) : (
              ""
            )}
            {change && amenities && amenities.length > 0 ? (
              <div className="row mt-5">
                {amenities &&
                  amenities.map((item) => (
                    <div key={item.id} className="col-4">
                      <p className="m-2">
                        <img
                          src={item.icon}
                          style={{ height: 32 }}
                          className="mr-3"
                        />{" "}
                        {item.name}
                        {item.describe ? <span>{item.description}</span> : ""}
                      </p>
                    </div>
                  ))}
              </div>
            ) : (
              ""
            )}

            <div className="mt-5">
              <div className="">
                <div className="d-flex">
                  <a className="img-link" href="javascript:void(0)">
                    <img
                      className="img-avatar img-avatar72 mr-3 img-avatar-thumb"
                      src={
                        owner && owner.logo
                          ? owner.logo
                          : "assets/media/avatars/avatar12.jpg"
                      }
                      alt=""
                    />
                  </a>
                  <div>
                    <a
                      className="font-w600 font-size-h3 mt-1"
                      href="javascript:void(0)"
                    >
                      {owner && owner.name}
                    </a>
                    <br />
                    <span className="font-size-sm text-muted">
                      Anunciante desde dezembro de 2022
                    </span>
                  </div>
                </div>
                <div className="d-flex">
                  <span className="m-2">
                    <i className="si si-star mr-1 text-warning" /> 12 Avaliações{" "}
                  </span>
                  <span className="m-2">
                    <i className="si si-check mr-1 text-warning" /> Perfil
                    verificado{" "}
                  </span>
                </div>
                <p className="m-2" style={{ whiteSpace: "pre-line" }}>
                  <b>Bio:</b> {owner && owner.bio}
                </p>
                <p className="m-2">
                  <b>Taxa de resposta:</b> 100%
                </p>
                <p className="m-2">
                  <b>Tempo médio de resposta:</b> 1 hora
                </p>
                <button className="btn btn-outline-primary m-2">
                  Ver anúncios
                </button>
              </div>
              <span>
                <i className="fa fa-shield-alt mr-2" /> Seu contato é protegido
                e assegurado pelo Habitaki
              </span>
            </div>
          </div>
          <div className="col-md-4 mt-5">
            <button
              className="btn btn-white mt-3 mr-2"
              onClick={() => shareProperty()}
            >
              <i className="si si-share-alt mr-1" />
              Compartilhar
            </button>
            <button
              className="btn btn-white mt-3"
              onClick={() => saveFavorite()}
            >
              <i
                className={
                  favorite
                    ? "fa fa-heart mr-1 text-warning"
                    : "si si-heart mr-1"
                }
              />
              Favoritos
            </button>

            <div className="block block-fx-pop">
              <div className="block-header">
                <div className="block-title">
                  <h3>
                    {data && data.value && CurrencyHelper.Format(data.value)} /{" "}
                    <small>
                      {data &&
                        data.type !== undefined &&
                        RealtyHelper.TranslateAdType(data.type)}
                    </small>
                  </h3>
                </div>
                {data && data.favoritesCount > 0 ? (
                  <div className="block-tools">
                    {data.favoritesCount} Interessado(s)
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="block-content">
                {data &&
                  data.costs &&
                  data.costs.map((item) => (
                    <div
                      key={StringHelper.GenerateUniqueId()}
                      className="block block-rounded block-bordered"
                    >
                      <div className="m-2">
                        <div className="row">
                          <div className="col-6 font-w700">{item.name}</div>
                          <div className="col-6 text-right">
                            {CurrencyHelper.Format(item.value)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                <button
                  className="btn btn-block btn-warning btn-lg mb-4"
                  disabled={!userData || data.userId === userData.uid}
                  onClick={showProposalModalDialog}
                >
                  Enviar proposta
                </button>
              </div>
            </div>
            <div className="text-center">
              <button
                className="btn btn-dual"
                onClick={() => setShowComplainModal(true)}
              >
                <i className="far fa-flag mr-2" /> Denunciar esse anúncio
              </button>
            </div>
            {userData &&
            data &&
            (userData.level === UserHelper.UserLevel.Administrator ||
              userData.realEstate === data.realEstate ||
              userData.uid === data.userId) ? (
              <div className="text-center mt-3">
                <button
                  className="btn btn-info"
                  onClick={() => setShowReportModal(true)}
                >
                  <i className="si si-settings" /> Ver dados gerenciais
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* Proposal Modal */}
      {showProposalModal && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enviar Proposta</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowProposalModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Digite o valor da proposta"
                  value={proposalValue}
                  onChange={(e) => {
                    if (e.target.value) {
                      setProposalValue(parseFloat(e.target.value));
                    } else {
                      setProposalValue(null);
                    }
                  }}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowProposalModal(false)}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleProposalSubmit}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Loading loading={loading} />
      <ToastContainer className="toastr" />
      <VideoModal
        video={selected}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <ComplaintModal
        property={data && data}
        show={showComplainModal}
        onHide={() => setShowComplainModal(false)}
      />

      <AdManagementModal
        ad={data && data}
        show={showReportModal}
        onHide={() => setShowReportModal(false)}
      />
    </>
  );
}
