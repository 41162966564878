import React from "react";
import Layout from "@/app/core/layouts/main";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import MainDashboard from "./main";

const ProjectShell = (props) => <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>;

const Redirecter = () => <Redirect to="/error/404" />;

const ImovelRouter = {
    path: "/imovel/:key",
    component: ProjectShell,
    routes: [
        {
            path: "/imovel/:key",
            exact: false,
            component: MainDashboard,
        },
        {
            path: "*",
            component: Redirecter,
        },
    ],
};

export default ImovelRouter;
