import { Firebase } from "@/app/core/base/BaseFirebase";
import { toast } from "react-toastify";
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, getDoc, query, where, deleteDoc, Timestamp } from "firebase/firestore";
import { ErrorHelper } from "../../util/helpers/ErrorHelper";

export const InviteController = {
    db: getFirestore(Firebase),

    Insert: async function (data, res) {
        try {
            const inviteRef = collection(this.db, "invites");
            const result = await addDoc(inviteRef, { ...data, updatedDate: Timestamp.now() });

            res(result.id);
        } catch (e) {
            console.log("ERRo", e)
            toast.error(ErrorHelper.HandleMessage(e))
        }
    },
    
    Update: async function (data, res) {
        try {
            const frankDocRef = doc(this.db, "invites", data.id);
            await updateDoc(frankDocRef, { ...data, updatedDate: Timestamp.now() });
        } catch (e) {
            console.log("ERRo", e)
            toast.error(ErrorHelper.HandleMessage(e))
        }
    },

    Get: async function (id, res) {
        res(await this.request(doc(this.db, "invites", id)));
    },

    List: async function (realEstate, res) {

        const q = query(collection(this.db, "invites"), where("realEstate", "==", realEstate));
        const querySnapshot = await getDocs(collection(this.db, "invites"));
        let results = [];
        querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id });
        });

        res(results);
    },
    Delete: async function (id, res) {
        await deleteDoc(doc(this.db, "invites", id));
        res()
    },

    request: async function (userRef) {
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
            let data = docSnap.data();
            data.id = docSnap.id;
            return data;
        } else {
            // docSnap.data() will be undefined in this case
            return null;
        }

    },
}