import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import PropTypes from "prop-types";
import HeaderOptions from "./HeaderOptions";
import Grid from "./Grid";

export default function Table(props) {
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState("name");
  const [direction, setDirection] = useState("ASC");
  const [filter, setFilter] = useState({
    page: page,
    limit: 100,
    totalRows: 100,
  });

  useEffect(() => {
    setFilter((prevState) => {
      console.log("estado", prevState);
      return {
        ...prevState,
        page: page,
        orderBy: orderBy ? orderBy : "name",
        direction,
      };
    });
  }, [direction, orderBy, page]);

  useEffect(() => {
    if (props.setFilter) {
      props.setFilter(filter);
    }
  }, [filter, props]);

  useEffect(() => {
    setPage(1);
  }, [props.data]);

  function changeOrderAndDirection(column, direction) {
    setOrderBy(column);
    setDirection(direction);
    // setPage(1);
  }

  return (
    <div className="col-xl-12" data-toggle="appear" data-timeout="200">
      <div
        className={`block block-rounded block-themed block-transparent bg-${Storage.getTheme()}-25 block-mode-loading-${Storage.getTheme()} block-mode-loading-refresh ${props.loading ? "block-mode-loading" : ""
          }`}
        style={{ height: "94%", ...props.style }}
      >
        {!props.hideHeader && (
          <div className={`block-header bg-${Storage.getTheme()}-25`}>
            <h3 className={`block-title text-themed-${Storage.getTheme()}`}>
              {props.title}
            </h3>
            <HeaderOptions
              refreshData={props.refreshData}
              loading={props.loading}
              filterIsDirty={props.filterIsDirty}
              hasFilters={props.hasFilters}
              aditionalOptions={props.aditionalOptions}
              hasDownload={props.hasDownload}
              insertAction={props.insertAction}
            />
          </div>
        )}
        <div
          className="block-content"
          style={{ height: "78%", paddingBottom: "30px" }}
        >
          <Grid
            orderBy={(column, direction) =>
              changeOrderAndDirection(column, direction)
            }
            ordered={orderBy}
            direction={direction}
            setPageNumber={(page) => setPage(page)}
            editAction={(data) => props.editAction(data)}
            viewAction={(data) => props.viewAction(data)}
            deleteAction={(data) => props.deleteAction(data)}
            columns={props.columns}
            data={props.data && props.data}
            filter={filter}
            hasEditButton={props.editAction}
            hasDeleteButton={props.deleteAction}
            hasViewButton={props.viewAction}
            hide={props.hide}
            tableId={props.tableId}
            limit={props.limit}
          />
        </div>
      </div>
    </div>
  );
}

Table.propTypes = {
  filter: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
  hasFilters: PropTypes.bool,
};
