import Storage from "@/app/core/util/Storage";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";
import MegaBanner from "./components/megaBanner";
import CategoriesShowcase from "./components/CategoriesShowcase";
import PopularRealty from "./components/PopularRealty";
import PopularHouse from "./components/PopularHouses";
import RealEstateCall from "./components/RealEstateCall";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import { Firebase } from "@/app/core/base/BaseFirebase";

export default function HomeComponent() {
  const [loading] = useState(false);
  const [location, setLocation] = useState(null);

  const defaultLocation = {
    lat: -23.6333584,
    lng: -47.0066096,
  };
  const messaging = getMessaging();
  const userData = Storage.getUserData();
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    subscribeFcm();
    await initFcm();
    await initLocation();
    await saveLastAccess();
  }

  async function initFcm() {
    try {
      if (!userData) {
        return;
      }
      if (Notification.permission !== "granted") {
        const permission = await Notification.requestPermission();
        if (permission !== "granted") {
          console.log("Notification permission denied");
          return;
        }
      }

      const token = await getToken(messaging, {
        vapidKey:
          "BM6SG7AzF_4f1A1c0OrGkYEhq81hLCZcD7w7ViqLgwVCfj_BYMw8PXt54zqrZSIuGVW4kRO-kygwW706_MwJWBU",
      });

      // console.log("FCM Token:", token);
      await AccountController.SaveFcmToken(token);
    } catch (error) {
      console.error("Error getting permission", error);
    }
  }

  async function initLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log("Retrieved user's location", position);
          const updatedLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(updatedLocation);
          Storage.setLastLocation(updatedLocation);
        },
        (error) => {
          console.log("Failed to get user's location", error);
          const lastLocation = Storage.getLastLocation();
          if (lastLocation) {
            setLocation(lastLocation);
          } else {
            setLocation(defaultLocation);
          }
        },
        {
          timeout: 10000,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setLocation(defaultLocation);
    }
  }

  function subscribeFcm() {
    onMessage(messaging, (payload) => {
      if (!window.location.hash.startsWith(`#/chat?`)) {
        toast.success("Você recebeu uma nova mensagem");
        console.log("Message received. ", payload);
        showNotification(payload);
      }
    });
  }

  async function saveLastAccess() {
    if (!userData || !userData.uid) {
      return;
    }
    await AccountController.UpdateLastAccess(userData.uid);
  }

  const showNotification = (payload) => {
    console.log("Showing notification with payload:", payload);
    new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: payload.notification.icon,
    });
  };

  return (
    <>
      <MegaBanner />
      <CategoriesShowcase />
      <PopularRealty location={location} />
      <PopularHouse location={location} />
      {/* <RealEstateCall /> */}
      <Loading loading={loading} />
      <ToastContainer className="toastr" />
    </>
  );
}
