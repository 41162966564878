import { toast } from "react-toastify";
import { Firebase } from "@/app/core/base/BaseFirebase";
import { ErrorHelper } from "../../util/helpers/ErrorHelper";
import {
    getFirestore,
    collection,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    getDoc,
    deleteDoc,
    Timestamp,
} from "firebase/firestore";
import { FireHelper } from "../../util/helpers/FireHelper";
import { StringHelper } from "../../util/helpers/StringHelper";

export const CategoriesController = {
    db: getFirestore(Firebase),

    ToFirestoreCategory: function (data) {
        let updatedData = {
          id: data.id,
          name: data.name,
          count: data.count,
          optionals:
            data.optionals &&
            data.optionals.map((op) => ({
              id: op.id || StringHelper.GenerateUniqueId(),
              name: op.name,
              describe: op.describe,
              highlight: op.highlight,
              message: op.message,
              icon: op.icon,
            })),
          createdDate: data.createdDate ?? Timestamp.now(),
          updatedDate: Timestamp.now(),
        };

        return FireHelper.ToFirestoreDoc(updatedData);
    },

    Insert: async function (data, res) {
        try {
            const inviteRef = collection(this.db, "categories");
            const result = await addDoc(inviteRef, this.ToFirestoreCategory(data));
            res(result.id);
        } catch (e) {
            console.log("CategoriesController error", e)
            toast.error(ErrorHelper.HandleMessage(e))
        }
    },

    Update: async function (data, res) {
        try {
            const frankDocRef = doc(this.db, "categories", data.id);
            await updateDoc(frankDocRef, this.ToFirestoreCategory(data));
        } catch (e) {
            console.log("CategoriesController error", e)
            toast.error(ErrorHelper.HandleMessage(e))
        }
    },

    Get: async function (id) {
        const docSnap = await getDoc(doc(this.db, "categories", id));
        if (docSnap.exists()) {
            let data = docSnap.data();
            data.id = docSnap.id;
            return data;
        } else {
            return null;
        }
    },

    List: async function (res) {
        const querySnapshot = await getDocs(collection(this.db, "categories"));
        let results = [];
        querySnapshot.forEach((doc) => {
            results.push({ ...doc.data(), id: doc.id });
        });
        res(results);
    },

    Delete: async function (id, res) {
        await deleteDoc(doc(this.db, "categories", id));
        res()
    },
}