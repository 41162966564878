export const DateHelper = {
  GetFirstDayOfMonthByDate: function (date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    return this.GetFirstDayOfMonth(year, month);
  },

  GetLastDayOfMonthByDate: function (date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    return this.GetLastDayOfMonth(year, month);
  },

  GetFirstDayOfMonth: function (year, month) {
    return new Date(year, month, 1);
  },

  GetLastDayOfMonth: function (year, month) {
    let lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    return new Date(year, month, lastDayOfMonth);
  },

  AddMonths: function (date, value) {
    let newDate = new Date(date);
    newDate.setMonth(date.getMonth() + value);
    return newDate;
  },
};
