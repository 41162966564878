export const Json = [
  {
    id: "3f8a2104-8dfd-4a36-bc73-474d8d123d4a",
    name: "Home",
    icon: "si-cursor",
    routerLink: "#/",
  },
  {
    id: "7e19c0e8-2d4b-420a-a9d3-1d3b4d197e9d",
    name: "Clientes",
    routerLink: "#/customer",
    icon: "si-users",
  },
  {
    id: "5674a4e1-1f54-45e2-91a8-9e44ab239b0f",
    name: "Painel Supervisor",
    routerLink: "#/supervisor",
    icon: "si-calendar",
  },
  {
    id: "71c9a3cf-5de5-442e-b9e5-417d8d31e5a2",
    name: "Ajustes",
    type: "over-text",
  },
  {
    id: "809e593d-bb74-4a90-9c8a-957c97d52725",
    name: "Perfil",
    routerLink: "#/profile",
    icon: "si-user",
  },
  {
    id: "f2753a36-6b0d-4c44-8756-23140d1ab0c2",
    name: "Disponibilidade",
    routerLink: "#/available-cities",
    icon: "si-map",
  },
  {
    id: "ab5f8428-c58b-4e3e-8b68-8679b9c1d67b",
    name: "Configurações",
    routerLink: "#/settings",
    icon: "si-settings",
  },
];

export const MenuBase = [
  {
    id: "f024f5e6-51b2-4e9a-80c0-6c732028eb7e",
    name: "Home",
    routerLink: "#/",
    icon: "",
  },
  {
    id: "5e3a13d1-61c1-48ae-9e0f-d13ae6e83e3a",
    name: "Comprar",
    routerLink: "#/pesquisa?type=comprar",
    icon: "",
  },
  {
    id: "12c13b9f-7379-4b7f-84c7-21a6beae7ad5",
    name: "Alugar",
    routerLink: "#/pesquisa?type=alugar",
    icon: "",
  },
  {
    id: "b20a51cc-992c-4e5e-9f30-2db9d1549b17",
    name: "Anunciar imóvel",
    routerLink: "#/anunciar-imovel",
    icon: "",
  },
];

export const MenuService = [
  {
    id: "43a2b0ee-68d0-4f56-9b57-10e251cb1241",
    name: "contato@habitaki.com.br",
    routerLink: "mailto:contato@habitaki.com.br",
    icon: "si si-envelope",
  },
  {
    id: "7c939ae3-05d2-4f76-8e85-bc13595b7f54",
    name: "Horário de segunda a sexta Das 9hs ás 18hs",
    routerLink: "mailto:contato@habitaki.com.br",
    icon: "si si-clock",
  },
];

export const MenuRealtor = [
  {
    id: "8a18f3f4-77b1-47e3-bf70-fc2ab8a63413",
    name: "Anunciar imóvel",
    routerLink: "#/anunciar-imovel",
    icon: "si si-home",
  },
  {
    id: "6e8c8bfa-2178-4a47-9a5f-e6b32e7f17d6",
    name: "Painel do corretor",
    routerLink: "#/meu-painel",
    icon: "si si-pie-chart",
  },
  {
    id: "d9152387-6177-4c24-9a0c-5b8e8b39410a",
    name: "Meus anúncios",
    routerLink: "#/anuncios",
    icon: "si si-tag",
  },
  {
    id: "43c229ba-b4e0-4d48-b7ed-178f2de2e958",
    name: "Chat",
    routerLink: "#/chat",
    icon: "si si-briefcase",
  },
  // {
  //   id: "c9b4e78d-78c3-422d-a092-c9a2e301c431",
  //   name: "Notificações",
  //   routerLink: "#/notificacoes",
  //   icon: "",
  // },
  {
    id: "e889c255-9b87-4b4f-a40c-8764949d1487",
    name: "Perfil",
    routerLink: "#/perfil",
    icon: "si si-user",
  },
];

export const MenuRealEstate = [
  {
    id: "1e1df312-3d17-46b1-8b57-b74f263f30de",
    name: "Anunciar imóvel",
    routerLink: "#/anunciar-imovel",
    icon: "si si-home",
  },
  {
    id: "7bb75058-d074-4a5e-bd7c-4c5d63f8c1e7",
    name: "Dashboard",
    routerLink: "#/dashboard",
    icon: "si si-pie-chart",
  },
  {
    id: "8b356bf1-fd32-4c22-b8af-b3d56f4173b8",
    name: "Meus anúncios",
    routerLink: "#/anuncios",
    icon: "si si-tag",
  },
  {
    id: "506f80bc-3d3b-4519-851f-2ff8bc6a23f4",
    name: "Chat",
    routerLink: "#/chat",
    icon: "si si-briefcase",
  },
  {
    id: "2252ea35-2d6a-4e1f-bf09-b34d2b5d9d4b",
    name: "Usuários",
    routerLink: "#/corretores",
    icon: "si si-users",
  },
  {
    id: "7e7b7b60-e657-4d62-9f78-94261a8f40a8",
    name: "Perfil",
    routerLink: "#/perfil",
    icon: "si si-user",
  },
  {
    id: "0a5f735d-d0cb-4b16-a965-5d290dba7922",
    name: "Empresa",
    routerLink: "#/empresa",
    icon: "far fa-building",
  },
];

export const MenuUser = [
  {
    id: "ff7fcff4-18a8-44c6-b1af-0d0f17c44af3",
    name: "Anunciar imóvel",
    routerLink: "#/anunciar-imovel",
    icon: "si si-home",
  },
  {
    id: "7b6ff3eb-2d65-450f-8435-1082b5785980",
    name: "Meus anúncios",
    routerLink: "#/anuncios",
    icon: "si si-tag",
  },
  {
    id: "8b8f5710-0e95-41c5-843e-1f48d0f16e39",
    name: "Chat",
    routerLink: "#/chat",
    icon: "si si-briefcase",
  },
  {
    id: "d2dbbe49-6ae2-464a-b6cf-d55d4c504b79",
    name: "Favoritos",
    routerLink: "#/favoritos",
    icon: "si si-heart",
  },
  {
    id: "1d5e26d2-02eb-4d02-a83c-10d52556a5b1",
    name: "Perfil",
    routerLink: "#/perfil",
    icon: "si si-user",
  },
];

export const MenuAdministrator = [
  {
    id: "b4f9b52e-13ae-4e29-ae84-cdbf9cfbb17e",
    name: "Dashboard",
    routerLink: "#/dashboard",
    icon: "si si-bar-chart",
  },
  {
    id: "b4f9b52e-13ae-4e29-ae84-cdbf9cfbb17e",
    name: "Anúncios",
    routerLink: "#/gerenciador-de-anuncios",
    icon: "si si-basket-loaded",
  },
  {
    id: "b4f9b52e-13ae-4e29-ae84-cdbf9cfbb17e",
    name: "Usuários",
    routerLink: "#/users",
    icon: "si si-users",
  },
  {
    id: "b4f9b52e-13ae-4e29-ae84-cdbf9cfbb17e",
    name: "Imobiliárias",
    routerLink: "#/realEstateAgencies",
    icon: "si si-briefcase",
  },
  {
    id: "4d0de9d7-5c7e-4f7e-bcf5-8a540d09cb79",
    name: "Configurações",
    routerLink: "#/settings",
    icon: "si si-settings",
  },
  {
    id: "833cd732-bc24-4e68-972b-7dbbcc09db33",
    name: "Perfil",
    routerLink: "#/perfil",
    icon: "si si-user",
  },
];
