// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDy3QMIiEJ8bUz3L-jCV7jGFlKUUfKge5c",
  authDomain: "habitaki-e1021.firebaseapp.com",
  databaseURL: "https://habitaki-e1021-default-rtdb.firebaseio.com",
  projectId: "habitaki-e1021",
  storageBucket: "habitaki-e1021.appspot.com",
  messagingSenderId: "398935843584",
  appId: "1:398935843584:web:da7fc2888985a7e24f49e5",
  measurementId: "G-KFMECTDGCW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const Firebase = app;
export const Analytics = analytics;
