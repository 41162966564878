import Storage from "../Storage";
import { FieldTypeObj } from "./FieldTypeHelper";
import { Timestamp } from "firebase/firestore";

export function OnDataChange(data, evt, type) {
  const { name, value, checked, files } = evt.target;
  const isNullOrUndefinedOrEmpty = (val) => val === null || val === "";

  let info;
  switch (type) {
    case FieldTypeObj.Currency:
    case FieldTypeObj.Decimal:
      info = {
        [name]: isNullOrUndefinedOrEmpty(value) ? null : parseFloat(value),
      };
      break;
    case FieldTypeObj.Number:
      info = {
        [name]: isNullOrUndefinedOrEmpty(value) ? null : parseInt(value),
      };
      break;
    case FieldTypeObj.Bool:
      info = { [name]: value === true || value === "true" };
      break;
    case FieldTypeObj.Checkbox:
      info = { [name]: checked === true };
      break;
    case FieldTypeObj.Files:
      info = { [name]: files };
      break;
    case FieldTypeObj.Area:
    case FieldTypeObj.Text:
    default:
      info = { [name]: value };
  }

  return { ...data, ...info };
}

export const DataHelper = {
  BaseModal: {
    userId: Storage.getUserData() !== null ? Storage.getUserData().uid : null,
    createdDate: Timestamp.now(),
    updatedDate: Timestamp.now(),
  },
};
