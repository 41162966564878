import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import SearchComponent from "./components/search";
import MainComponent from "./components/main";
import Layout from "@/app/core/layouts/main";
import Storage from "@/app/core/util/Storage";

const ProjectShell = props => Storage.isAuthenticated() ? (
  <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>
) : <Redirect to="/" />;

const Redirecter = () => <Redirect to="/error/404" />;

export const DashboardRouter = {
  path: "/dashboard",
  component: ProjectShell,
  routes: [
    {
      path: "/dashboard",
      exact: true,
      component: MainComponent
    },
    {
      path: "/dashboard/search",
      exact: true,
      component: SearchComponent
    },
    {
      path: "*",
      component: Redirecter
    }
  ]
};
