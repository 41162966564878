import Storage from "@/app/core/util/Storage";
import CorrectorReport from "./CorrectorReport";
import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";

export default function AnunciarImovelComponent() {
    const userData = Storage.getUserData();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [data, setData] = useState({});

    function onUploadClick() { }

    return (
        <>
            <div className="content">
                <div className="row mr-0 pr-0">
                    <div className="col-md-12 row mb-3">
                        <img
                            className="img-avatar img-avatar96 img-avatar-thumb text-center"
                            src={
                                data && data.picture !== null
                                    ? data.picture
                                    : "assets/media/avatars/avatar10.jpg"
                            }
                            style={{ textAlign: "center" }}
                            onClick={(evt) => onUploadClick()}
                            // alt="Add logo"
                            title="Personalize com seu logo aqui"
                        />
                        <button className="btn btn-white btn-rounded btn-sm" style={{ marginLeft: -25, marginTop: 75 }} onClick={() => onUploadClick()} ><i className="fa fa-camera" /></button>
                        <h3 className="mb-0 ml-3 mt-4"><strong className="font-w700">Seja bem vindo! {userData && userData.firstName}</strong>
                            <br />
                            <small className="text-muted">Código de cliente</small>
                        </h3>
                    </div>
                    <div className="col-md-6 ml-0 mr-0 p-0">
                        {/* Relatório */}
                        <CorrectorReport />
                    </div>
                    <div className="col-md-6 row ml-0 mr-0">
                        <div className="col-md-6 ml-0 mr-0 pr-1 pl-1">
                            <div className="block block-fx-pop bg-gd-sun block-roundedMax mb-3 pb-3 ml-1 cursor" style={{ height: 210 }} onClick={() => null}>
                                <div className="block-header">
                                    <div className="block-title">
                                        <i className="far fa-chart-bar text-white fa-2x"></i>
                                    </div>
                                    <div className="block-tools">
                                        <i className="si si-question text-white" title="Acesse todos os orçamentos em Aberto com um clique!" />
                                    </div>
                                </div>
                                <div className="block-content text-white">
                                    <div>
                                        <h2 className="text-white mt-3">8 Mensagens</h2>
                                        Não lidas
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 ml-0 mr-0 pr-1 pl-1 cursor" onClick={() => window.location = `#/reminders`}>
                            <div className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1" style={{ height: 210 }}>
                                <div className="block-header">
                                    <div className="block-title">
                                        <i className="si si-home fa-2x"></i>
                                    </div>
                                    <div className="block-tools">
                                        <i className="si si-question" title="Com nossa parceria você garante a regularidade cadastral de todos os seus clientes" />
                                    </div>
                                </div>
                                <div className="block-content">
                                    <h2 className="mb-1">8 Anúncios ativos</h2>
                                    2 concluídos
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        {/* Tabelas */}
                    </div>
                    <div className="col-md-3">
                        {/* <ManagerCard customerId={props.match.params.key} /> */}
                    </div>
                </div>
            </div>
            <Loading loading={loading} />
            <ToastContainer className="toastr" />
        </>
    );
}
