import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Field from "@/app/core/components/input/Field";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { Tab, Tabs } from "react-bootstrap";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import RealtyInLine from "@/app/core/layouts/main-components/realty/RealtyInLine";
import OwnerRealtyCard from "@/app/core/layouts/main-components/realty/OwnerRealtyCard";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { FavoritesController } from "@/app/core/base/controllers/FavoriteController";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import { Message } from "@/app/core/util/messages/GeralMessages";

export default function UserManagementModal({ show, onHide, user }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [favorites, setFavorites] = useState([]);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    if (show && user && user.id) {
      setData(user);
      loadInfos();
    } else {
      setData({});
    }
  }, [show]);

  async function loadInfos() {
    PropertiesController.ListUserProperties(user.uid, (res) =>
      setProperties(res)
    );
    FavoritesController.ListUserFavorites(user.uid, async (res) => {
      if (Array.isArray(res)) {
        let propertyIds = res.map((item) => item.propertyId);
        if (propertyIds.length !== 0) {
          setFavorites(await PropertiesController.ListByIds(propertyIds));
        } else {
          setFavorites([]);
        }
      }
    });
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  async function save() {
    let error = false,
      message = "";
  }

  async function changeUserLevel(evt){
    let old = data;
    old.level = parseInt(evt.target.value);

    console.log("Itens ", old);

    await AccountController.Update(old);
    toast.success(Message.Saved);
    setData(old);
  }

  return (
    <Modal
      title="Usuário"
      show={show}
      onHide={onHide}
      isLoading={loading}
      onSubmit={() => save()}
    >
      <div class="block-content block-content-full text-center bg-body-light">
        <img
          class="img-avatar img-avatar-thumb"
          src={
            data && data.logo ? data.logo : "assets/media/avatars/avatar14.jpg"
          }
        />
        <div class="mt-2">
          <p class="font-w600 mb-0">{data && data.name}</p>
        </div>
      </div>
      <Tabs
        defaultActiveKey="resumo"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="resumo" title="Resumo" className="block-content">
          <div className="form-group">
            <span>Email</span>
            <p>{data && data.email}</p>
          </div>
          <div className="form-group">
            <span>Telefone</span>
            <p>{data && data.phone}</p>
          </div>
          <div className="form-group">
            <span>CPF</span>
            <p>{data && data.doc}</p>
          </div>
          <div className="form-group">
            <span>CRECI</span>
            <p>{data && data.creci}</p>
          </div>
          <div className="form-group">
            <span>Nível de acesso</span>
            <select className="form-control" name="level" onChange={evt => changeUserLevel(evt)}>
              {UserHelper.UserLevelList.map(item => (
                <option value={item.value} selected={data && data.level === item.value ? true : false}>{item.name}</option>
              ))}
            </select>
          </div>
        </Tab>
        <Tab
          eventKey="favoritos"
          title={favorites && `(${favorites.length}) Favoritos`}
        >
          {favorites &&
            favorites.map((item) => (
              <RealtyInLine data={item} favorite={true} />
            ))}
        </Tab>
        <Tab
          eventKey="anuncios"
          title={properties && `(${properties.length}) Anúncios`}
        >
          <div className="row">
            {properties &&
              properties.map((item) => (
                <div className="col-md-6">
                  <OwnerRealtyCard data={item} favorite={true} />
                </div>
              ))}
          </div>
        </Tab>
      </Tabs>
    </Modal>
  );
}
