export const RealtyHelper = {
    AdType: {
        Rent: 0,
        Buy: 1,
        Both: 2,
    },

    AmenityType: {
        Normal: 0,
        Quantity: 1,
        Distance: 2,
    },

    Status: {
        FillingIn: 0,
        Ongoing: 1,
        Done: 2,
        Blocked: 3,
        Canceled: 4,
    },

    StatusList: [
        {name: "Preenchendo", value: 0},
        {name: "Em Andamento", value: 1},
        {name: "Concluído", value: 2},
        {name: "Bloqueado", value: 3},
        {name: "Cancelado", value: 4},
    ],

    AdTypeList: [
        { name: "Aluguel", value: 0 },
        { name: "Compra", value: 1 },
        { name: "Aluguel e compra", value: 2 },
    ],

    AmenityTypeList: [
        { name: "Normal", value: 0 },
        { name: "Quantidade", value: 1 },
        { name: "Distância", value: 2 },
    ],

    TranslateAdType: function (type) {
        const result = this.AdTypeList.filter(x => x.value === type);
        if (result.length > 0) {
            return result[0].name;
        } else {
            return "S.I.";
        }
    },
    Purpose: {
        Residential: 0,
        Comercial: 1,
    },
    PurposeList: [
        { name: "Residential", value: 0 },
        { name: "Comercial", value: 1 },
    ],

    TranslatePurpose: function (type) {
        const result = this.PurposeList.filter(x => x.value === type);
        if (result.length > 0) {
            return result[0].name;
        } else {
            return "S.I.";
        }
    },

    Categories: [
        { name: "Apartamento", value: 0 },
        { name: "Casa", value: 1 },
        { name: "Casa de condomínio", value: 2 },
        { name: "Casa de Vila", value: 3 },
        { name: "Cobertura", value: 4 },
        { name: "Flat", value: 5 },
        { name: "Fazenda / Sítio / Chácara", value: 6 },
        { name: "Lote/Terreno", value: 7 },
        { name: "Sobrado", value: 8 },
    ]
}