import Table from "@/app/core/components/table";
import Functions from "@/app/core/util/Functions";
import React, { useEffect, useState } from "react";
import CategoryModal from "./CategoryModal";
import { CategoriesController } from "@/app/core/base/controllers/CategoriesController";
import { PlansController } from "@/app/core/base/controllers/PlanController";
import PlanModal from "./PlanModal";

export default function PlansCard(props) {
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState({});
    const [showModal, setShowModal] = useState(false);
    const cols = [
        { header: "#ID", isSortable: true, name: "id" },
        { header: "Nome", isSortable: true, name: "name" },
        { header: "Diferenciais", isSortable: true, name: "count" },
    ];

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        PlansController.List(res => {
            for (let s in res) {
                res[s].count = res[s].optionals.length;
            }
            setData(res)
        });
    }

    async function deleteAction(item) { }

    async function viewAction(item) {
        setSelected(item.data);
        setShowModal(true)
    }

    function closeModal() {
        setSelected({})
        setShowModal(false);
        loadData();
    }

    return (
        <div>
            <Table
                title="Planos"
                loading={false}
                data={data}
                columns={cols}
                refreshData={() => loadData()}
                setFilter={(filter) => Functions.ProcessFilter(filter, data)}
                deleteAction={(data) => deleteAction(data)}
                viewAction={(data) => viewAction(data)}
                insertAction={() => setShowModal(true)}
            />

            <PlanModal
                plan={selected}
                show={showModal}
                onHide={() => closeModal()}
            />
        </div>
    )
}