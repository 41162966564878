import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import MainComponent from "./main";
import Layout from "@/app/core/layouts/main";
import Storage from "@/app/core/util/Storage";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";

const userData = Storage.getUserData();

const ProjectShell = (props) =>
    Storage.isAuthenticated() && userData.level === UserHelper.UserLevel.Administrator ? (
        <Layout {...props}>{renderRoutes(props.route.routes, props)}</Layout>
    ) : (
        <Redirect to="/" />
    );

const Redirecter = () => <Redirect to="/error/404" />;

const AdsRouter = {
    path: "/gerenciador-de-anuncios",
    component: ProjectShell,
    routes: [
        {
            path: "/gerenciador-de-anuncios",
            exact: true,
            component: MainComponent,
        },
        {
            path: "*",
            component: Redirecter,
        },
    ],
};

export default AdsRouter;