import { toast } from "react-toastify";
import axios from "axios";
import GetBaseUrl from "../BaseAddress";

export const ContactController = {

    EmailBody: function (message, targetName) {
        const email = `
  <!DOCTYPE html>
  <html lang="pt-BR">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link
      href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,900;1,900&family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap"
      rel="stylesheet">
    <title>Email Habitaki</title>
    <style>
      .btn_documents {
        display: flex;
        /* margin-top: 30px; */
        border: 1px solid #252525;
        /* width: 36rem; */
        /* height: 40px; */
        padding: 15px 0 15px 0;
        border-radius: 7px;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
      }
  
      .btn_documents button {
        border: none;
        background-color: #fcf9f9;
        /* padding: 0.7rem 1rem; */
        /* border-radius: 4px; */
        cursor: pointer;
        margin-left: 15px;
      }
  
      .btn_documents button a {
        display: flex;
        text-decoration: none;
        font-size: 18px;
        color: #000000;
        justify-content: space-between;
      }
  
      .btn_download {
        display: flex;
        font-size: 17px;
        align-items: center;
        margin-bottom: 0;
        margin-right: 15px;
        color: gray;
      }
  
      .search {
        margin-bottom: 2.5rem;
        min-height: 350px;
        max-width: 100%;
      }
  
      .main-content {
        max-width: 700px;
        padding: 10px 80px 10px 80px;
        background: #F2E6E6;
      }
  
      .GT_logo {
        display: flex;
        width: 540px;
        height: 144px;
        margin-bottom: 15px;
        background: #F2E6E6;
      }
  
  
      .details_email h1 {
        font-weight: bold;
        text-decoration: none;
        margin: 10px;
      }
  
      .description {
        margin: 10px;
        font-size: 20px;
      }
  
      .more svg {
        display: inline-block;
        font-size: inherit;
        content: "";
        height: 25px;
        min-height: 24px;
        vertical-align: middle;
        width: 25px;
        margin: 10px;
        margin-right: 9px;
      }
  
      .tax_details {
        max-width: 700px;
        height: 405px;
        background: #F6F6F6;
        padding: 10px 80px 10px 80px;
  
      }
  
      .taxes h1 {
        font-weight: bold;
        text-decoration: none;
        font-size: 26px;
        margin: 10px;
      }
  
      .taxes p {
        margin: 10px;
        font-size: 17px;
      }
  
      .more_documents h4 {
        font-size: 16px;
        margin-top: 5px;
        font-weight: bold;
        color: #000000;
      }
  
      * {
        margin: 0 auto;
        padding: 0;
        box-sizing: border-box;
      }
  
      html {
        font-size: 55%;
        scroll-behavior: smooth;
      }
  
      body {
        font: normal normal 100% Open Sans, Helvetica, Sans-Serif;
        font-size: 2rem;
        color: var(--primary-color);
        line-height: 1.5;
      }
  
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font: normal normal 100% Open Sans, Helvetica, Sans-Serif;
      }
  
      h1 {
        font-size: 4rem;
      }
  
      h2 {
        font-size: 4rem;
        margin-bottom: 2rem;
        text-transform: uppercase;
        line-height: 1.5;
      }
  
  
      h3 {
        font-size: 4rem;
      }
  
      h4 {
        font-size: 3.5rem;
      }
  
      h5 {
        font-size: 3rem;
      }
  
      h6 {
        font-size: 2.5rem;
      }
  
      a {
        text-decoration: none;
      }
  
      p {
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
      }
  
      li {
        list-style: none;
      }
  
      main {
        display: block;
        unicode-bidi: isolate;
      }
  
      .site-footer {
        max-width: 700px;
        height: 93px;
        background: #000;
        color: #BEBEBE;
        font-weight: 600;
        padding: 10px 80px 10px 80px;
      }
  
      .address p {
        padding: 10px 0px 10px 0px;
        font-size: 13px;
      }
    </style>
    <!-- <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="stylesheet" href="Projeto/CSS/Footer.css">
    <link rel="stylesheet" href="Projeto/CSS/Classes.css">
    <link rel="stylesheet" href="Projeto/CSS/Elements.css">
    <link rel="stylesheet" href="Projeto/CSS/Buttons.css"> -->
  </head>
  <body>
    <main class="search">
      <section>
        <div class="main-content">
          <div class="img_gt">
            
          </div>
          <div class="details_email">
            <img src="https://firebasestorage.googleapis.com/v0/b/habitaki-e1021.appspot.com/o/habitaki%2Flogo.png?alt=media&token=02be7cbe-e7c7-4a61-a048-cf25d5076e6d"/>

            <h1></h1>
            <p class="description">Olá ${targetName}</p>
          </div>
        </div>
        <div class="tax_details">
          <ul>
            <li class="more"><svg fill="#000f85" height="800px" width="800px" version="1.1" id="Layer_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"
                xml:space="preserve" stroke="#000f85">
                <g id="SVGRepo_bgCarrier" stroke-width="0" />
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <g>
                      <g>
                        <path
                          d="M256,0C114.618,0,0,114.618,0,256s114.618,256,256,256s256-114.618,256-256S397.382,0,256,0z M256,469.333 c-117.818,0-213.333-95.515-213.333-213.333S138.182,42.667,256,42.667S469.333,138.182,469.333,256S373.818,469.333,256,469.333 z" />
                        <path
                          d="M383.996,234.667H277.333V128c0-11.782-9.551-21.333-21.333-21.333s-21.333,9.551-21.333,21.333v106.667H128.038 c-11.782,0-21.333,9.551-21.333,21.333s9.551,21.333,21.333,21.333h106.628V384c0,11.782,9.551,21.333,21.333,21.333 s21.333-9.551,21.333-21.333V277.333h106.662c11.782,0,21.333-9.551,21.333-21.333S395.778,234.667,383.996,234.667z" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </li>
          </ul>
          ${message}
        </div>
      </section>
      <footer class="site-footer">
        <div class="address">
          <p>Este e-mail foi enviado pela Habitaki<br>
            habitaki.com.br</p>
        </div>
      </footer>
    </main>
  </body>
  </html>
  `;

        return email;
    },
    SendInvite: async function (data) {
        const body = `<div class="taxes">
            <h1>Você foi convidado para a imobiliária ${data.realEstateName}</h1>
            <p>Para aceitar o convite clique no botão abaixo:</p>
          </div>
            <div class="btn_documents">
              <button><a href="${GetBaseUrl()}#/register?invite=${data.id}">Aceitar convite!</a></button>
            </div>`;

        try {
            const request = {
                method: "POST",
                url: `https://navigatejobs.azurewebsites.net/api/v1/sendemail`,
                data: {
                    targetName: data.name,
                    targetEmail: data.email,
                    subject: "Habitaki - Convite para participal da Imobiliária",
                    htmlContent: this.EmailBody(body, data.name),
                    from: "sistema@habitaki.com.br",
                    plain: this.EmailBody(body),
                },
                headers: {
                    "content-type": "application/json",
                },
            };

            const response = await axios.post(request.url, request.data);

            if (response.data || response.status === 200 || response.statusCode === 200) {
                console.log(response.data, response);
                toast.success("Enviado com sucesso");

            } else {
                if (response.status === 200 || response.statusCode === 200) {
                    toast.success("Enviado com sucesso");
                } else {
                    console.error(response);
                }
            }
        } catch (error) {
            console.error(`erro api`, error);
        }
    },
    SendComplain: async function (data, property) {
        const body = `<div class="taxes">
            <h1>${data.name} denúnciou um anúncio</h1>
            <p>Email do denunciante: ${data.email}</p>
            <p>Assunto da denúncia: ${data.subject}</p>
            <p>Título do anúncio: ${property.title}</p>
            <p style="white-space: pre-line;">Descrição da denúncia: <br/>${data.description}</p>
          </div>
            <div class="btn_documents">
              <button><a href="${GetBaseUrl()}#/imovel/${property.id}">Ver anúncio</a></button>
            </div>`;

        try {
            const request = {
                method: "POST",
                url: `https://navigatejobs.azurewebsites.net/api/v1/sendemail`,
                data: {
                    targetName: "Habitaki",
                    targetEmail: "contato@habitaki.com.br",
                    subject: "Denúncia sobre um anúncio",
                    htmlContent: this.EmailBody(body, "Administrador"),
                    from: "sistema@habitaki.com.br",
                    plain: this.EmailBody(body),
                },
                headers: {
                    "content-type": "application/json",
                },
            };

            const response = await axios.post(request.url, request.data);

            if (response.data || response.status === 200 || response.statusCode === 200) {
                console.log(response.data, response);
                toast.success("Enviado com sucesso");

            } else {
                if (response.status === 200 || response.statusCode === 200) {
                    toast.success("Enviado com sucesso");
                } else {
                    console.error(response);
                }
            }
        } catch (error) {
            console.error(`erro api`, error);
        }
    }
}