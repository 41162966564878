import Storage from "@/app/core/util/Storage";
import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";
import { ContactHelper } from "@/app/core/util/helpers/ContactHelper";
import { OnlyDateTranslate } from "@/app/core/util/DateTranslater";
import { FileController } from "@/app/core/base/controllers/FileController";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import EditProfileModal from "./components/EditProfileModal";

export default function ProfileComponent() {
  const [userData, setUserData] = useState(Storage.getUserData());
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const inputFile = useRef(null);

  useEffect(() => {
    loadUser();
  }, []);

  function onButtonClick() {
    inputFile.current.click();
  }

  async function uploadAvatar(file) {
    FileController.Upload(
      `users/${userData.uid}/${file.name}`,
      file,
      async (res) => {
        const updatedData = { ...userData, picture: res };
        setUserData(updatedData);
        Storage.setUserData(updatedData);

        await AccountController.Update(
          { ...updatedData, picture: res },
          (result) => {
            toast.success(Message.Saved);
          }
        );
      }
    );
  }

  async function loadUser() {
    const res = await AccountController.GetUser(userData.uid);
    console.log("Vamos", res);
    setUserData(res);
    Storage.setUserData({ ...res });
  }

  return (
    <>
      <div className="content">
        <div className="row mr-0 pr-0">
          <div className="col-md-4">
            <div className="block block-rounded block-bordered">
              <div className="block-content">
                <div className="text-center py-3">
                  <img
                    className="img-avatar img-avatar72 mr-3 img-avatar-thumb"
                    src={
                      userData && userData.picture
                        ? userData.picture
                        : "assets/media/avatars/avatar12.jpg"
                    }
                    alt="Logo da empresa"
                    title="Logo da empresa"
                    onClick={() => onButtonClick()}
                  />
                  <br />
                  <button
                    className="btn btn-link"
                    onClick={() => onButtonClick()}
                  >
                    Trocar foto
                  </button>
                </div>
                <hr />
                <h5>Informações gerais</h5>
                <div className="ml-2">
                  <p>
                    <i className="si si-calendar mr-2" /> Membro desde{" "}
                    {userData &&
                      userData.createdDate &&
                      OnlyDateTranslate(userData.createdDate)}
                  </p>
                  <p>
                    <i className="si si-clock mr-2" /> Responde em 1h
                  </p>
                  <p>
                    <i className="si si-envelope-letter mr-2" />{" "}
                    {userData && userData.email}
                  </p>
                  <p>
                    <i className="si si-screen-smartphone mr-2" />{" "}
                    {userData && ContactHelper.PhoneMask(userData.phone)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <h2>Olá, {userData && userData.name}!</h2>
            <p className="mb-0">Bio:</p>
            <p style={{ whiteSpace: "pre-line" }}>
              {userData && userData.bio !== undefined
                ? userData.bio
                : "Edite seu perfil para cadastrar a BIO"}
            </p>

            <hr />
            <h5>
              <i className="far fa-comment-dots mr-2" /> Idiomas
            </h5>
            <ul>
              <li>Português</li>
              <li>Inglês</li>
              <li>Espanhol</li>
            </ul>

            <button
              className="btn btn-warning mt-4"
              onClick={() => setShowModal(true)}
            >
              Editar perfil
            </button>
          </div>
        </div>
      </div>
      <input
        type="file"
        id="file"
        accept="image/*"
        ref={inputFile}
        onChange={(event) => {
          const parent = event.target.files;
          Array.prototype.forEach.call(parent, (file) => {
            uploadAvatar(file);
          });
        }}
        style={{ visibility: "hidden" }}
      />

      <EditProfileModal
        show={showModal}
        realEstate={userData}
        onHide={() => setShowModal(false) | loadUser()}
      />
      <Loading loading={loading} />
      <ToastContainer className="toastr" />
    </>
  );
}
