import React, { useRef } from "react";

export default function UploadButton({ title, upload, accept }) {
    const inputFile = useRef(null);

    function onButtonClick() {
        inputFile.current.click();
    }

    return (
        <>
            <a
                href="javascript:void(0)"
                className="btn mt-3 btn-warning"
                onClick={() => onButtonClick()}
            >
                <i className="si si-cloud-upload"></i> {title}
            </a>
            <input
                type="file"
                id="file"
                accept={accept}
                multiple
                ref={inputFile}
                onChange={(event) => {
                    const files = Array.from(event.target.files);
                    if (files.length > 0) {
                        upload(files);
                    }
                }}
                style={{ visibility: "hidden", width: 10 }}
            />
        </>
    )
}