import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Field from "@/app/core/components/input/Field";
import Modal from "@/app/core/layouts/main/components/Modal";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { AccountController } from "@/app/core/base/controllers/AccountController";

export default function EditProfileModal({ show, onHide, realEstate }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            setData(realEstate);
        }
    }, [show]);

    function handleRealChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    async function save() {
        let error = false, message = "";

        if (!data.name) {
            error = true;
            message += "Nome; ";
        }
        if (!data.phone) {
            error = true;
            message += "Telefone; ";
        }

        if (error) {
            toast.error("Esta faltando preencher: " + message);
        } else {
            if (!UserHelper.ValidaCNPJ(data.taxId) && data.level === UserHelper.UserLevel.Realtor) {
                toast.error(Message.CNPJInvalid);
            } else {
                setLoading(true);
                await AccountController.Update(data, res => {
                    toast.success("Salvo com sucesso");
                });
                onHide()
                setLoading(false);
            }
        }
    }

    return (
        <Modal title="Editar" show={show} onHide={onHide} isLoading={loading} onSubmit={() => save()}>
            <Field name="name" onChange={evt => handleRealChange(evt)} title={"Nome completo"} value={data && data.name} type={FieldTypeObj.Text} />
            <Field name="phone" onChange={evt => handleRealChange({ target: { name: evt.target.name, value: evt.target.value.replace(/[^\d]+/g, "") } })} title={"Celular"} value={data && data.phone} type={FieldTypeObj.Mask} mask={"(99) 9999-99999"} />
            {data && (data.level === UserHelper.UserLevel.Realtor || data.level === UserHelper.UserLevel.RealEstate) ?
                <Field name="doc" onChange={evt => handleRealChange({ target: { name: evt.target.name, value: evt.target.value.replace(/[^\d]+/g, "") } })} title={"CPF"} value={data && data.doc} type={FieldTypeObj.Mask} mask={"999.999.999-99"} />
                : ""}
            <Field name="bio" onChange={evt => handleRealChange(evt)} title={"Sua apresentação"} value={data && data.bio} type={FieldTypeObj.TextArea} hint={"Fica apresentado nos anuncios"} />
        </Modal>
    );
}
