import React, { useState, useEffect } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import AdministratorPanel from "../panels/AdministratorPanel";
import RealEstateAgencyPanel from "../panels/RealEstateAgencyPanel";

export default function MainDashboard() {
  const userData = Storage.getUserData();
  const [loading] = useState(false);
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    loadAutomations();
  }, []);

  async function loadAutomations() {
    if (enviroment === "local" || enviroment === "staging") {
    }
  }

  return (
    <>
    <div className="content">
      {userData && userData.level === UserHelper.UserLevel.Administrator ? <AdministratorPanel/> : ""}
      {userData && userData.level === UserHelper.UserLevel.RealEstate ? <RealEstateAgencyPanel/> : ""}
    </div>
      <Loading loading={loading} />
      <ToastContainer className="toastr" />
    </>
  );
}
