import Storage from "@/app/core/util/Storage";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";
import { InviteController } from "@/app/core/base/controllers/InviteController";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import { Tab, Tabs } from "react-bootstrap";
import CategoriesCard from "./components/CategoriesCard";
import RealEstateTypeCard from "./components/RealEstateTypeCard";
import PlansCard from "./components/PlansCard";

export default function AnunciosComponent() {
    const userData = Storage.getUserData();
    const [loading] = useState(false);
    const enviroment = process.env.REACT_APP_ENV;

    useEffect(() => {
    }, []);


    return (
        <>
            <div className="content mb-8">
                <div className="row">
                    <div className="col-12">
                        <div className="content-heading">
                            <h3>Configurações</h3>
                        </div>
                    </div>
                </div>
                <Tabs>
                    <Tab eventKey={1} title="Categorias de opcionais" className="">
                        <CategoriesCard />
                    </Tab>
                    <Tab eventKey={2} title="Tipo de imóvel" className="">
                        <RealEstateTypeCard />
                    </Tab>
                    <Tab eventKey={3} title="Planos" className="">
                        <PlansCard />
                    </Tab>
                    <Tab eventKey={4} title="Regras" className="">
                        <CategoriesCard />
                    </Tab>

                </Tabs>
            </div>
            <Loading loading={loading} />
            <ToastContainer className="toastr" />
        </>
    );
}
