import React, { useEffect, useState } from "react";
import "./assets/nav.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Storage from "@/app/core/util/Storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Layout(props) {
  const [showModal, setShowModal] = useState(false);
  const [showProspectModal, setShowProspectModal] = useState(false);
  const [menu, setMenu] = useState([]);
  const user = Storage.getUserData();
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    processMenu();
  }, [user]);

  function processMenu() {
  }

  let pageContainerRef = React.createRef();
  const toggleOverlay = () => {
    const pageContainer = pageContainerRef.classList;
    pageContainer.remove("side-overlay-o");
    pageContainer.remove("sidebar-o");
  };
  const toggleNav = () => {
    const pageContainer = pageContainerRef.classList;
    if (window.innerWidth > 991) {
      pageContainer.remove("sidebar-o-xs");
      pageContainer.toggle("sidebar-o");
    } else {
      pageContainer.remove("sidebar-o");
      pageContainer.toggle("sidebar-o-xs");
    }
  };


  return (
    <div
      ref={(el) => (pageContainerRef = el)}
      id="page-container"
      className={`enable-page-overlay side-scroll page-header-fixed main-content-narrow sidebar-dark page-header-${Storage.getTheme()} `}
    >
      <div id="page-overlay" onClick={() => toggleOverlay()} />
      <Nav toggleNav={toggleNav} menu={menu} />
      <Header
        showModal={() => setShowModal(!showModal)}
        showProspectModal={() => setShowProspectModal(!showProspectModal)}
        toggleNav={toggleNav}
        color={props.color}
        {...props.location}
      />
      <main id="main-container">{props.children}</main>
      {props.footer === undefined ?
        <Footer />
        : ""}
      {/* <SideOverlay style={{ width: "500px" }} title="Toolbox">
        <Toolbox />
      </SideOverlay> */}
      <ToastContainer className="toastr" />
    </div>
  );
}
