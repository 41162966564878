export const ContactHelper = {

    PhoneMask: function (phone) {
        const number = this.PhoneTranslate(phone);
        const ddi = number.slice(0, 2);
        const ddd = number.slice(2, 4);
        const cel1 = number.slice(4, 9);
        const cel2 = number.slice(9, 13);

        const organized = `+${ddi} (${ddd}) ${cel1}-${cel2}`;
        if (number.length === 13) {
            return organized;
        } else if (number.length === 12) {
            return organized;
        }
    },

    PhoneTranslate: function (i) {
        let phone = "";
        if (i === undefined) {
        } else if (i.length <= 9) {
            phone = "5511" + i;
        } else if (i.length > 9 && i.length < 13) {
            phone = "55" + i;
        } else {
            phone = i;
        }
        return phone;
    }
}