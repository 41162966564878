import { toast } from "react-toastify";
import { Firebase } from "@/app/core/base/BaseFirebase";
import { ErrorHelper } from "../../util/helpers/ErrorHelper";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";

export const RealEstateTypeController = {
  db: getFirestore(Firebase),

  Insert: async function (data, res) {
    try {
      const inviteRef = collection(this.db, "realEstateType");
      const result = await addDoc(inviteRef, {
        ...data,
        updatedDate: Timestamp.now(),
        createdDate: Timestamp.now(),
      });

      res(result.id);
    } catch (e) {
      console.log("ERRo", e);
      toast.error(ErrorHelper.HandleMessage(e));
    }
  },

  Update: async function (data, res) {
    try {
      const frankDocRef = doc(this.db, "realEstateType", data.id);
      await updateDoc(frankDocRef, { ...data, updatedDate: Timestamp.now() });
    } catch (e) {
      console.log("ERRo", e);
      toast.error(ErrorHelper.HandleMessage(e));
    }
  },

  Get: async function (id) {
    const docSnap = await getDoc(doc(this.db, "realEstateType", id));
    if (docSnap.exists()) {
      let data = docSnap.data();
      data.id = docSnap.id;
      return data;
    } else {
      return null;
    }
  },

  List: async function (res) {
    const querySnapshot = await getDocs(collection(this.db, "realEstateType"));
    let results = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
  },

  Delete: async function (id, res) {
    await deleteDoc(doc(this.db, "realEstateType", id));
    res();
  },
};
