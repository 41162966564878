import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { FavoritesController } from "@/app/core/base/controllers/FavoriteController";
import { CategoriesController } from "@/app/core/base/controllers/CategoriesController";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { toast } from "react-toastify";
import useDebounce from "@/app/core/util/UseDebounce";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";
import { RealEstateTypeController } from "@/app/core/base/controllers/RealEstateTypeController";
import { Dropdown } from "react-bootstrap";
import { CurrencyHelper } from "@/app/core/util/helpers/CurrencyHelper";

export default function SearchTerms({
  reportHumanizedQuery,
  reportProperties,
  reportFavorites,
  orderBy,
  type,
  props,
}) {
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [realEstateTypes, setRealEstateTypes] = useState([]);
  const [optionalsCounts, setOptionalsCounts] = useState(new Map());
  const debouncedData = useDebounce(data, 500);

  useEffect(() => {
    loadUrlParams();
    loadRemoteData();
  }, []);

  useEffect(() => {
    if (debouncedData) {
      handleSearch();
      reportSearchData(debouncedData);
    }
  }, [debouncedData]);

  useEffect(() => {
    setData((oldData) => ({ ...oldData, orderBy: orderBy, type: type }));
  }, [orderBy, type]);

  function getUrlComponent(componentName) {
    if (!props) {
      return null;
    }
    const params = new URLSearchParams(props.location.search);
    return params.get(componentName);
  }

  async function loadUrlParams() {
    const query = getUrlComponent("query") || "";
    const realEstateTypesIds =
      getUrlComponent("realEstateTypesIds")?.split(",") || [];
    const amenitiesOptionalsIds =
      getUrlComponent("amenitiesOptionalsIds")?.split(",") || [];

    setData({
      ...data,
      query: query,
      realEstateTypesIds: realEstateTypesIds,
      amenitiesOptionalsIds: amenitiesOptionalsIds,
    });
  }

  async function loadRemoteData() {
    try {
      await Promise.all([
        RealEstateTypeController.List((res) => setRealEstateTypes(res)),
        CategoriesController.List((res) => setCategories(res)),
        PropertiesController.GetOptionalsPropertiesCount().then(
          (countersMap) => {
            console.log("Counters map", countersMap);
            setOptionalsCounts(countersMap);
          }
        ),
      ]);
    } catch (error) {
      console.log("SearchTerms: Failed to fetch data: ", error);
      toast.error("Falha ao inicializar filtros");
    }
  }

  async function handleSearch() {
    try {
      let propertyResults = await PropertiesController.QueryProperties({
        queryString: debouncedData.query,
        types: debouncedData.type
          ? [debouncedData.type, RealtyHelper.AdType.Both]
          : null,
        withStories: debouncedData.withStories,
        minPrice: debouncedData.minPrice,
        maxPrice: debouncedData.maxPrice,
        rooms: debouncedData.rooms,
        minArea: debouncedData.minArea,
        maxArea: debouncedData.maxArea,
        parkingLots: debouncedData.parkingLots,
        bathrooms: debouncedData.bathrooms,
        amenitiesOptionalsIds: debouncedData.amenitiesOptionalsIds,
        realEstateTypesIds: debouncedData.realEstateTypesIds,
        orderBy: debouncedData.orderBy,
      });

      let propertyIds = propertyResults.map((p) => p.id);
      let favoriteResults =
        propertyIds.length !== 0
          ? await FavoritesController.QueryFavorites(propertyIds)
          : [];

      reportProperties(propertyResults);
      reportFavorites(favoriteResults);
    } catch (error) {
      console.log("SearchTerms: Failed to query properties: ", error);
      toast.error("Falha ao buscar propriedades");
    }
  }

  function reportSearchData(debouncedData) {
    const {
      query,
      type,
      withStories,
      minPrice,
      maxPrice,
      rooms,
      minArea,
      maxArea,
      parkingLots,
      bathrooms,
      amenitiesOptionalsIds,
      realEstateTypesIds,
      orderBy,
    } = debouncedData;

    let criterios = [];

    if (query && query !== "") {
      criterios.push(`${query}`);
    }

    if (type) {
      criterios.push(
        `${type === RealtyHelper.AdType.Buy ? "Comprar" : "Alugar"}`
      );
    }

    if (withStories !== undefined) {
      criterios.push(`${withStories ? "Com vídeos" : ""}`);
    }

    if (minPrice && minPrice > 0) {
      criterios.push(`Preço mínimo: ${CurrencyHelper.Format(minPrice)}`);
    }

    if (maxPrice && maxPrice > 0) {
      criterios.push(`Preço máximo: ${CurrencyHelper.Format(maxPrice)}`);
    }

    if (rooms && rooms > 0) {
      criterios.push(`Quartos: ${rooms}`);
    }

    if (minArea && minArea > 0) {
      criterios.push(`Área mínima: ${minArea} m²`);
    }

    if (maxArea && maxArea > 0) {
      criterios.push(`Área máxima: ${maxArea} m²`);
    }

    if (parkingLots && parkingLots > 0) {
      criterios.push(`Vagas de estacionamento: ${parkingLots}`);
    }

    if (bathrooms && bathrooms > 0) {
      criterios.push(`Banheiros: ${bathrooms}`);
    }

    if (amenitiesOptionalsIds && amenitiesOptionalsIds.length > 0) {
      criterios.push(
        `Opcionais: ${mapOptionalsIdsToNames(amenitiesOptionalsIds).join(", ")}`
      );
    }

    if (realEstateTypesIds && realEstateTypesIds.length > 0) {
      criterios.push(
        `Tipos de imóveis: ${mapRealEstateTypesIdsToNames(
          realEstateTypesIds
        ).join(", ")}`
      );
    }

    if (orderBy) {
      criterios.push(
        `Ordenar por: ${orderBy === "asc" ? "Menor preço" : "Maior preço"}`
      );
    }

    let humanizedQuery = "";

    if (criterios.length > 0) {
      humanizedQuery = criterios.join(" / ");
    } else {
      humanizedQuery = "Nenhum critério fornecido";
    }

    reportHumanizedQuery(humanizedQuery);
  }

  function mapRealEstateTypesIdsToNames(realEstateTypesIds) {
    return realEstateTypesIds
      .map((id) => realEstateTypes.find((type) => type.id === id)?.name)
      .filter((name) => name !== undefined);
  }

  function mapOptionalsIdsToNames(optionalsIds) {
    return optionalsIds
      .map((id) => {
        for (let cat of categories) {
          let optional = cat.optionals.find((op) => op.id === id);
          if (optional) return optional.name;
        }
      })
      .filter((name) => name !== undefined);
  }

  function isRealEstateTypeChecked(realEstateType) {
    return data && Array.isArray(data.realEstateTypesIds)
      ? data.realEstateTypesIds.includes(realEstateType.id)
      : false;
  }

  function isCategoryOptionalChecked(optional) {
    return data && Array.isArray(data.amenitiesOptionalsIds)
      ? data.amenitiesOptionalsIds.includes(optional.id)
      : false;
  }

  function handleOptionalState(evt, optional) {
    const amenities = data.amenitiesOptionalsIds || [];
    const updatedAmenities = evt.target.checked
      ? [...amenities, optional.id]
      : amenities.filter((op) => op !== optional.id);

    setData((prevData) => ({
      ...prevData,
      amenitiesOptionalsIds: updatedAmenities,
    }));
  }

  function handleRealEstateTypeState(evt, realEstateType) {
    let realEstateTypesIds = data.realEstateTypesIds || [];
    let updatedRealEstateTypesIds = evt.target.checked
      ? [...realEstateTypesIds, realEstateType.id]
      : realEstateTypesIds.filter((id) => id !== realEstateType.id);

    setData((prevData) => ({
      ...prevData,
      realEstateTypesIds: updatedRealEstateTypesIds,
    }));
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  function getRealEstateTypesCheckedCount() {
    if (data && Array.isArray(data.realEstateTypesIds)) {
      return data.realEstateTypesIds.length;
    }
    return 0;
  }

  return (
    <div>
      <div
        className="block block-rounded"
        style={{ backgroundColor: "#FFC4A9" }}
      >
        <div className="block-content">
          <div className="form-group">
            <span>Localização do imóvel</span>
            <input
              className="form-control"
              name="query"
              value={data && data.query}
              onChange={(evt) => handleChange(evt)}
              placeholder="Digite uma rua, bairro ou cidade"
            />
          </div>
          <div className="form-group">
            <span>Imóvel com:</span>
            <div className="custom-control custom-switch custom-control-lg mb-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id="example-sw-custom-lg3"
                name="example-sw-custom-lg3"
                onChange={(evt) =>
                  setData({ ...data, withStories: evt.target.checked })
                }
              />
              <label
                className="custom-control-label"
                for="example-sw-custom-lg3"
              >
                Tour virtual ou vídeos
              </label>
            </div>
          </div>
          <div className="form-group">
            <span>Tipo de imóvel</span>
            <Dropdown>
              <Dropdown.Toggle
                className="btn btn-block btn-white text-left border border-2x"
                variant="light"
              >
                {getRealEstateTypesCheckedCount() > 0
                  ? `${getRealEstateTypesCheckedCount()} selecionados`
                  : "Todos os imóveis"}
              </Dropdown.Toggle>
              <Dropdown.Menu
                style={{
                  width: "100%",
                  maxHeight: 440,
                  overflowY: "scroll",
                }}
              >
                <label className="font-w700">Residencial</label>
                {realEstateTypes &&
                  realEstateTypes
                    .filter(
                      (x) => x.purpose === RealtyHelper.Purpose.Residential
                    )
                    .map((item, index) => (
                      <div key={item.id} class="form-group ml-2">
                        <div class="custom-control custom-checkbox mb-1">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={item.id}
                            name={item.id}
                            checked={isRealEstateTypeChecked(item)}
                            onChange={(evt) =>
                              handleRealEstateTypeState(evt, item)
                            }
                          />
                          <label class="custom-control-label" for={item.id}>
                            {item.name}
                          </label>
                        </div>
                      </div>
                    ))}
                <label className="font-w700">Comercial</label>
                {realEstateTypes &&
                  realEstateTypes
                    .filter((x) => x.purpose === RealtyHelper.Purpose.Comercial)
                    .map((item, index) => (
                      <div key={item.id} class="form-group ml-2">
                        <div class="custom-control custom-checkbox mb-1">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id={item.id}
                            name={item.id}
                            checked={isRealEstateTypeChecked(item)}
                            onChange={(evt) =>
                              handleRealEstateTypeState(evt, item)
                            }
                          />
                          <label class="custom-control-label" for={item.id}>
                            {item.name}
                          </label>
                        </div>
                      </div>
                    ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="row p-0">
            <div className="form-group col-6">
              <span>Preço mínimo:</span>
              <input
                className="form-control"
                name="minPrice"
                value={data && data.minPrice}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Decimal)}
                placeholder="R$"
              />
            </div>
            <div className="form-group col-6">
              <span>Preço máximo:</span>
              <input
                className="form-control"
                name="maxPrice"
                value={data && data.maxPrice}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Decimal)}
                placeholder="R$"
              />
            </div>
          </div>
          <div className="form-group">
            <span>Número de quartos:</span>
            <br />
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  rooms: prev.rooms === 1 ? 0 : 1,
                }))
              }
              className={
                data &&
                `btn m-2 ml-0 btn-rounded ${
                  data.rooms === 1 ? "btn-dark" : "btn-white"
                }`
              }
            >
              1
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  rooms: prev.rooms === 2 ? 0 : 2,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.rooms === 2 ? "btn-dark" : "btn-white"
                }`
              }
            >
              2
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  rooms: prev.rooms === 3 ? 0 : 3,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.rooms === 3 ? "btn-dark" : "btn-white"
                }`
              }
            >
              3
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  rooms: prev.rooms === 4 ? 0 : 4,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.rooms === 4 ? "btn-dark" : "btn-white"
                }`
              }
            >
              4+
            </button>
          </div>
        </div>
      </div>
      <div className="block block-rounded block-bordered">
        <div className="block-content">
          <div className="row p-0">
            <div className="form-group col-6">
              <span>Área mínimo:</span>
              <input
                className="form-control"
                name="minArea"
                value={data && data.minArea}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Decimal)}
                placeholder="m²"
              />
            </div>
            <div className="form-group col-6">
              <span>Área máximo:</span>
              <input
                className="form-control"
                name="maxArea"
                value={data && data.maxArea}
                onChange={(evt) => handleChange(evt, FieldTypeObj.Decimal)}
                placeholder="m²"
              />
            </div>
          </div>
          <div className="form-group">
            <span>Número de vagas:</span>
            <br />
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  parkingLots: prev.parkingLots === 1 ? 0 : 1,
                }))
              }
              className={
                data &&
                `btn m-2 ml-0 btn-rounded ${
                  data.parkingLots === 1 ? "btn-dark" : "btn-white"
                }`
              }
            >
              1
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  parkingLots: prev.parkingLots === 2 ? 0 : 2,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.parkingLots === 2 ? "btn-dark" : "btn-white"
                }`
              }
            >
              2
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  parkingLots: prev.parkingLots === 3 ? 0 : 3,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.parkingLots === 3 ? "btn-dark" : "btn-white"
                }`
              }
            >
              3
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  parkingLots: prev.parkingLots === 4 ? 0 : 4,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.parkingLots === 4 ? "btn-dark" : "btn-white"
                }`
              }
            >
              4+
            </button>
          </div>
          <div className="form-group">
            <span>Número de banheiros:</span>
            <br />
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  bathrooms: prev.bathrooms === 1 ? 0 : 1,
                }))
              }
              className={
                data &&
                `btn m-2 ml-0 btn-rounded ${
                  data.bathrooms === 1 ? "btn-dark" : "btn-white"
                }`
              }
            >
              1
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  bathrooms: prev.bathrooms === 2 ? 0 : 2,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.bathrooms === 2 ? "btn-dark" : "btn-white"
                }`
              }
            >
              2
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  bathrooms: prev.bathrooms === 3 ? 0 : 3,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.bathrooms === 3 ? "btn-dark" : "btn-white"
                }`
              }
            >
              3
            </button>
            <button
              onClick={() =>
                setData((prev) => ({
                  ...prev,
                  bathrooms: prev.bathrooms === 4 ? 0 : 4,
                }))
              }
              className={
                data &&
                `btn m-2 btn-rounded ${
                  data.bathrooms === 4 ? "btn-dark" : "btn-white"
                }`
              }
            >
              4+
            </button>
          </div>
          {categories &&
            categories.map((category, idx) => (
              <div key={category.id} className="form-group">
                <strong className="font-w700 mb-3">{category.name}:</strong>
                {category &&
                  category.optionals &&
                  category.optionals.map((optional, index) => (
                    <div
                      key={optional.id}
                      className="custom-control custom-checkbox custom-control-lg mb-1 ml-2 py-1"
                    >
                      <input
                        type="checkbox"
                        id={`${category.id}_${optional.id}`}
                        name={`${category.id}_${optional.id}`}
                        className="custom-control-input"
                        onChange={(evt) => handleOptionalState(evt, optional)}
                        checked={isCategoryOptionalChecked(optional)}
                      />
                      <label
                        for={`${category.id}_${optional.id}`}
                        className="custom-control-label"
                      >
                        {optional.name} ({optionalsCounts.get(optional.id) || 0}
                        )
                      </label>
                    </div>
                  ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
