import { HashRouter as Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { DashboardRouter } from "@/app/modules/dashboard";
import AuthenticationRouter from "@/app/modules/auth";
import Error404Page from "./core/layouts/errors/Error404Page";
import Storage from "./core/util/Storage";
import HomeRouter from "./modules/home";
import SearchRouter from "./modules/search";
import ImovelRouter from "./modules/imovel";
import AnunciarRouter from "./modules/anunciar";
import MeuPainelRouter from "./modules/meuPainel";
import AnunciosRouter from "./modules/anuncios";
import ChatRouter from "./modules/chat";
import PerfilRouter from "./modules/perfil";
import CorretoresRouter from "./modules/corretores";
import EmpresaRouter from "./modules/empresa";
import RegisterRouter from "./modules/register";
import SettingsRouter from "./modules/administrator/settings";
import FavoritosRouter from "./modules/favoritos";
import AdsRouter from "./modules/administrator/ads";
import UsersRouter from "./modules/administrator/users";
import RealEstateAgenciesRouter from "./modules/administrator/realEstateAgencies";

export default function App() {
  if (!localStorage.getItem("theme")) {
    localStorage.setItem("theme", "light");
  }

  document.body.classList.add("bg-" + Storage.getTheme());

  const routes = [
    AuthenticationRouter,
    RegisterRouter,
    HomeRouter,
    SearchRouter,
    ImovelRouter,
    AnunciarRouter,
    MeuPainelRouter,
    EmpresaRouter,
    AnunciosRouter,
    ChatRouter,
    CorretoresRouter,
    SettingsRouter,
    PerfilRouter,
    UsersRouter,
    DashboardRouter,
    AdsRouter,
    UsersRouter,
    RealEstateAgenciesRouter,
    FavoritosRouter,
    {
      path: "*",
      component: Error404Page,
      onShell: false,
    },
  ];

  return <Router>{renderRoutes(routes)}</Router>;
}
