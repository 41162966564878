import Storage from "@/app/core/util/Storage";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";
import { Dropdown } from "react-bootstrap";
import Field from "@/app/core/components/input/Field";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import { InviteController } from "@/app/core/base/controllers/InviteController";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import { GetTerm } from "@/app/core/util/DateTranslater";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { ContactController } from "@/app/core/base/controllers/ContactController";
import { AccountController } from "@/app/core/base/controllers/AccountController";

export default function AnunciosComponent() {
    const userData = Storage.getUserData();
    const [loading] = useState(false);
    const [data, setData] = useState([]);
    const [invites, setInvites] = useState([]);
    const [realEstateAgency, setRealEstateAgency] = useState({});
    const [newUser, setNewUser] = useState({});
    const enviroment = process.env.REACT_APP_ENV;

    useEffect(() => {
        loadRealEstate();
        loadInvites()
        loadUsers()
    }, []);

    async function loadInvites() {
        InviteController.List(userData.realEstate, result => {
            setInvites(result);
        })
    }
    async function loadUsers() {
        AccountController.ListByRealEstate(userData.realEstate, result => {
            console.log("Users", result);
            setData(result);
        })
    }

    async function loadRealEstate() {
        RealEstateAgencyController.Get(userData.realEstate, res => {
            setRealEstateAgency(res);
        })
    }

    function handleChange(evt, type) {
        setNewUser(OnDataChange(newUser, evt, type));
    }

    async function sendInvitation(e) {
        e.preventDefault();
        let error = false, message = "";
        if (!newUser.name) {
            error = true;
            message += "Nome; "
        }
        if (!newUser.email) {
            error = true;
            message += "email; "
        }
        if (newUser.role === undefined) {
            error = true;
            message += "nível de acesso; "
        }

        if (error) {
            toast.error(`Faltou preencher ${message}`);
        } else {
            const exist = data.filter(x => x.email === newUser.email);
            const existInvite = invites.filter(x => x.email === newUser.email);

            if (exist.length > 0) {
                toast.error(Message.AlreadyExist)
            } else if (existInvite.length > 0) {
                toast.error(Message.InviteExist)
            } else {
                let baseData = { ...newUser, realEstate: userData.realEstate, expire: GetTerm(2).getTime() * 1000 };
                await InviteController.Insert(baseData, async result => {
                    baseData.id = result;
                    baseData.realEstateName = realEstateAgency.name;
                    console.log("Convite", baseData);
                    await ContactController.SendInvite(baseData);
                    loadInvites();
                })
            }

        }
    }

    async function removeInvite(item) {
        if (window.confirm(Message.DeleteConfirm)) {
            await InviteController.Delete(item.id, res => {
                toast.success(Message.DeleteSuccess);
                loadInvites();
            })
        }
    }

    //TODO: Processo de exclusão de conta
    function removeUser(user) { }

    return (
        <>
            <div className="content">
                <div className="row">
                    <div className="col-8">
                        <div className="content-heading">
                            <h3>Usuários</h3>
                        </div>
                    </div>
                    <div className="col-4 text-right">
                        {userData && userData.role === UserHelper.Roles.Administrator ?
                            <Dropdown>
                                <Dropdown.Toggle className="btn btn-warning mt-4">
                                    <i className="si si-plus" /> Adicionar usuário
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <form onSubmit={(e) => sendInvitation(e)}>
                                        <Field type={FieldTypeObj.Text} name={"name"} value={newUser && newUser.name} title={"Nome"} onChange={evt => handleChange(evt)} />
                                        <Field type={FieldTypeObj.Email} name={"email"} value={newUser && newUser.email} title={"Email"} onChange={evt => handleChange(evt)} />
                                        <Field type={FieldTypeObj.Select} name={"role"} nameOption={"name"} keyOption={"value"} options={UserHelper.RoleList} value={newUser && newUser.role} title={"Nível de acesso"} onChange={evt => handleChange(evt, FieldTypeObj.Number)} />
                                        <button type="submit" className="btn btn-success btn-block">Enviar convite</button>
                                    </form>
                                </Dropdown.Menu>
                            </Dropdown>
                            : ""}
                    </div>

                    {data && data.map(item => (
                        <div className="col-md-3">
                            <a className="block block-rounded block-link-shadow text-center" href="javascript:void(0)">
                                <div className="block-content block-content-full bg-gd-dusk">
                                    <img className="img-avatar img-avatar-thumb" src={item && item.picture ? item.picture : "assets/media/avatars/avatar8.jpg"} alt="" />
                                </div>
                                <div className="block-content block-content-full block-content-sm bg-gd-dusk-op">
                                    <p className="font-w600 text-white mb-0">{item.name}</p>
                                    <p className="font-size-sm font-italic text-white-75 mb-0">
                                        {item.email}
                                    </p>
                                </div>
                                <div className="block-content block-content-full">
                                    <div className="row gutters-tiny">
                                        <div className="col-6 py-2">
                                            {UserHelper.RoleTranslator(item.role)}
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-dual" disabled={userData.role !== UserHelper.Roles.Administrator ? true : false} onClick={() => removeUser(item)}><i className="si si-close mr-2" />Remover</button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                    {invites && invites.map(item => (
                        <div className="col-md-3">
                            <a className="block block-rounded block-link-shadow text-center" href="javascript:void(0)">
                                <div className="block-content block-content-full bg-gd-dusk">
                                    <img className="img-avatar img-avatar-thumb" src="assets/media/avatars/avatar8.jpg" alt="" />
                                </div>
                                <div className="block-content block-content-full block-content-sm bg-gd-dusk-op">
                                    <p className="font-w600 text-white mb-0">{item.name}</p>
                                    <p className="font-size-sm font-italic text-white-75 mb-0">
                                        {item.email}
                                    </p>
                                </div>
                                <div className="block-content block-content-full">
                                    <div className="row gutters-tiny">
                                        <div className="col-6">
                                            Convite pendente
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-dual" onClick={() => removeInvite(item)}><i className="si si-close mr-2" />Remover</button>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                    {data && data.length === 0 ?
                        <div className="col-12 text-center py-8">
                            <h4>Você não tem nenhum usuário cadastrado</h4>
                        </div>
                        : ""}
                </div>
            </div>
            <Loading loading={loading} />
            <ToastContainer className="toastr" />
        </>
    );
}
