import React, { useEffect, useState } from "react";
import { ResumeCard } from "./administratorElements/ResumeCard";
import UsersReport from "./administratorElements/UsersReport";
import RealEstateAgencyReport from "./administratorElements/RealEstateAgencyReport";
import AdsReport from "./administratorElements/AdsReport";
import AdsStatusReport from "./administratorElements/AdsStatusReport";
import VisitsReports from "./realEstateAgencyElements/VisitsReport";
import ConversionReport from "./realEstateAgencyElements/ConversionReport";
import { DateHelper } from "@/app/core/util/helpers/DateHelper";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { ChatController } from "@/app/core/base/controllers/ChatController";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";

export default function RealEstateAgencyPanel({ agencyId }) {
  const date = new Date();
  const [startDate, setStartDate] = useState(
    DateHelper.GetFirstDayOfMonth(date.getFullYear(), 0)
  );
  const [endDate, setEndDate] = useState(
    DateHelper.GetLastDayOfMonth(date.getFullYear(), 11)
  );
  const [resume, setResume] = useState({
    ads: 0,
    contacts: 0,
    visits: 0,
    members: 0,
  });
  const [realEstateId, setRealEstateId] = useState(
    agencyId || Storage.getUserData().realEstate
  );

  useEffect(() => {
    if (agencyId && agencyId !== realEstateId) {
      setRealEstateId(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    if (realEstateId) {
      loadResume(realEstateId);
    }
  }, [startDate, endDate, realEstateId]);

  async function loadResume(realEstateId) {
    try {
      const results = await Promise.all([
        ChatController.GetRealEstateChatsCount(realEstateId),
        PropertiesController.GetRealEstatePropertiesCount(realEstateId),
        PropertiesController.GetRealEstatePropertiesVisitsCount(realEstateId),
        AccountController.GetRealEstateMembersCount(realEstateId),
      ]);
      setResume({
        contacts: results[0],
        ads: results[1],
        visits: results[2],
        members: results[3],
      });
    } catch (error) {
      console.log("RealEstateAgencyPanel error", error);
      toast.error("Ocorreu um erro");
    }
  }

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  function parseDate(dateString) {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  return (
    <div className="row">
      <div className="col-8">
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Dashboard
          </h2>
        </div>
      </div>
      <div className="col-4 row">
        <div className="col-md-6">
          <div className="form-group">
            <span>Data de início</span>
            <input
              type="date"
              className="form-control"
              value={formatDate(startDate)}
              onChange={(evt) => setStartDate(parseDate(evt.target.value))}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <span>Data fim</span>
            <input
              type="date"
              className="form-control"
              value={formatDate(endDate)}
              onChange={(evt) => setEndDate(parseDate(evt.target.value))}
            />
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <ResumeCard title="Anúncios" current={resume && resume.ads} />
      </div>
      <div className="col-md-3">
        <ResumeCard title="Contatos" current={resume && resume.contacts} />
      </div>
      <div className="col-md-3">
        <ResumeCard title="Visitas" current={resume && resume.visits} />
      </div>
      <div className="col-md-3">
        <ResumeCard title="Membros" current={resume && resume.members} />
      </div>
      <div className="col-12">
        <VisitsReports
          realEstateId={realEstateId}
          startDate={startDate}
          endDate={endDate}
        />
        <ConversionReport
          realEstateId={realEstateId}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </div>
  );
}
