import { toast } from "react-toastify";
import { Firebase } from "@/app/core/base/BaseFirebase";
import { ErrorHelper } from "../../util/helpers/ErrorHelper";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { FireHelper } from "../../util/helpers/FireHelper";

export const PlansController = {
  db: getFirestore(Firebase),

  ToFirestorePlan: function (data) {
    let updatedData = {
      id: data.id,
      name: data.name,
      count: data.count,
      value: data.value,
      optionals:
        Array.isArray(data.optionals) &&
        data.optionals.map((op) => ({
          icon: op.icon,
          name: op.name,
        })),
      createdDate: data.createdDate ?? Timestamp.now(),
      updatedDate: Timestamp.now(),
    };

    return FireHelper.ToFirestoreDoc(updatedData);
  },

  Insert: async function (data, res) {
    try {
      const inviteRef = collection(this.db, "plans");
      const result = await addDoc(inviteRef, this.ToFirestorePlan(data));

      res(result.id);
    } catch (e) {
      console.log("ERRo", e);
      toast.error(ErrorHelper.HandleMessage(e));
    }
  },

  Update: async function (data, res) {
    try {
      const frankDocRef = doc(this.db, "plans", data.id);
      await updateDoc(frankDocRef, this.ToFirestorePlan(data));
    } catch (e) {
      console.log("ERRo", e);
      toast.error(ErrorHelper.HandleMessage(e));
    }
  },

  Get: async function (id) {
    const docSnap = await getDoc(doc(this.db, "plans", id));
    if (docSnap.exists()) {
      let data = docSnap.data();
      data.id = docSnap.id;
      return data;
    } else {
      return null;
    }
  },

  List: async function (res) {
    const querySnapshot = await getDocs(collection(this.db, "plans"));
    let results = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    res(results);
  },

  Delete: async function (id, res) {
    await deleteDoc(doc(this.db, "plans", id));
    res();
  },
};
