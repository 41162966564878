import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import Grid from "./Grid";
import BigSearch from "./BigSearch";

export default function MainDashboard() {
  const [loading] = useState(false);

  return (
    <>
      <Loading loading={loading} />
      <BigSearch />
      <div className="row">
        <div className="col-lg-12 col-md-12 col-md-12">
          <Grid />
        </div>
      </div>
    </>
  );
}
