import {
  getAuth,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { Firebase } from "@/app/core/base/BaseFirebase";
import { toast } from "react-toastify";
import {
  getFirestore,
  collection,
  addDoc,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
  limit,
  getCountFromServer,
  count,
} from "firebase/firestore";
import { deleteToken, getMessaging } from "firebase/messaging";
import { AuthErrorHelper } from "../../util/helpers/ErrorHelper";
import Storage from "../../util/Storage";
import { UserHelper } from "../../util/helpers/UserHelper";
import { InviteController } from "./InviteController";
import { Timestamp } from "firebase/firestore";
import { FireHelper } from "../../util/helpers/FireHelper";
import { DateHelper } from "../../util/helpers/DateHelper";

export const AccountController = {
  db: getFirestore(Firebase),
  auth: getAuth(Firebase),

  ToFirestoreUser: function (data) {
    let updatedData = {
      bio: data.bio,
      creci: data.creci,
      doc: data.doc,
      email: data.email,
      emailVerified: data.emailVerified,
      fcmTokens: data.fcmTokens || [],
      level: data.level ?? UserHelper.UserLevel.User,
      logo: data.logo,
      name: data.name,
      phone: data.phone,
      picture: data.picture,
      realEstate: data.realEstate,
      uid: data.uid,
      role: data.role ?? UserHelper.Roles.User,
      createdDate: data.createdDate ?? Timestamp.now(),
      updatedDate: Timestamp.now(),
    };
    return FireHelper.ToFirestoreDoc(updatedData);
  },

  CreateUser: async function (data, res) {
    await createUserWithEmailAndPassword(this.auth, data.email, data.password)
      .then(async (userCredential) => {
        try {
          const user = userCredential.user;
          const docRef = doc(this.db, "users", user.uid);
          if (data.inviteId) {
            InviteController.Delete(data.inviteId, (res) => {});
            delete data.inviteId;
          }
          await setDoc(docRef, {
            ...this.ToFirestoreUser(data),
            uid: user.uid,
          });

          this.Login(data);
          // return docRef.id;
        } catch (e) {
          console.error("Error adding document: ", e);
          toast.error(AuthErrorHelper.HandleMessage(e.message));
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(AuthErrorHelper.HandleMessage(errorMessage));
      });
  },

  CreateRealEstate: async function (data, realEstate, res) {
    await createUserWithEmailAndPassword(this.auth, data.email, data.password)
      .then(async (userCredential) => {
        try {
          const user = userCredential.user;
          const realEstateRef = await addDoc(
            collection(this.db, "realEstateAgencies"),
            realEstate
          );
          const docRef = doc(this.db, "users", user.uid);
          await setDoc(docRef, {
            ...this.ToFirestoreUser(data),
            realEstate: realEstateRef.id,
            role: UserHelper.Roles.Administrator,
            uid: user.uid,
          });

          this.Login(data);
          // return docRef.id;
        } catch (e) {
          console.error("Error adding document: ", e);
          toast.error(AuthErrorHelper.HandleMessage(e.message));
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(AuthErrorHelper.HandleMessage(errorMessage));
      });
  },

  Login: async function (data) {
    signInWithEmailAndPassword(this.auth, data.email, data.password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const userData = await this.GetUser(user.uid);
        Storage.setUserData({ ...user, ...userData });

        // Logged in
        toast.success("Bem vindo!");
        setTimeout(() => {
          window.location = "#/";
          window.location.reload();
        }, 300);

        // Update last access date
        const userRef = doc(this.db, "users", user.uid);
        await setDoc(userRef, {
          ...this.ToFirestoreUser(userData),
          uid: user.uid,
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(AuthErrorHelper.HandleMessage(errorMessage), {
          autoClose: 15000,
        });
      });
  },

  Logout: async function () {
    signOut(this.auth);
    deleteToken(getMessaging());
    Storage.deleteUserData();
  },

  GetUser: async function (uid) {
    const userRef = doc(this.db, "users", uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  },

  Update: async function (data) {
    try {
      const userDocRef = doc(this.db, "users", data.uid);
      await updateDoc(userDocRef, { ...this.ToFirestoreUser(data) });
    } catch (e) {
      toast.error(AuthErrorHelper.HandleMessage(e));
    }
  },

  UpdateLastAccess: async function (uid) {
    try {
      const userDocRef = doc(this.db, "users", uid);
      await updateDoc(userDocRef, { updatedDate: Timestamp.now() });
    } catch (e) {
      toast.error(AuthErrorHelper.HandleMessage(e));
    }
  },

  SaveFcmToken: async function (fcmToken) {
    try {
      const useData = Storage.getUserData();
      const userDocRef = doc(this.db, "users", useData.uid);
      await updateDoc(userDocRef, { fcmTokens: [fcmToken] });
    } catch (e) {
      console.log("Failed to save notification token", e);
    }
  },

  ListByRealEstate: async function (realEstate, res) {
    const q = query(
      collection(this.db, "users"),
      where("realEstate", "==", realEstate),
      limit(1000)
    );
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
  },

  List: async function (res) {
    const querySnapshot = await getDocs(
      collection(this.db, "users"),
      limit(1000)
    );
    const results = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
  },

  GetTotalCount: async function () {
    const coll = collection(this.db, "users");
    const snapshot = await getCountFromServer(coll);
    return snapshot.data().count;
  },

  GetTotalCountByLevel: async function (level) {
    const coll = collection(this.db, "users");
    const q = query(coll, where("level", "==", level));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  },

  GetAdminReportsByDate: async function (startDate, endDate) {
    const userData = Storage.getUserData();
    if (!userData || userData.level !== UserHelper.UserLevel.Administrator) {
      return [];
    }

    const usersQuery = query(
      collection(this.db, "users"),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        usuarios: 0,
        corretores: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(usersQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        const level = doc.data().level;
        if (level === UserHelper.UserLevel.Realtor) {
          currentValue.corretores += 1;
        } else if (level === UserHelper.UserLevel.User) {
          currentValue.usuarios += 1;
        }
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },

  GetRealEstateMembersCount: async function (realEstateId) {
    const userData = Storage.getUserData();
    if (!userData || !realEstateId) {
      return 0;
    }
    const coll = collection(this.db, "users");
    const q = query(coll, where("realEstate", "==", realEstateId));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  },
};
