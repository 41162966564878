import { FavoritesController } from "@/app/core/base/controllers/FavoriteController";
import { CurrencyHelper } from "@/app/core/util/helpers/CurrencyHelper";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { toast } from "react-toastify";
import CheckMobile from "@/app/core/util/Screen";
var Carousel = require('react-responsive-carousel').Carousel;

export default function RealtyInLine({ data, favorite }) {
    const userData = Storage.getUserData();
    const [realty, setRealty] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [isMobile] = useState(CheckMobile);

    useEffect(() => {
        setRealty(data);
    }, [data]);

    useEffect(() => {
        setIsFavorite(favorite);
    }, [favorite]);

    function onChange() { }

    function onClickItem() {
        window.open(`#/imovel/${realty.id}`);
    }

    function onClickThumb() { }

    function favoriteItem() {
        if (userData) {
            if (isFavorite === false) {
                FavoritesController.Insert(realty.id, res => { });
                setRealty({
                    ...realty,
                    favoritesCount: realty.favoritesCount + 1,
                });
                setIsFavorite(true);
            } else {
                FavoritesController.Delete(realty.id, res => { });
                setRealty({
                    ...realty,
                    favoritesCount: realty.favoritesCount - 1,
                });
                setIsFavorite(false);
            }
        } else {
            toast.info("Faça login para adicionar aos favoritos")
        }
    }

    return (
      <div className="block block-transparent block-rounded block-bordered row">
        <div className="block-content p-0 overflow-hidden col-md-4">
          <Carousel
            showArrows={true}
            onChange={onChange}
            onClickItem={onClickItem}
            showThumbs={false}
            onClickThumb={onClickThumb}
          >
            {realty &&
              realty.images &&
              realty.images.map((item) => (
                <img
                  key={item.url}
                  src={item.url}
                  className="cursor"
                  style={{ objectFit: "cover", width: "100%", height: 226 }}
                />
              ))}
          </Carousel>
        </div>
        <a
          href={"javascript:void(0)"}
          className="col-md-8"
          style={{ height: "100%" }}
        >
          <div className="block-header border-bottom mb-0 pb-0">
            <a
              className="block-title mb-0 pb-0 text-muted"
              href={realty && `#/imovel/${realty.id}`}
            >
              {realty &&
                `${realty.street}, ${realty.neighborhood} - ${realty.city}`}
              <h4 className="text-dark">{realty.title}</h4>
            </a>
            <div className="block-tools">
              <i
                className={
                  realty && userData && isFavorite
                    ? "fa fa-heart text-danger fa-2x"
                    : "si si-heart text-dark fa-2x"
                }
                onClick={() => favoriteItem()}
              />
            </div>
          </div>
          <div className="block-content ml-3 mt-2 m-0 p-0 d-flex">
            {realty &&
              realty.amenities &&
              realty.amenities.map((opt) => (
                <span key={opt.id} className="d-flex">
                  {opt.optionals
                    .filter((item) => item.active)
                    .map((item) => {
                      return (
                        <p
                          key={item.id}
                          className="mb-0 d-flex align-items-center"
                        >
                          {item && item.icon && (
                            <img
                              className="item ml-2 mr-2 item-circle border border-primary border-2x p-1"
                              title={item.name}
                              src={item.icon}
                              style={{ height: 30, width: 30 }}
                            />
                          )}
                          <small className="text-muted">{isMobile ? "" : item.name}</small>
                        </p>
                      );
                    })}
                </span>
              ))}
            {realty && realty.rooms ? (
              <p className="mb-0 d-flex align-items-center">
                <img
                  className="item ml-2 mr-2 item-circle border border-primary border-2x p-1"
                  src={process.env.PUBLIC_URL + "/assets/media/quartos.png"}
                  style={{ height: 30, width: 30 }}
                />
                <small className="text-muted">
                  {realty.bathrooms} {isMobile ? "":"Quartos"}{" "}
                </small>
              </p>
            ) : (
              ""
            )}
            {realty && realty.parkingLots ? (
              <p className="mb-0 d-flex align-items-center">
                <img
                  className="item ml-2 mr-2 item-circle border border-primary border-2x p-1"
                  src={process.env.PUBLIC_URL + "/assets/media/vagas.png"}
                  style={{ height: 30, width: 30 }}
                />
                <small className="text-muted">
                  {realty.parkingLots} {isMobile ? "":"Vagas"}{" "}
                </small>
              </p>
            ) : (
              ""
            )}
            {realty && realty.bathrooms ? (
              <p className="mb-0 d-flex align-items-center">
                <img
                  className="item ml-2 mr-2 item-circle border border-primary border-2x p-1"
                  src={process.env.PUBLIC_URL + "/assets/media/banheira.png"}
                  style={{ height: 30, width: 30 }}
                />
                <small className="text-muted">
                  {realty.bathrooms} {isMobile ? "":"Banheiros"}{" "}
                </small>
              </p>
            ) : (
              ""
            )}
          </div>
          <div className="block-content block-content-full row mt-4">
            <div className="col-6">
              <p>
                {realty.favoritesCount && realty.favoritesCount > 0
                  ? `${realty.favoritesCount} Interessado(s)`
                  : ""}
              </p>
            </div>
            <div className="col-6 text-right">
              <h3 className="text-primary">
                {realty && realty.value && CurrencyHelper.Format(realty.value)}
              </h3>
            </div>
          </div>
        </a>
      </div>
    );
}