import { CurrencyHelper } from "@/app/core/util/helpers/CurrencyHelper";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
var Carousel = require('react-responsive-carousel').Carousel;

export default function RealtyCard({ data }) {
    const [realty, setRealty] = useState({});

    useEffect(() => {
        setRealty(data);
    }, [data]);

    function onChange() { }

    function onClickItem() {
        window.open(`#/imovel/${realty.id}`);
    }

    function onClickThumb() { }

    return (
      <div className="block block-rounded block-fx-pop">
        <div className="block-content p-0 overflow-hidden">
          <Carousel
            showArrows={true}
            onChange={onChange}
            onClickItem={onClickItem}
            showThumbs={false}
            onClickThumb={onClickThumb}
          >
            {realty &&
              realty.images &&
              realty.images.map((item) => (
                <div key={item.url} className="cursor">
                  <img src={item.url} style={{ height: 300 }} />
                </div>
              ))}
          </Carousel>
          {/* <i className={realty && realty.isFavorite === true ? "fa fa-heart text-danger fa-2x" : "far fa-heart text-white fa-2x"} style={{ position: "absolute", top: 0, margin: 22 }} onClick={() => favoriteItem()} /> */}
          {realty && realty.promoCategory && (
            <button
              className="btn btn-white"
              style={{ position: "absolute", top: 0, margin: 22, right: 0 }}
            >
              {realty.promoCategory.name}
            </button>
          )}
        </div>
        <a href={realty && `#/imovel/${realty.id}`}>
          <div className="block-content">
            <h4 className="h6 mb-2 mb-0">{realty && realty.title}</h4>
            <h5 className="h2 font-w300 push mb-0">
              <span className="font-size-h3 text-muted mb-0">
                {realty && realty.neighborhood} - {realty && realty.city}
              </span>
            </h5>
          </div>
          <div className="block-content m-0 p-0">
            <div className="row p-0 m-0">
              {realty &&
                realty.amenities &&
                realty.amenities.map((item) => (
                  <div key={item.id} className="col-6">
                    <p className="py-3 mb-0">
                      {item && item.icon && (
                        <img src={item.icon.url} style={{ height: 30 }} />
                      )}{" "}
                      <strong>{item.quantity && item.quantity}</strong>{" "}
                      <small>{item.name}</small>
                    </p>
                  </div>
                ))}
              <div className="col-12">
                <h3 className="text-primary">
                  {realty && CurrencyHelper.Format(realty.value)}
                </h3>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
}