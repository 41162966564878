import React from "react";
import PropTypes from "prop-types";
import Pagination from "./Pagination";
import Storage from "@/app/core/util/Storage";

export default function Grid(props) {
  const theme = Storage.getTheme();
  const userData = Storage.getUserData();

  const styledTd = {
    maxWidth: "120px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  const distructTooltip = (row, col) => {
    if (typeof row[col.name] === "object" && !col.innerField) {
      if (row[col.name].type === "div" || row[col.name].type === "span") {
        return col.switch
          ? col.switch[row[col.name]]
            ? col.switch[row[col.name]]
            : row[col.name]
              ? row[col.name]
              : ""
          : "";
      } else {
        return row[col.name].map((item) => {
          return item[col.innerField] ? item[col.innerField] : "";
        });
      }
    } else {
      return col.switch
        ? col.switch[row[col.name]]
          ? col.switch[row[col.name]]
          : row[col.name]
            ? row[col.name]
            : ""
        : "";
    }
  };

  const mapInnerFields = (row, col) => {
    let value = [];
    col.innerField.forEach((subitem) => {
      value =
        value.length > 0 || value.length === undefined
          ? value[subitem]
          : row[col.name][0][subitem];
    });
    return value;
  };

  const transformData = (row, col) => {
    const value = row[col.name];
    switch (col.transform.type) {
      case "document": {
        if (!value) {
          return value;
        } else if (value.length === 14) {
          return value.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          );
        } else if (value.length === 11) {
          return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        } else {
          return value;
        }
      }
      case "date": {
        const date = new Date(value),
          day = date.getDate().toString(),
          dayF = day.length === 1 ? "0" + day : day,
          month = (date.getMonth() + 1).toString(),
          monthF = month.length === 1 ? "0" + month : month,
          yearF = date.getFullYear();
        return dayF + "/" + monthF + "/" + yearF;
      }
      default: {
        break;
      }
    }
  };

  function getSortedColumn(col) {
    if (typeof col.innerField === "object") {
      let field = col.name;
      for (let i = 0; i < col.innerField.length; i++) {
        field += "." + col.innerField[i];
      }
      return field;
    } else if (col.innerField) {
      return col.name + "." + col.innerField;
    } else {
      return col.name;
    }
  }

  function canReadColumn(col) {
    if (!col.level || userData.id === col.level) return true;
  }

  function processReturn(item) {
    return <div>{item}</div>;
  }

  function toFix(num, precision) {
    return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
  }

  return (
    <>
      <table
        style={{ height: "79%" }}
        className="table table-striped table-hover table-borderless table-vcenter font-size-sm"
        id={props.tableId}
      >
        <thead>
          <tr className="text-uppercase">
            {props.columns &&
              props.columns.map(
                (col, idx) =>
                  canReadColumn(col) && (
                    <th
                      className="d-none d-sm-table-cell font-w700"
                      key={`${col.name}-${idx}`}
                    >
                      <span
                        onClick={() => {
                          if (props.orderBy && col.isSortable) {
                            if (props.ordered === getSortedColumn(col)) {
                              props.orderBy(
                                getSortedColumn(col),
                                props.direction === "ASC" ? "DESC" : "ASC"
                              );
                            } else {
                              props.orderBy(getSortedColumn(col), "ASC");
                            }
                          }
                        }}
                        style={col.isSortable ? { cursor: "pointer" } : null}
                      >
                        {col.header}
                        {!col.isSortable ? null : props.ordered ===
                          getSortedColumn(col) ? (
                          props.direction === "ASC" ? (
                            <i className="fas fa-chevron-up ml-2 opacity-sorting" />
                          ) : (
                            <i className="fas fa-chevron-down ml-2 opacity-sorting" />
                          )
                        ) : (
                          <i className="fas fa-sort ml-2 opacity-sorting" />
                        )}
                      </span>
                    </th>
                  )
              )}
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((row, idx) => {
              if (props.limit === undefined &&
                props.filter !== undefined &&
                idx >= (props.filter.page - 1) * 100 &&
                idx <= props.filter.page * 100
              ) {
                return (
                  <tr key={idx}>
                    {props.columns &&
                      props.columns.map(
                        (col, i) =>
                          canReadColumn(col) && (
                            <td
                              style={styledTd}
                              key={`${col.name}-${idx}-${i}`}
                            >
                              <span
                                data-toggle="tooltip"
                                data-placement="left"
                                title={distructTooltip(row, col)}
                                className={`font-size-sm text-${theme}-75`}
                              >
                                {!col.transform
                                  ? !col.switch
                                    ? typeof row[col.name] === "object"
                                      ? row[col.name].length > 0
                                        ? typeof col.innerField === "object"
                                          ? mapInnerFields(row, col)
                                          : row[col.name][0][col.innerField]
                                        : processReturn(row[col.name])
                                      : row[col.name]
                                    : col.switch[row[col.name]]
                                  : transformData(row, col)}
                              </span>
                            </td>
                          )
                      )}
                    {props.hasEditButton && (
                      <td className="text-right">
                        <span
                          className="text-primary-lighter shaved-btn cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => props.editAction({ data: row })}
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Manage"
                        >
                          <i className="fa fa-fw fa-pencil-alt" />
                        </span>
                      </td>
                    )}
                    {props.hasViewButton && (
                      <td className="text-right">
                        <span
                          className="text-primary-lighter shaved-btn cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => props.viewAction({ data: row })}
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Visualizar"
                        >
                          <i className="fas fa-eye" />
                        </span>
                      </td>
                    )}
                    {props.hasDeleteButton && (
                      <td className="text-right">
                        <span
                          className="text-primary-lighter shaved-btn cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => props.deleteAction({ data: row })}
                          data-toggle="tooltip"
                          data-placement="left"
                          title="s"
                        >
                          <i className="fas fa-times" />
                        </span>
                      </td>
                    )}
                  </tr>
                );
              } else {
                return (
                  <tr key={idx}>
                    {props.columns &&
                      props.columns.map(
                        (col, i) =>
                          canReadColumn(col) && (
                            <td
                              style={styledTd}
                              key={`${col.name}-${idx}-${i}`}
                            >
                              <span
                                data-toggle="tooltip"
                                data-placement="left"
                                title={distructTooltip(row, col)}
                                className={`font-size-sm text-${theme}-75`}
                              >
                                {!col.transform
                                  ? !col.switch
                                    ? typeof row[col.name] === "object"
                                      ? row[col.name].length > 0
                                        ? typeof col.innerField === "object"
                                          ? mapInnerFields(row, col)
                                          : row[col.name][0][col.innerField]
                                        : processReturn(row[col.name])
                                      : row[col.name]
                                    : col.switch[row[col.name]]
                                  : transformData(row, col)}
                              </span>
                            </td>
                          )
                      )}
                    {props.hasEditButton && (
                      <td className="text-right">
                        <span
                          className="text-primary-lighter shaved-btn cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => props.editAction({ data: row })}
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Manage"
                        >
                          <i className="fa fa-fw fa-pencil-alt" />
                        </span>
                      </td>
                    )}
                    {props.hasViewButton && (
                      <td className="text-right">
                        <span
                          className="text-primary-lighter shaved-btn cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => props.viewAction({ data: row })}
                          data-toggle="tooltip"
                          data-placement="left"
                          title="Visualizar"
                        >
                          <i className="fas fa-eye" />
                        </span>
                      </td>
                    )}
                    {props.hasDeleteButton && (
                      <td className="text-right">
                        <span
                          className="text-primary-lighter shaved-btn cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => props.deleteAction({ data: row })}
                          data-toggle="tooltip"
                          data-placement="left"
                          title="s"
                        >
                          <i className="fas fa-times" />
                        </span>
                      </td>
                    )}
                  </tr>
                );
              }
            })}
          {(!props.data || props.data.length === 0) && props.columns && (
            <tr key={0}>
              <td colSpan={props.columns.length + 1} align="center">
                <h5 style={{ margin: "0 auto" }}>Vazio</h5>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {props.limit === undefined &&
        props.data &&
        props.filter &&
        props.hide === false &&
        props.data.length > 100 && (
          <Pagination
            paginate={(paginate) => props.setPageNumber(paginate)}
            totalRows={props.filter.totalRows}
            limit={props.filter.limit}
            page={props.filter.page}
            totalPages={props.data && Math.ceil(props.data.length / 100)}
          />
        )}
    </>
  );
}

Grid.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
};
