import React from "react";
import { Link } from "react-router-dom";

export default function Logo(props) {
  return (
    <Link to="/app">
      <img
        src={process.env.PUBLIC_URL + "/assets/media/logo.png"}
        alt="Logo CXP"
        {...props}
      />
    </Link>
  );
}
