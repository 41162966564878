
import UserOptions from "./UserOptions";
import { Dropdown } from "react-bootstrap";
import Toggler from "@/app/core/util/Toggler";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import CheckMobile from "@/app/core/util/Screen";
import { MenuAdministrator, MenuBase, MenuRealEstate, MenuRealtor, MenuUser } from "@/app/core/config/menu";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import { AccountController } from "@/app/core/base/controllers/AccountController";

export default function Header(props) {
  const userData = Storage.getUserData();
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showBudgetModal, setShowBudgetModal] = useState(false);
  let screenWidth = CheckMobile();
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
  }, [userData]);

  function loadBaseMenu() {
    if (screenWidth) {
      return (
        <Dropdown>
          <Dropdown.Toggle variant={Storage.getTheme()}>
            <i className="fa fa-fw fa-bars" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {MenuBase.map(item => (
              <a key={item.id} className="btn btn-lg mr-1 ml-1 btn-white text-dark font-w500" href={item.routerLink}>{item.icon.length > 0 ? <i className={item.icon} /> : ""} {item.name}</a>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )
    } else {
      return <div>
        {MenuBase.map(item => (
          <a key={item.id} className="btn btn-lg mr-1 ml-1 btn-white text-dark font-w500" href={item.routerLink}>{item.icon.length > 0 ? <i className={item.icon} /> : ""} {item.name}</a>
        ))}
      </div>
    }
  }

  function leave() {
    if (window.confirm("Tem certeza que deseja sair?")) {
      AccountController.Logout();
      window.location.reload();
    }
  }

  function loadSessionButton() {
    if (userData === null) {
      return <a className="btn btn-warning" href="#/auth">Acessar conta</a>
    } else {
      return <Dropdown >
        <Dropdown.Toggle variant={Storage.getTheme()} className="btn btn-warning" style={{ backgroundColor: "#FDAFA1", color: "#FFF" }}>
          Minha conta
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {userData && userData.level === UserHelper.UserLevel.Administrator ? <div>
            {MenuAdministrator.map(item => (
              <div key={item.id}>
                <a className="btn mr-1 ml-1 btn-white text-dark font-w500" href={item.routerLink}>{item.icon.length > 0 ? <i className={`mr-2 ${item.icon}`} /> : ""} {item.name}</a>
                <Dropdown.Divider />
              </div>
            ))}
          </div> : ""}
          {userData && userData.level === UserHelper.UserLevel.User ? <div>
            {MenuUser.map(item => (
              <div key={item.id}>
                <a className="btn mr-1 ml-1 btn-white text-dark font-w500" href={item.routerLink}>{item.icon.length > 0 ? <i className={`mr-2 ${item.icon}`} /> : ""} {item.name}</a>
                <Dropdown.Divider />
              </div>
            ))}
          </div> : ""}
          {userData && userData.level === UserHelper.UserLevel.Realtor ? <div>
            {MenuRealtor.map(item => (
              <div key={item.id}>
                <a className="btn mr-1 ml-1 btn-white text-dark font-w500" href={item.routerLink}>{item.icon.length > 0 ? <i className={`mr-2 ${item.icon}`} /> : ""} {item.name}</a>
                <Dropdown.Divider />
              </div>
            ))}
          </div> : ""}
          {userData && userData.level === UserHelper.UserLevel.RealEstate ? <div>
            {MenuRealEstate.map(item => (
              <div key={item.id}>
                <a className="btn mr-1 ml-1 btn-white text-dark font-w500" href={item.routerLink}>{item.icon.length > 0 ? <i className={`mr-2 ${item.icon}`} /> : ""} {item.name}</a>
                <Dropdown.Divider />
              </div>
            ))}
          </div> : ""}

          <a className="btn mr-1 ml-1 btn-white text-dark font-w500" href={"javascript:void(0)"} onClick={() => leave()}><i className={"si si-logout mr-2"} /> Sair</a>
        </Dropdown.Menu>
      </Dropdown>
    }

  }

  function goHome(){
    window.location = "/";
  }

  return (
    <header id="page-header" className={props.color === undefined ? "bg-white" : ""} style={props.color !== undefined ? { backgroundColor: props.color } : {}}>
      {screenWidth === false ?
        <div className="content-header bg-transparent">
          <div>
            {/* {loadTopButtons()} */}
            <img src={process.env.PUBLIC_URL + "/assets/media/logo.png"} className="cursor" onClick={()=> goHome()} style={{ height: 37 }} />
          </div>
          <div>
          </div>
          <div className="d-flex">
            {loadBaseMenu()}
            {/* {loadAdminOptionals()} */}

            {loadSessionButton()}
          </div>
        </div>
        :
        <div className="row p-2">
          <div className="col-6 title py-2">
            <img src={process.env.PUBLIC_URL + "/assets/media/logo.png"} style={{ height: 27 }} />
          </div>
          <div className="col-6 d-flex text-right">
            {loadBaseMenu()}
            {loadSessionButton()}
          </div>
        </div>
      }

      <div id="page-header-loader" className="overlay-header bg-primary-darker">
        <div className="content-header">
          <div className="w-100 text-center">
            <i className="fa fa-fw fa-2x fa-sun fa-spin text-white" />
          </div>
        </div>
      </div>

    </header>
  );
}
