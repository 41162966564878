import {
  XAxis,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  YAxis,
  CartesianGrid,
} from "recharts";
import ReportHelper from "@/app/core/util/helpers/ReportHelper";
import React, { useEffect, useState } from "react";
import { FavoritesController } from "@/app/core/base/controllers/FavoriteController";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { ChatController } from "@/app/core/base/controllers/ChatController";

export default function ConversionReport({ realEstateId, startDate, endDate }) {
  const [data, setData] = useState({ total: 0, ticket: 0, count: 0 });
  const reportHelper = ReportHelper();

  useEffect(() => {
    loadReport();
  }, [startDate, endDate]);

  async function loadReport() {
    if (!startDate || !endDate) {
      return;
    }

    const results = await Promise.all([
      FavoritesController.GetRealEstateReportsByDate(
        realEstateId,
        startDate,
        endDate
      ),
      ChatController.GetRealEstateReportsByDate(realEstateId, startDate, endDate),
    ]);

    const favoritesReports = results[0];
    const chatsReports = results[1];
    const mergedItems = [];

    for (let fr of favoritesReports) {
      for (let cr of chatsReports) {
        if (fr.year === cr.year && fr.month === cr.month) {
          mergedItems.push({
            ...fr,
            ...cr,
            contatos: cr.chats,
            favoritos: fr.favorites,
          });
        }
      }
    }

    process(mergedItems);
  }

  function process(res) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        if (res[i].month === responses[s].value) {
          responses[s].contatos = parseInt(res[i].contatos);
          responses[s].favoritos = parseInt(res[i].favoritos);
        }
      }
    }
    setData(responses);
  }

  return (
    <div className="col-md-12" key={"ticketsReport"}>
      <div className="block block-rounded block-bordered">
        <ul className="nav nav-tabs nav-tabs-block align-items-center border-bottom">
          <li className="nav-item">
            <a
              className="nav-link text-body-color active"
              href="javascript:void(0)"
            >
              Contatos vs Favoritos (conversões vs prospects)
            </a>
          </li>
        </ul>
        {data && data ? (
          <div className="block-content block-content-full">
            <ResponsiveContainer height={300} key={"ativos"}>
              <AreaChart
                width={400}
                height={400}
                data={data}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
                <XAxis dataKey="name" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey={"contatos"}
                  stroke={reportHelper.colors[6]}
                  fill={reportHelper.colors[6]}
                />
                <Area
                  type="monotone"
                  dataKey={"favoritos"}
                  stroke={reportHelper.colors[5]}
                  fill={reportHelper.colors[5]}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
