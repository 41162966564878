import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./assets/css/additional.css";
import "./assets/css/dashmix.css";
import "./assets/css/themes/xwork.min.css";

// Register the service worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

ReactDOM.render(<App />, document.getElementById("root"));