import { CategoriesController } from "@/app/core/base/controllers/CategoriesController";
import React, { useEffect, useState } from "react";

export default function CategoriesShowcase() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    loadCategories();
  }, []);

  function loadCategories() {
    CategoriesController.List((res) => {
      let showCase = [];
      for (let item of res) {
        if (showCase.length < 4) {
          showCase = [
            ...showCase,
            ...item.optionals.filter((x) => x.highlight !== undefined),
          ];
        }
      }
      setCategories(showCase);
    });
  }

  function onCategoryOptionalClick(optional) {
    if (optional) {
      window.location = `#/pesquisa?amenitiesOptionalsIds=${optional.id}`;
    }
  }

  return (
    <div className="ml-3 mr-3">
      <div className="text-center mt-5">
        <h2 className="text-muted font-w700 border-botom">Categorias</h2>
        <hr
          className="border-4x border-warning"
          style={{ color: "#F36161", width: 124 }}
        />
      </div>

      <div className="row">
        {categories &&
          categories.map((item) => (
            <div className="col-md-3 pl-1" style={{ zIndex: 0 }} key={item.id}>
              <div
                className="block block-rounded block-fx-pop cursor"
                onClick={() => onCategoryOptionalClick(item)}
              >
                <div className="block-content p-0 overflow-hidden">
                  <img
                    className="img-fluid rounded-top"
                    src={item && item.highlight}
                    alt=""
                    style={{ height: 200, width: "100%", objectFit: "cover" }}
                  />
                </div>
                <div className="block-content text-center">
                  <h4 className="h6 mb-2">{item && item.name}</h4>
                </div>
              </div>
            </div>
          ))}
        <a className="col-12 mt-3" href={`#/pesquisa?tour=true`}>
          <img
            src={process.env.PUBLIC_URL + "/assets/media/tour.png"}
            style={{ width: "100%" }}
          />
        </a>
      </div>
    </div>
  );
}
