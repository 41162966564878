export const FieldType = [
    { value: 1, name: "Email" },
    { value: 2, name: "Campo de texto" },
    { value: 3, name: "Texto" },
    { value: 4, name: "Lista de opções" },
    // { value: 5, name: "TypeAhead" },
    { value: 6, name: "Date" },
    { value: 7, name: "Moeda" },
    { value: 9, name: "Número" },
    { value: 10, name: "Nº Decimal" },
    { value: 12, name: "Boleano" },
];

export const FieldTypeObj = {
    Email: 1,
    Area: 2,
    Text: 3,
    Select: 4,
    TypeAhead: 5,
    Date: 6,
    Currency: 7,
    TextArea: 8,
    Number: 9,
    Decimal: 10,
    Files: 11,
    Bool: 12,
    Password: 13,
    Mask: 14,
    Checkbox: 15,
};
