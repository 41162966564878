import Modal from 'react-bootstrap/Modal';

function VideoModal({ video, show, onHide }) {
    return (
        <Modal show={show} fullScreen={true} dialogClassName='modal-90w modal-lg' onHide={() => onHide(false)}>
            <Modal.Header closeButton>
                {/* <Modal.Title>Story</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <video src={video} width={"100%"} controls={true} />
            </Modal.Body>
        </Modal>
    );
}

export default VideoModal;