import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table";
import Functions from "@/app/core/util/Functions";
import {
  DateTranslate,
  DateTranslateFromTimestamp,
} from "@/app/core/util/DateTranslater";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import { DateHelper } from "@/app/core/util/helpers/DateHelper";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import AdManagementModal from "../../../imovel/main/components/AdManagementModal";
import { CurrencyHelper } from "@/app/core/util/helpers/CurrencyHelper";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";

export default function AdsPanel() {
  const date = new Date();
  const [data, setData] = useState([]);
  const [report, setReport] = useState({
    fillingIn: 0,
    ongoing: 0,
    done: 0,
    blocked: 0,
  });
  const [startDate, setStartDate] = useState(
    DateHelper.GetFirstDayOfMonth(date.getFullYear(), 0)
  );
  const [endDate, setEndDate] = useState(
    DateHelper.GetLastDayOfMonth(date.getFullYear(), 11)
  );

  const cols = [
    { header: "Nome", isSortable: true, name: "title" },
    {
      header: "Tipo",
      isSortable: true,
      name: "purpose",
      switch: {
        0: <span className="badge badge-success">Aluguel</span>,
        1: <span className="badge badge-info">Compra</span>,
      },
    },
    {
      header: "Status",
      isSortable: true,
      name: "status",
      switch: {
        0: <span className="badge badge-secondary">Não preenchido</span>,
        1: <span className="badge badge-warning">Em andamento</span>,
        2: <span className="badge badge-info">Concluído</span>,
        3: <span className="badge badge-danger">Bloqueado</span>,
      },
    },
    { header: "Área", isSortable: true, name: "metersTotal" },
    { header: "Valor", isSortable: true, name: "value" },
    { header: "Cidade", isSortable: true, name: "city" },
    { header: "Criado em", isSortable: true, name: "createdAt" },
  ];

  useEffect(() => {
    loadData();
  }, [startDate, endDate]);

  async function loadData() {
    PropertiesController.List((res) => {
      for (let item of res) {
        item.metersTotal = item.metersTotal.toLocaleString();
        item.value = CurrencyHelper.Format(item.value);
        item.createdAt = DateTranslate(item.createdDate);
        item.updatedAt = DateTranslate(item.updatedDate);
      }
      setData(res);
    });

    PropertiesController.GetAdminTotalStatusesByDate(startDate, endDate).then(
      (res) => {
        setReport({
          fillingIn: res.get("FillingIn") || 0,
          ongoing: res.get("Ongoing") || 0,
          done: res.get("Done") || 0,
          blocked: res.get("Blocked") || 0,
        });
      }
    );
  }

  function viewAction(item) {
    window.open(`#/imovel/${item.data.id}`, "_blank");
  }

  function formatDate(date) {
    return date.toISOString().split("T")[0];
  }

  function parseDate(dateString) {
    const [year, month, day] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  }

  return (
    <>
      <div className="content">
        <div className="row">
          <div className="col-8">
            <div className="content-heading">
              <h3>Anúncios</h3>
            </div>
          </div>
          <div className="col-4 row">
            <div className="col-md-6">
              <div className="form-group">
                <span>Data de início</span>
                <input
                  type={"date"}
                  className="form-control"
                  value={formatDate(startDate)}
                  onChange={(evt) => setStartDate(parseDate(evt.target.value))}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <span>Data fim</span>
                <input
                  type={"date"}
                  className="form-control"
                  value={formatDate(endDate)}
                  onChange={(evt) => setEndDate(parseDate(evt.target.value))}
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <a
              class="block block-rounded block-link-pop"
              href="javascript:void(0)"
            >
              <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                <div>
                  <i class="fa fa-2x fa-arrow-up text-primary"></i>
                </div>
                <div class="ml-3 text-right">
                  <p class="font-size-h3 font-w300 mb-0">{report.fillingIn}</p>
                  <p class="text-muted mb-0">Aguar. Preenchimento</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-3">
            <a
              class="block block-rounded block-link-pop"
              href="javascript:void(0)"
            >
              <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                <div>
                  <i class="si fa-2x si-settings text-primary"></i>
                </div>
                <div class="ml-3 text-right">
                  <p class="font-size-h3 font-w300 mb-0">{report.ongoing}</p>
                  <p class="text-muted mb-0">Em andamento</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-3">
            <a
              class="block block-rounded block-link-pop"
              href="javascript:void(0)"
            >
              <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                <div>
                  <i class="si fa-2x si-check text-primary"></i>
                </div>
                <div class="ml-3 text-right">
                  <p class="font-size-h3 font-w300 mb-0">{report.done}</p>
                  <p class="text-muted mb-0">Concluído</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-3">
            <a
              class="block block-rounded block-link-pop"
              href="javascript:void(0)"
            >
              <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                <div>
                  <i class="si fa-2x si-trash text-primary"></i>
                </div>
                <div class="ml-3 text-right">
                  <p class="font-size-h3 font-w300 mb-0">{report.blocked}</p>
                  <p class="text-muted mb-0">Bloqueados</p>
                </div>
              </div>
            </a>
          </div>
          <div className="col-12">
            <Table
              title={`${data && data.length} Anúncios`}
              loading={false}
              data={data}
              limit={100}
              columns={cols}
              refreshData={() => loadData()}
              setFilter={(filter) => Functions.ProcessFilter(filter, data)}
              viewAction={(data) => viewAction(data)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
