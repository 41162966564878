export default function ReportHelper() {
    const base = [
      { name: "Jan", value: 0 },
      { name: "Fev", value: 1 },
      { name: "Mar", value: 2 },
      { name: "Abr", value: 3 },
      { name: "Mai", value: 4 },
      { name: "Jun", value: 5 },
      { name: "Jul", value: 6 },
      { name: "Aug", value: 7 },
      { name: "Set", value: 8 },
      { name: "Out", value: 9 },
      { name: "Nov", value: 10 },
      { name: "Dez", value: 11 },
    ];
  
    const monthNames = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
  
    const colors = [
      "#0088FE",
      "#00C49F",
      "#FFBB28",
      "#FF8042",
      "#FFA07A",
      "#FF00D6",
      "#079D49",
      "#FFDB92",
      "#FFC145",
    ];
  
    return {
      base,
      monthNames,
      colors,
    };
  }
  