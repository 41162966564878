import React from "react";
import MainComponent from "./main";
import { renderRoutes } from "react-router-config";
import Layout from "@/app/core/layouts/main";

const EmptyRouter = props => <Layout color="#FFE8E4"><div {...props}>{renderRoutes(props.route.routes, props)}</div></Layout>;

const HomeRouter = () => ({
    path: "/",
    exact: true,
    component: EmptyRouter,
    routes: [
        {
            path: "",
            component: MainComponent
        }
    ]
});

export default HomeRouter();
