export const AuthErrorHelper = {
  HandleMessage: function (message) {
    console.log("Erro firestor", message);
    if (message.includes("auth/email-already-in-use)")) {
      return "Já existe uma conta com esse email";
    } else if (message.includes("auth/invalid-credential")) {
      return "Usuário ou senha são inválidos";
    } else if (message.includes("auth/user-disabled")) {
      return "Essa conta foi suspensa. Entre em contato com o a equipe de atendimento no email contato@habitaki.com.br";
    } else {
      return "Ocorreu um erro desconhecido";
    }
  },
};

export const ErrorHelper = {
  HandleMessage: function (message) {
    console.log("Erro firestor", message);
    if (message && message.includes("Invalid document reference")) {
      return "Esse elemento nao existe";
    } else if (message && message.includes("No document to update")) {
      return "Esse elemento nao existe";
    } else {
      return "Ocorreu um erro desconhecido";
    }
  },
};
