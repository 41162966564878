import { RealEstateTypeController } from "@/app/core/base/controllers/RealEstateTypeController";
import CheckMobile from "@/app/core/util/Screen";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import WhereToLiveInput from "./WhereToLiveInput";
import { toast } from "react-toastify";

export default function MegaBanner() {
  const [data, setData] = useState({
    type: RealtyHelper.AdType.Buy,
    query: "",
  });
  const [realEstateTypes, setRealEstateTypes] = useState([]);
  const isMobile = CheckMobile();

  useEffect(() => {
    loadData();
  }, []);

  function loadData() {
    RealEstateTypeController.List((res) => {
      setRealEstateTypes(res);
    });
  }

  function handleRealEstateTypeState(evt, item) {
    item.checked = evt.target.checked;

    setRealEstateTypes((prevData) => [...prevData]);
  }

  function onSearchButtonClick() {
    executeSearch(data);
  }

  function onSearchTextChanged(text) {
    setData((oldData) => ({ ...oldData, text: text }));
  }

  function onResultClick(infos) {
    const newData = { ...data, text: infos.text };
    setData(newData);
    executeSearch(newData);
  }

  function executeSearch(dataObj) {
    let params = [];

    params.push(
      `type=${dataObj.type === RealtyHelper.AdType.Rent ? "alugar" : "comprar"}`
    );

    if (dataObj?.text && dataObj.text.length > 0) {
      params.push(`query=${dataObj.text}`);
    }

    let selectedTypes = realEstateTypes.filter((t) => t.checked);
    if (selectedTypes.length > 0) {
      params.push(
        `realEstateTypesIds=${selectedTypes.map((x) => x.id).join(",")}`
      );
    }

    window.location = `#/pesquisa?${params.join("&")}`;
  }

  return (
    <div
      id="banner"
      className=""
      style={{ backgroundColor: "#FFE8E4", marginTop: -70, height: 492 }}
    >
      <div className="row py-7">
        {isMobile ? (
          ""
        ) : (
          <div className="col-md-4 text-right">
            <img
              src={process.env.PUBLIC_URL + "/assets/media/banner.png"}
              style={{ height: 537 }}
            />
          </div>
        )}
        <div className={isMobile ? "col-12" : "col-md-8"}>
          <div className={isMobile ? "ml-2 mr-2" : "py-5 mt-5"}>
            <h1 className="font-w700">Encontre seu lar ideal</h1>
            <ul className="nav nav-tabs nav-tabs-alt border-bottom-0 justify-content-center justify-content-md-start mt-5">
              <li className="nav-item">
                <a
                  className={
                    data &&
                    `nav-link text-body-color py-4 ${
                      data.type === RealtyHelper.AdType.Buy ? "active" : ""
                    }`
                  }
                  href="javascript:void(0)"
                  onClick={() =>
                    setData({
                      ...data,
                      type: RealtyHelper.AdType.Buy,
                    })
                  }
                >
                  <span className={isMobile ? "" : "d-none d-md-inline ml-1"}>
                    Comprar
                  </span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={
                    data &&
                    `nav-link text-body-color py-4 ${
                      data.type === RealtyHelper.AdType.Rent ? "active" : ""
                    }`
                  }
                  href="javascript:void(0)"
                  onClick={() =>
                    setData({
                      ...data,
                      type: RealtyHelper.AdType.Rent,
                    })
                  }
                >
                  <span className={isMobile ? "" : "d-none d-md-inline ml-1"}>
                    Alugar
                  </span>
                </a>
              </li>
            </ul>

            <div className="row mt-4">
              <div className="col-md-4">
                <div className="form-group">
                  <span className="font-w700">Tipo de imóvel</span>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="btn btn-block btn-lg btn-white text-left border border-2x"
                      variant="light"
                    >
                      Todos os imóveis
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        width: "100%",
                        maxHeight: 440,
                        overflowY: "scroll",
                      }}
                    >
                      <label className="font-w700">Residencial</label>
                      {realEstateTypes &&
                        realEstateTypes
                          .filter(
                            (x) =>
                              x.purpose === RealtyHelper.Purpose.Residential
                          )
                          .map((item, index) => (
                            <div key={item.id} class="form-group ml-2">
                              <div class="custom-control custom-checkbox mb-1">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id={item.id}
                                  name={item.id}
                                  checked={item.checked}
                                  onChange={(evt) =>
                                    handleRealEstateTypeState(evt, item)
                                  }
                                />
                                <label
                                  class="custom-control-label"
                                  for={item.id}
                                >
                                  {item.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      <label className="font-w700">Comercial</label>
                      {realEstateTypes &&
                        realEstateTypes
                          .filter(
                            (x) => x.purpose === RealtyHelper.Purpose.Comercial
                          )
                          .map((item, index) => (
                            <div key={item.id} class="form-group ml-2">
                              <div class="custom-control custom-checkbox mb-1">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id={item.id}
                                  name={item.id}
                                  checked={item.checked}
                                  onChange={(evt) =>
                                    handleRealEstateTypeState(evt, item)
                                  }
                                />
                                <label
                                  class="custom-control-label"
                                  for={item.id}
                                >
                                  {item.name}
                                </label>
                              </div>
                            </div>
                          ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <span className="font-w700">Onde deseja morar?</span>
                  <WhereToLiveInput
                    onSearchTextChanged={(text) => onSearchTextChanged(text)}
                    report={(infos) => onResultClick(infos)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <button
                  className={
                    isMobile
                      ? "btn-block btn btn-lg btn-warning mt-1"
                      : "btn btn-lg btn-warning mt-4"
                  }
                  onClick={() => onSearchButtonClick()}
                >
                  <i className="far fa-search mr-2"></i>Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
