import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table";
import Functions from "@/app/core/util/Functions";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import {
  DateTranslate,
  DateTranslateFromTimestamp,
} from "@/app/core/util/DateTranslater";
import UserManagementModal from "./components/UserManagementModal";

export default function UsersControlPanel() {
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const cols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Nome", isSortable: true, name: "name" },
    {
      header: "Nível",
      isSortable: true,
      name: "level",
      switch: {
        0: <span className="badge badge-success">Usuário</span>,
        1: <span className="badge badge-warning">Corretor</span>,
        2: <span className="badge badge-danger">Corretor - imobiliária</span>,
        10: <span className="badge badge-dark">Administrador</span>,
      },
    },
    { header: "E-mail", isSortable: true, name: "email" },
    { header: "Criado em", isSortable: true, name: "createdAt" },
    { header: "Último acesso", isSortable: true, name: "loggedAt" },
  ];

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    AccountController.List((res) => {
      for (let user of res) {
        user.createdAt = DateTranslate(user.createdDate);
        user.loggedAt = DateTranslate(user.updatedDate);
      }
      setData(res);
      setRaw(res);
    });
  }

  async function viewAction(item) {
    setSelected(item.data);
    setShowModal(true);
  }

  function closeModal() {
    setSelected({});
    setShowModal(false);
    loadData();
  }

  function handleChange(evt) {
    const inputValue = evt.target.value;
    if (inputValue === "") {
      setData(raw);
      return;
    }

    let newResults = [];
    for (let item of raw) {
      const name = item.name.toLowerCase();
      const email = item.email.toLowerCase();
      if (name.includes(inputValue) || email.includes(inputValue)) {
        newResults.push(item);
      }
      setData(newResults);
    }
  }

  return (
    <>
      <div className="content">
        <div className="row">
          <div className="col-6">
            <div className="content-heading">
              <h3>Usuários</h3>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <div className="input-group input-group-lg mt-3">
                <input
                  type="text"
                  onChange={(evt) => handleChange(evt)}
                  className="form-control form-control"
                  placeholder="Pesquisar..."
                />
                <div className="input-group-append">
                  <span className="input-group-text border-0 bg-body">
                    <i className="fa fa-fw fa-search"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Table
              title={`${data && data.length} Usuários`}
              loading={false}
              data={data}
              limit={100}
              columns={cols}
              refreshData={() => loadData()}
              setFilter={(filter) => Functions.ProcessFilter(filter, data)}
              viewAction={(data) => viewAction(data)}
            />
          </div>
        </div>
      </div>
      <UserManagementModal
        user={selected}
        show={showModal}
        onHide={() => closeModal()}
      />
    </>
  );
}
