import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Field from "@/app/core/components/input/Field";
import Modal from "@/app/core/layouts/main/components/Modal";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import Storage from "@/app/core/util/Storage";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { ContactController } from "@/app/core/base/controllers/ContactController";

export default function ComplaintModal({ show, onHide, property }) {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(Storage.getUserData());
    const [data, setData] = useState({
        email: userData ? userData.email : "",
        name: userData ? userData.name : "",
    });
    
    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    function sendEmail(){
        if(
            !data.name ||
            !data.email ||
            !data.subject ||
            !data.description
        ){
            toast.error(Message.PendingData)
        }else{
            ContactController.SendComplain(data, property);
            setTimeout(()=>{
                onHide()
            },500)
        }
    }

    return (
        <Modal title="Denunciar anúncio" show={show} onHide={onHide} isLoading={loading} onSubmit={()=> sendEmail()}>
            <div className="form-group">
                <span>Anúncio que deseja Denunciar</span>
                <div className="form-control">
                    {property && property.title}
                </div>
            </div>
            <div className="form-group">
                <span>ID do anúncio</span>
                <div className="form-control">
                    {property && property.id}
                </div>
            </div>
            <Field 
                title={"Nome do denunciante"} 
                onChange={evt => handleChange(evt)} 
                name={"name"}
                type={FieldTypeObj.Text}
                value={data.name}/>
            <Field 
                title={"Email do denunciante"} 
                onChange={evt => handleChange(evt)} 
                name={"email"}
                type={FieldTypeObj.Text}
                value={data.email}/>
            <Field 
                title={"Título da denúncia"} 
                onChange={evt => handleChange(evt)} 
                name={"subject"}
                type={FieldTypeObj.Text}
                value={data.subject}/>
            <Field 
                name={"description"} 
                value={data.description}
                type={FieldTypeObj.TextArea}
                title={"Descreva o motivo da denúncia"} 
                onChange={evt => handleChange(evt)}/>
        </Modal>
    );
}
