import Storage from "@/app/core/util/Storage";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import OwnerRealtyCard from "@/app/core/layouts/main-components/realty/OwnerRealtyCard";

export default function AnunciosComponent() {
  const userData = Storage.getUserData();
  const [loading] = useState(false);
  const [data, setData] = useState([]);
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    loadAutomations();
  }, []);

  async function loadAutomations() {
    if (userData.realEstate) {
      PropertiesController.ListByRealEstate((res) => setData(res));
    } else {
      PropertiesController.ListByUser((res) => setData(res));
    }
  }

  return (
    <>
      <div className="content">
        <div className="row">
          <div className="col-8">
            <div className="content-heading">
              <h3>Anúncios</h3>
            </div>
          </div>
          <div className="col-4">
            <a
              className="btn btn-warning mt-4 btn-block"
              href="#/anunciar-imovel"
            >
              <i className="si si-plus" /> Adicionar anúncio
            </a>
          </div>
          {data &&
            data.map((item) => (
              <div key={item.id} className="col-md-4">
                <OwnerRealtyCard data={item} reload={() => loadAutomations()} />
              </div>
              //     <div className="block block-rounded block-fx-pop">
              //         <div className="block-content p-0 overflow-hidden">
              //             <img className="img-fluid rounded-top" style={{ objectFit: "cover", height: 346, width: "100%" }} src={item.images && item.images[0] && item.images[0].url ? item.images[0].url : "assets/media/photos/photo27.jpg"} alt="" />
              //         </div>
              //         <div className="block-content">
              //             <h4 className="h6 mb-2">{item.title}</h4>
              //             <h5 className="h2 font-w300 push">
              //                 {CurrencyHelper.Format(item.value)} <span className="font-size-h3 text-muted"> - {RealtyHelper.TranslateAdType(item.type)}</span>
              //             </h5>
              //         </div>
              //         <div className="block-content block-content-full">

              //         </div>
              //     </div>
            ))}
          {data && data.length === 0 ? (
            <div className="col-12 text-center py-8">
              <h4>Você não tem anúncios</h4>
              <a className="btn btn-warning mt-4" href="#/anunciar-imovel">
                <i className="si si-plus" /> Clique aqui para adicionar anúncio
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Loading loading={loading} />
      <ToastContainer className="toastr" />
    </>
  );
}
