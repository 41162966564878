import BaseService from "@/app/core/base/BaseService";
import Axios from "axios";

export const AddressController = {
  ExtractAddressData: function ({
    street,
    number,
    complement,
    neighborhood,
    district,
    city,
    state,
    cep,
    country,
  }) {
    const components = [
      street,
      number,
      complement,
      neighborhood,
      district,
      city,
      state,
      cep,
      country,
    ];
    return components
      .filter((component) => component && component.trim() !== "")
      .join(", ");
  },

  GetAddress: function (cep, res) {
    Axios.get(`https://viacep.com.br/ws/${cep}/json`, {})
      .then(function (response) {
        let location = {};
        if (response.data.logradouro) {
          location.street = `${response.data.logradouro}`;
        }
        if (response.data.bairro) {
          location.neighborhood = `${response.data.bairro}`;
        }
        if (response.data.localidade) {
          location.city = `${response.data.localidade}`;
        }
        if (response.data.uf) {
          location.state = `${response.data.uf}`;
        }

        res({ ...location, postalCode: cep });
      })
      .catch(function (error) {
        if (error) {
          console.log("AddressController", error);
        }
      });
  },

  GetGeocodeData: async function (addressData) {
    try {
      const response = await Axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: this.ExtractAddressData(addressData),
            key: "AIzaSyCaCcyoHcSIwb5DSBtku4XdwrXQZ-elwBQ", // Replace with your actual API key
          },
        }
      );

      const result = response.data.results[0];
      const location = result.geometry.location;
      const formattedAddress = result.formatted_address;
      return { location, formattedAddress };
    } catch (error) {
      if (error) {
        console.log("AddressController", error);
      }
      return null;
    }
  },

  GetGeocodeDataAddresses: async function (addressQuery) {
    try {
      const response = await Axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address: addressQuery,
            key: "AIzaSyCaCcyoHcSIwb5DSBtku4XdwrXQZ-elwBQ", // Replace with your actual API key
          },
        }
      );

      return response.data.results;
    } catch (error) {
      if (error) {
        console.log("AddressController", error);
      }
      return null;
    }
  },
};
