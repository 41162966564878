import Table from "@/app/core/components/table";
import Functions from "@/app/core/util/Functions";
import React, { useEffect, useState } from "react";
import { RealEstateTypeController } from "@/app/core/base/controllers/RealEstateTypeController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import RealEstateTypeModal from "./RealEstateTypeModal";

export default function RealEstateTypeCard(props) {
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState({});
    const [showModal, setShowModal] = useState(false);
    const cols = [
        { header: "#ID", isSortable: true, name: "id" },
        { header: "Nome", isSortable: true, name: "name" },
        { header: "Opções", isSortable: true, name: "count" },
    ];

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        RealEstateTypeController.List(res => {
            for (let s in res) {
                if (res[s].optionals) {
                    res[s].count = res[s].optionals.length;
                } else {
                    res[s].count = 0;
                }
            }
            setData(res)
        });
    }

    async function deleteAction(item) {
        if (window.confirm(Message.DeleteConfirm)) {
            RealEstateTypeController.Delete(item.data.id, res => {
                toast.success(Message.DeleteSuccess);
                loadData();
            })
        }
    }

    async function viewAction(item) {
        setSelected(item.data);
        setShowModal(true)
    }

    function closeModal() {
        setSelected({})
        setShowModal(false);
        loadData();
    }

    return (
        <div>
            <Table
                title="Tipos de imóveis"
                loading={false}
                data={data}
                columns={cols}
                refreshData={() => loadData()}
                setFilter={(filter) => Functions.ProcessFilter(filter, data)}
                deleteAction={(data) => deleteAction(data)}
                viewAction={(data) => viewAction(data)}
                insertAction={() => setShowModal(true)} />

            <RealEstateTypeModal
                category={selected}
                show={showModal}
                onHide={() => closeModal()}
            />
        </div>
    )
}