import { FavoritesController } from "@/app/core/base/controllers/FavoriteController";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import RealtyInLine from "@/app/core/layouts/main-components/realty/RealtyInLine";
import Loading from "@/app/core/layouts/main/components/Loading";
import { ErrorHelper } from "@/app/core/util/helpers/ErrorHelper";
import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";

export default function FavoritosComponent() {
  const [properties, setProperties] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadFavorites();
  }, []);

  async function loadFavorites() {
    try {
      setLoading(true);

      const favorites = await FavoritesController.ListMyFavorites(res => {});
      const propertiesIds = favorites.map((f) => f.propertyId);
      const properties = await PropertiesController.ListByIds(propertiesIds);

      setFavorites(favorites);
      setProperties(properties);
    } catch (error) {
      console.log("Favorites error", error);
      toast.error(ErrorHelper.HandleMessage(error));
    } finally {
      setLoading(false);
    }
  }

  function isFavorite(property) {
    for (let favorite of favorites) {
      if (favorite.propertyId === property.id) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <div className="content">
        <div className="row">
          <div className="col-12">
            <p>
              Seus favoritos
            </p>
          </div>
          <div className="col-12">
            {properties &&
              properties.map((item) => (
                <RealtyInLine
                  key={item.id}
                  data={item}
                  favorite={() => isFavorite(item)}
                />
              ))}
          </div>
        </div>
      </div>
      <Loading loading={loading} />
      <ToastContainer className="toastr" />
    </>
  );
}
