import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Field from "@/app/core/components/input/Field";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { Tab, Tabs } from "react-bootstrap";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import RealtyInLine from "@/app/core/layouts/main-components/realty/RealtyInLine";
import OwnerRealtyCard from "@/app/core/layouts/main-components/realty/OwnerRealtyCard";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { FavoritesController } from "@/app/core/base/controllers/FavoriteController";
import RealEstateAgencyPanel from "@/app/modules/dashboard/components/panels/RealEstateAgencyPanel";

export default function AgencyManagementModal({ show, onHide, agency }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [favorites, setFavorites] = useState([]);
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        if (show && agency && agency.id) {
            setData(agency);
            loadInfos()
        } else {
            setData({})
        }
    }, [show]);
    
    async function loadInfos(){
        PropertiesController.ListRealEstateByAngency(data.id, res => setProperties(res));
    }

    function handleChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    return (
        <Modal title="Imobiliária" show={show} onHide={onHide} isLoading={loading}>
            <div class="block-content block-content-full text-center bg-body-light">
                <img class="img-avatar img-avatar-thumb" src={data && data.logo ? data.logo :"assets/media/avatars/avatar14.jpg"} />
                <div class="mt-2">
                    <p class="font-w600 mb-0">{data && data.name}</p>
                </div>
            </div>
            <Tabs  
                defaultActiveKey="relatorio" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="relatorio" title="Relatório" className="">
                    <RealEstateAgencyPanel id={data && data.id && data.id} />
                </Tab>
                <Tab eventKey="resumo" title="Informações" className="block-content">
                        <div className="form-group">
                            <span className="font-w700">Telefone</span>
                            <p>{data && data.phone}</p>
                        </div>
                        <div className="form-group">
                            <span className="font-w700">CNPJ</span>
                            <p>{data && data.taxId}</p>
                        </div>
                        <div className="form-group">
                            <span className="font-w700">Endereço</span>
                            <p>{data && data.street} - {data && data.neighborhood}, {data && data.city} | {data && data.state}</p>
                        </div>
                        <div className="form-group">
                            <span className="font-w700">Bio</span>
                            <p style={{ whiteSpace: "pre-line" }}>{data && data.bio}</p>
                        </div>
                </Tab>
                <Tab eventKey="anuncios" title={properties && `(${properties.length}) Anúncios`}>
                    <div className="row">
                        {properties && properties.map(item => (
                        <div className="col-md-6">
                            <OwnerRealtyCard data={item} favorite={true} />
                        </div>
                        ))}
                    </div>
                </Tab>
            </Tabs>
        </Modal>
    );
}
