import Modal from "@/app/core/layouts/main/components/Modal";
import React, { useEffect, useState } from "react";
import Field from "@/app/core/components/input/Field";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import { toast } from "react-toastify";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import { AddressController } from "@/app/core/base/controllers/AddressController";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";

export default function EditAgencyModal({ show, onHide, realEstate }) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            setData(realEstate);
        }
    }, [show]);

    function handleRealChange(evt, type) {
        setData(OnDataChange(data, evt, type));
    }

    async function save() {
        let error = false, message = "";

        if (!data.name) {
            error = true;
            message += "Nome da imobiliária; ";
        }
        if (!data.phone) {
            error = true;
            message += "Telefone; ";
        }
        if (!data.taxId) {
            error = true;
            message += "CNPJ; ";
        }
        if (!data.postalCode) {
            error = true;
            message += "CEP; ";
        }

        if (error) {
            toast.error("Esta faltando preencher: " + message);
        } else {
            if (!UserHelper.ValidaCNPJ(data.taxId)) {
                toast.error(Message.CNPJInvalid);
            } else {
                setLoading(true);
                await RealEstateAgencyController.Update(data, res => {
                    toast.success("Salvo com sucesso");
                });
                onHide()
                setLoading(false);
            }
        }
    }

    function loadAddressByPostalCode(cep) {
        AddressController.GetAddress(cep, res => {
            setData({ ...data, ...res });
        })
    }

    return (
        <Modal title="Editar" show={show} onHide={onHide} isLoading={loading} onSubmit={() => save()}>
            <Field name="name" onChange={evt => handleRealChange(evt)} title={"Nome da imobiliária"} value={data && data.name} type={FieldTypeObj.Text} />
            <Field name="phone" onChange={evt => handleRealChange({ target: { name: evt.target.name, value: evt.target.value.replace(/[^\d]+/g, "") } })} title={"Telefone comercial"} value={data && data.phone} type={FieldTypeObj.Mask} mask={"(99) 9999-99999"} />
            <Field name="taxId" onChange={evt => handleRealChange({ target: { name: evt.target.name, value: evt.target.value.replace(/[^\d]+/g, "") } })} title={"CNPJ"} value={data && data.taxId} type={FieldTypeObj.Mask} mask={"99.999.999/9999-99"} />
            <div className="row">
                <div className="col-7">
                    <Field name="postalCode" onChange={evt => {
                        handleRealChange({ target: { name: evt.target.name, value: evt.target.value.replace(/[^\d]+/g, "") } });
                        if (evt.target.value.replace(/\D/g, "").length === 8) {
                            loadAddressByPostalCode(evt.target.value.replace(/\D/g, ""))
                        }
                    }} title={"CEP"} value={data && data.postalCode} type={FieldTypeObj.Mask} mask={"99999-999"} />
                </div>
                <div className="col-5">
                    <a className="btn btn-dual btn-block mt-4" href="https://buscacepinter.correios.com.br/app/endereco/" target="_blank" rel="noreferrer">Não sabe seu CEP?</a>
                </div>
            </div>
            <Field name="bio" onChange={evt => handleRealChange(evt)} title={"Apresentação da imobiliária"} value={data && data.bio} type={FieldTypeObj.TextArea} />
        </Modal>
    );
}
