import { Firebase } from "@/app/core/base/BaseFirebase";
import { toast } from "react-toastify";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getMetadata,
  uploadBytes,
} from "firebase/storage";

export const FileController = {
  db: getFirestore(Firebase),
  storage: getStorage(Firebase, "gs://habitaki-e1021.appspot.com"),

  Upload: async function (path, file, response) {
    const logoRef = ref(this.storage, path);
    const uploadTask = uploadBytesResumable(logoRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.info("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.info("Upload is paused");
            break;
          case "running":
            console.info("Upload is running");
            break;
          default:
            console.info(`Upload is ${snapshot.state}`);
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("FileController LogoUpload error", error);
        toast.error("Ocorreu um erro ao fazer o upload");
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          response(downloadURL);
        });
      }
    );
  },

  UploadFile: async function (path, file) {
    try {
      const fileRef = ref(this.storage, path);
      await uploadBytes(fileRef, file);
      const metadata = await getMetadata(fileRef);
      const downloadURL = await getDownloadURL(fileRef);
      return {
        url: downloadURL,
        name: metadata.name,
        contentType: metadata.contentType,
        fullPath: metadata.fullPath,
        bucket: metadata.bucket,
      };
    } catch (error) {
      console.log("FileController UploadFile error", error);
      toast.error("Ocorreu um erro ao fazer o upload");
      return undefined;
    }
  },
};
