import { AddressController } from "@/app/core/base/controllers/AddressController";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import { StringHelper } from "@/app/core/util/helpers/StringHelper";
import useDebounce from "@/app/core/util/UseDebounce";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function WhereToLiveInput({ report, onSearchTextChanged }) {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [properties, setProperties] = useState([]);
  const [realEstateAgencies, setRealEstateAgencies] = useState([]);
  const debouncedQuery = useDebounce(query, 1000);

  useEffect(() => {
    onSearchTextChanged(query);
  }, [query]);

  useEffect(() => {
    if (debouncedQuery) {
      handleSearch();
    }
  }, [debouncedQuery]);

  function onItemSelected(selectedResult) {
    if (selectedResult) {
      report(selectedResult);
    }
  }

  async function handleSearch() {
    if (!query || query === "") {
      return;
    }

    setLoading(true);
    try {
      await Promise.all([
        searchAddresses(),
        searchRealEstateAgencies(),
        searchProperties(),
      ]);
    } catch (e) {
      toast.error("Failed to query properties");
    } finally {
      setLoading(false);
    }
  }

  async function searchAddresses() {
    const results = await AddressController.GetGeocodeDataAddresses(query);

    if (!Array.isArray(results)) {
      return;
    }

    function extractAddressComponents(addressComponents) {
      let extractedComponents = [];
      addressComponents.forEach((component) => {
        // Rua
        if (component.types.includes("route")) {
          extractedComponents.push(component.short_name);
        }
        // Bairro
        if (
          component.types.includes("sublocality") ||
          component.types.includes("sublocality_level_1")
        ) {
          extractedComponents.push(component.short_name);
        }
        // Cidade
        if (component.types.includes("administrative_area_level_2")) {
          extractedComponents.push(component.short_name);
        }
      });

      // Formata a string com os components do endereço
      return extractedComponents.join(", ");
    }

    const addresses = results
      .filter((r) => r.types)
      .map((r) => ({
        id: StringHelper.GenerateUniqueId(),
        text: extractAddressComponents(r.address_components),
      }));

    setAddresses(addresses);
  }

  async function searchRealEstateAgencies() {
    const results = await RealEstateAgencyController.Query({
      queryString: query,
    });
    setRealEstateAgencies(results.map((r) => ({ id: r.id, text: r.title })));
  }

  async function searchProperties() {
    const properties = await PropertiesController.QueryProperties({
      queryString: query,
    });
    setProperties(properties.map((p) => ({ id: p.id, text: p.title })));
  }

  return (
    <div style={{ zIndex: 2 }} key={"WhereToLive"}>
      <input
        type="text"
        className="form-control form-control-lg"
        placeholder="Digite o nome da rua, do bairro ou cidade"
        onChange={(evt) => setQuery(evt.target.value)}
        value={query}
      />
      {query && query.length >= 4 ? (
        <div
          className="block block-rounded m-0 block-bordered"
          style={{ zIndex: 99999999999 }}
        >
          {loading === true ? (
            <div className="text-center">
              <i className="fa fa-2x fa-circle-notch fa-spin text-info py-3" />
            </div>
          ) : (
            <div className="m-2">
              {properties && properties.length > 0 ? (
                <div>
                  <strong>Endereços</strong>
                  {properties &&
                    properties.map((item, index) => (
                      <div
                        onClick={() => onItemSelected(item)}
                        class="form-group ml-2"
                      >
                        <div class="custom-control custom-checkbox mb-1">
                          <label className="cursor">{item.text}</label>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}
              {addresses && addresses.length > 0 ? (
                <div>
                  <strong>Ruas</strong>
                  {addresses &&
                    addresses.map((item, index) => (
                      <div
                        onClick={() => onItemSelected(item)}
                        class="form-group ml-2"
                      >
                        <div class="custom-control custom-checkbox mb-1">
                          <label className="cursor">{item.text}</label>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}
              {realEstateAgencies && realEstateAgencies.length > 0 ? (
                <div>
                  <strong>Imobiliárias</strong>
                  {realEstateAgencies &&
                    realEstateAgencies.map((item, index) => (
                      <div
                        onClick={() => onItemSelected(item)}
                        class="form-group ml-2"
                      >
                        <div class="custom-control custom-checkbox mb-1">
                          <label className="cursor">{item.text}</label>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
