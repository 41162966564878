import { Firebase } from "@/app/core/base/BaseFirebase";
import { toast } from "react-toastify";
import {
  getFirestore,
  collection,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  Timestamp,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { ErrorHelper } from "../../util/helpers/ErrorHelper";
import { FireHelper } from "../../util/helpers/FireHelper";
import { DateHelper } from "../../util/helpers/DateHelper";
import algoliasearch from "algoliasearch";

export const RealEstateAgencyController = {
  db: getFirestore(Firebase),

  ToFirestoreRealEstateAgency: function (data) {
    let updatedData = {
      bio: data.bio,
      city: data.city,
      id: data.id,
      logo: data.logo,
      name: data.name,
      neighborhood: data.neighborhood,
      phone: data.phone,
      postalCode: data.postalCode,
      state: data.state,
      street: data.street,
      taxId: data.taxId,
      createdDate: data.createdDate ?? Timestamp.now(),
      updatedDate: Timestamp.now(),
    };
    return FireHelper.ToFirestoreDoc(updatedData);
  },

  Update: async function (data, res) {
    try {
      const frankDocRef = doc(this.db, "realEstateAgencies", data.id);
      await updateDoc(frankDocRef, this.ToFirestoreRealEstateAgency(data));
    } catch (e) {
      console.log("RealEstateAgencyController: ", e);
      toast.error(ErrorHelper.HandleMessage(e));
    }
  },

  Get: async function (id, res) {
    const docRef = doc(this.db, "realEstateAgencies", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      res(docSnap.data());
    } else {
      res(null);
    }
  },

  List: async function (res) {
    const querySnapshot = await getDocs(
      collection(this.db, "realEstateAgencies")
    );
    const results = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
  },

  Query: async function ({queryString, hitsPerPage}) {
    const searchClient = algoliasearch(
      "Z1II48E5CS",
      "070f1e8ed35532ebdf13422104c63f2d"
    );

    let index = searchClient.initIndex("realEstateAgencies");
    const { hits } = await index.search(queryString, {
      hitsPerPage: hitsPerPage || 100,
    });

    return hits.map((doc) => ({ ...doc, id: doc.objectID }));
  },

  GetTotalCount: async function () {
    const coll = collection(this.db, "realEstateAgencies");
    const snapshot = await getCountFromServer(coll);
    return snapshot.data().count;
  },

  GetReportsByDate: async function (startDate, endDate) {
    const realEstateAgenciesQuery = query(
      collection(this.db, "realEstateAgencies"),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        ativos: 0,
        inativas: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(realEstateAgenciesQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        if (doc.data().active === true) {
          currentValue.ativos += 1;
        } else {
          currentValue.inativas += 1;
        }
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },
};
