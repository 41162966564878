import React from "react";
import Toggler from "@/app/core/util/Toggler";
import Storage from "@/app/core/util/Storage";

export default function HeaderOptions(props) {
  const theme = Storage.getTheme();
  const FilterIcon = props.filterIsDirty
    ? require(`@/assets/img/icons/filter-dirty-${theme}.svg`)
    : require(`@/assets/img/icons/filter-empty-${theme}.svg`);

    return (
    <div className={`block-options text-themed-${Storage.getTheme()}`}>
      <button
        type="button"
        disabled={props.loading}
        className="btn-block-option"
        name="Pesquisar"
        onClick={() => props.refreshData()} 
        data-action="state_toggle"
        data-action-mode="demo"
      >
        <i className={`si si-refresh text-themed-${theme}`} />
      </button>
      {props.insertAction && (
        <button
          onClick={() => props.insertAction()}
          type="button"
          className="btn-block-option"
        >
          <i className={`si si-plus text-themed-${theme}`} />
        </button>
      )}
      {props.hasFilters && (
        <button
          type="button"
          className="btn-block-option"
          onClick={() => Toggler.toggleSideOverlay()}
        >
          <img src={FilterIcon} width="18" height="18" alt="Filter" />
        </button>
      )}
      <div className="dropdown">
        {props.aditionalOptions && (
          <>
            <button
              type="button"
              className="btn-block-option"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className={`si si-wrench text-themed-${theme}`} />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <a className="dropdown-item" href="javascript:void(0)">
                <i
                  className={`fa fa-fw fa-sync fa-spin text-warning mr-1 text-themed-${theme}`}
                />{" "}
                Pending
              </a>
              <a className="dropdown-item" href="javascript:void(0)">
                <i
                  className={`far fa-fw fa-times-circle text-danger mr-1 text-themed-${theme}`}
                />{" "}
                Cancelled
              </a>
              <a className="dropdown-item" href="javascript:void(0)">
                <i
                  className={`far fa-fw fa-check-circle text-success mr-1 text-themed-${theme}`}
                />{" "}
                Cancelled
              </a>
              <div role="separator" className="dropdown-divider" />
              <a className="dropdown-item" href="javascript:void(0)">
                <i className={`fa fa-fw fa-eye mr-1 text-themed-${theme}`} />{" "}
                View All
              </a>
            </div>
          </>
        )}
        {props.hasDownload && (
          <button type="button" className="btn-block-option">
            <i className={`si si-cloud-download text-themed-${theme}`} />
          </button>
        )}
      </div>
    </div>
  );
}
