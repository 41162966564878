import {
    XAxis,
    AreaChart,
    Area,
    Tooltip,
    ResponsiveContainer,
  } from "recharts";
  import ReportHelper from "@/app/core/util/helpers/ReportHelper";
  import React, { useEffect, useState } from "react";
  import { DateHelper } from "@/app/core/util/helpers/DateHelper";
  import { FavoritesController } from "@/app/core/base/controllers/FavoriteController";
  import { ChatController } from "@/app/core/base/controllers/ChatController";
  
  export default function ContactsAndConversionsOfAd({propertyId}) {
    const date = new Date();
    const [data, setData] = useState({ total: 0, ticket: 0, count: 0 });
    const [startDate, setStartDate] = useState(
      DateHelper.GetFirstDayOfMonth(date.getFullYear(), 0)
    );
    const [endDate, setEndDate] = useState(
      DateHelper.GetLastDayOfMonth(date.getFullYear(), 11)
    );
    const reportHelper = ReportHelper();
  
    useEffect(() => {
      loadReport();
    }, [startDate, endDate, propertyId]);
  
    useEffect(() => {
      loadReport();
    }, [startDate, endDate, propertyId]);
  
    async function loadReport() {
        if(propertyId){

            const results = await Promise.all([
              FavoritesController.GetPropertyByDate(propertyId, startDate, endDate),
              ChatController.GetPropertyReportsByDate(propertyId, startDate, endDate),
            ]);
        
            const favoritesReports = results[0];
            const chatsReports = results[1];
            const mergedItems = [];
        
            for (let fr of favoritesReports) {
              for (let cr of chatsReports) {
                if (fr.year === cr.year && fr.month === cr.month) {
                  mergedItems.push({
                    ...fr,
                    ...cr,
                    favoritos: fr.favorites,
                    contatos: cr.chats,
                  });
                }
              }
            }
            process(mergedItems);
        }
    }
  
    function process(res) {
      let responses = reportHelper.base;
  
      for (let s in responses) {
        for (let i in res) {
          if (res[i].month === responses[s].value) {
            responses[s].favoritos = parseInt(res[i].favoritos);
            responses[s].contatos = parseInt(res[i].contatos);
          }
        }
      }
      setData(responses);
    }
  
    function processDate(year) {
      setStartDate(DateHelper.GetFirstDayOfMonth(year, 0));
      setEndDate(DateHelper.GetLastDayOfMonth(year, 11));
    }
  
    return (
      <div className="col-md-12" key={"ticketsReport"}>
        <div className="block block-rounded block-bordered">
          <ul className="nav nav-tabs nav-tabs-block align-items-center border-bottom">
            <li className="nav-item">
              <a
                className="nav-link text-body-color active"
                href="javascript:void(0)"
              >
                Contatos vs Favoritos (conversões vs prospects)
              </a>
            </li>
            <li className="nav-item ml-auto">
              <div className="block-options mr-3">
                <button
                  className="btn btn-dual"
                  onClick={() => processDate(date.getFullYear() - 2)}
                >
                  {date.getFullYear() - 2}
                </button>
                <button
                  className="btn btn-dual"
                  onClick={() => processDate(date.getFullYear() - 1)}
                >
                  {date.getFullYear() - 1}
                </button>
                <button
                  className="btn btn-dual"
                  onClick={() => processDate(date.getFullYear())}
                >
                  {date.getFullYear()}
                </button>
              </div>
            </li>
          </ul>
          {data && data ? (
            <div className="block-content block-content-full">
              <ResponsiveContainer height={300} key={"ativos"}>
                <AreaChart
                  width={400}
                  height={400}
                  data={data}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <XAxis dataKey="name" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey={"contatos"}
                    stroke={reportHelper.colors[6]}
                    fill={reportHelper.colors[6]}
                  />
                  <Area
                    type="monotone"
                    dataKey={"favoritos"}
                    stroke={reportHelper.colors[5]}
                    fill={reportHelper.colors[5]}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
  