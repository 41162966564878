import Storage from "@/app/core/util/Storage";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";
import RealtyInLine from "@/app/core/layouts/main-components/realty/RealtyInLine";
import SearchTerms from "../../home/main/components/SearchTerms";
import CheckMobile from "@/app/core/util/Screen";
import { Tab, Tabs } from "react-bootstrap";
import SearchTermsModal from "./components/SearchTermsModal";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";
import { CurrencyHelper } from "@/app/core/util/helpers/CurrencyHelper";

export default function SearchComponent(props) {
  const userData = Storage.getUserData();
  const enviroment = process.env.REACT_APP_ENV;

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [orderBy, setOrderBy] = useState(null);
  const [adType, setAdType] = useState(RealtyHelper.AdType.Buy);
  const [results, setResults] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [isMobile] = useState(CheckMobile);
  const [humanizedQuery, setHumanizedQuery] = useState("");

  useEffect(() => {
    loadInitialAdType();
  }, []);

  function loadInitialAdType() {
    const type = getUrlComponent("type");
    switch (type) {
      case "comprar":
        setAdType(RealtyHelper.AdType.Buy);
        break;
      case "alugar":
        setAdType(RealtyHelper.AdType.Rent);
        break;
      default:
        break;
    }
  }

  function getUrlComponent(componentName) {
    if (!props) {
      return null;
    }
    const params = new URLSearchParams(props.location.search);
    return params.get(componentName);
  }

  function isPropertyInMyFavorites(item) {
    let result = false;
    favorites.forEach((favorite) => {
      if (item.id === favorite.propertyId) {
        result = true;
      }
    });
    return result;
  }

  return (
    <>
      <div className="content">
        <div className="row">
          <div className="col-8">
            <p>{humanizedQuery}</p>
          </div>
          <div className="col-4">
            <div className="form-group">
              <select
                className="form-control"
                onChange={(evt) => setOrderBy(evt.target.value)}
              >
                <option value={null}>Ordenar por</option>
                <option value={"asc"}>Menor preço</option>
                <option value={"desc"}>Maior preço</option>
              </select>
            </div>
          </div>
          <div className="col-md-4">
            {isMobile ? (
              <button
                className="btn btn-outline-info btn-block my-2"
                onClick={() => setShowModal(true)}
              >
                Editar Filtros
              </button>
            ) : (
              <SearchTerms
                reportHumanizedQuery={(data) => setHumanizedQuery(data)}
                reportProperties={(properties) => setResults(properties)}
                reportFavorites={(favorites) => setFavorites(favorites)}
                orderBy={orderBy}
                type={adType}
                props={props}
              />
            )}
          </div>
          <div className="col-md-8">
            <Tabs activeKey={adType} onSelect={(tabKey) => setAdType(tabKey)}>
              <Tab eventKey={RealtyHelper.AdType.Buy} title="Comprar">
                {results && results.length === 0 ? (
                  <div className="text-center py-5">
                    <h3 className="text-muted">Nenhum resultado encontrado</h3>
                  </div>
                ) : (
                  ""
                )}
                {results &&
                  results.map((item) => (
                    <RealtyInLine
                      key={item.id}
                      data={item}
                      favorite={() => isPropertyInMyFavorites(item)}
                    />
                  ))}
              </Tab>
              <Tab eventKey={RealtyHelper.AdType.Rent} title="Alugar">
                {results && results.length === 0 ? (
                  <div className="text-center py-5">
                    <h3 className="text-muted">Nenhum resultado encontrado</h3>
                  </div>
                ) : (
                  ""
                )}
                {results &&
                  results.map((item) => (
                    <RealtyInLine
                      key={item.id}
                      data={item}
                      favorite={() => isPropertyInMyFavorites(item)}
                    />
                  ))}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {isMobile ? (
        <SearchTermsModal
          show={showModal}
          onHide={() => setShowModal(false)}
          reportFavorites={(favorites) => setFavorites(favorites)}
          reportProperties={(properties) => setResults(properties)}
        />
      ) : (
        ""
      )}
      <Loading loading={loading} />
      <ToastContainer className="toastr" />
    </>
  );
}
