import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table";
import Functions from "@/app/core/util/Functions";
import {
  DateTranslate,
  DateTranslateFromTimestamp,
} from "@/app/core/util/DateTranslater";
import { RealEstateAgencyController } from "@/app/core/base/controllers/RealEstateAgencyController";
import AgencyManagementModal from "./components/AgencyManagementModal";

export default function RealEstateAgenciesPanel() {
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const cols = [
    { header: "#ID", isSortable: true, name: "id" },
    { header: "Nome", isSortable: true, name: "name" },
    { header: "Telefone", isSortable: true, name: "phone" },
    { header: "Cidade", isSortable: true, name: "city" },
    { header: "Anúncios", isSortable: true, name: "ads" },
    { header: "Criado em", isSortable: true, name: "createdAt" },
    { header: "Último acesso", isSortable: true, name: "updatedAt" },
  ];

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    RealEstateAgencyController.List((res) => {
      for (let item of res) {
        item.ads = 0;
        item.createdAt = DateTranslate(item.createdDate);
        item.updatedAt = DateTranslate(item.updatedDate);
      }
      setData(res);
      setRaw(res);
    });
  }

  async function viewAction(item) {
    setSelected(item.data);
    setShowModal(true);
  }

  function closeModal() {
    setSelected({});
    setShowModal(false);
    loadData();
  }

  function handleChange(evt) {
    const inputValue = evt.target.value;
    if (inputValue === "") {
      setData(raw);
    } else {
      let newResults = [];

      for (let s in raw) {
        const item = raw[s];
        const name = item.name.toLowerCase();
        const email = item.email.toLowerCase();
        if (name.includes(inputValue) || email.includes(inputValue)) {
          newResults.push(item);
        }

        setData(newResults);
      }
    }
  }

  return (
    <>
      <div className="content">
        <div className="row">
          <div className="col-6">
            <div className="content-heading">
              <h3>Imobiliárias</h3>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <div className="input-group input-group-lg mt-3">
                <input
                  type="text"
                  onChange={(evt) => handleChange(evt)}
                  className="form-control form-control"
                  placeholder="Pesquisar..."
                />
                <div className="input-group-append">
                  <span className="input-group-text border-0 bg-body">
                    <i className="fa fa-fw fa-search"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <Table
              title={`${data && data.length} Imobiliárias`}
              loading={false}
              data={data}
              limit={100}
              columns={cols}
              refreshData={() => loadData()}
              setFilter={(filter) => Functions.ProcessFilter(filter, data)}
              viewAction={(data) => viewAction(data)}
            />
          </div>
        </div>
      </div>
      <AgencyManagementModal
        agency={selected}
        onHide={()=> closeModal()}
        show={showModal}
      />
    </>
  );
}
