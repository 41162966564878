export const CurrencyHelper = {
  Format: function (amount) {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return formatter.format(amount);
  },
};
