import RealtyCard from "@/app/core/layouts/main-components/realty/RealtyCard";
import React, { useState, useEffect } from "react";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";

export default function PopularRealty({ location }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (location?.lat && location?.lng) {
      executeQuery();
    }
  }, [location]);

  async function executeQuery() {
    const rangeInKm = 50.0;
    const userLocation = {
      lat: location.lat,
      lng: location.lng,
    };
    const realEstateTypes = [
      "Cetq8vJM2BYhK2IrLLd1", // Apartamentos,
      "BpcxCeDU6dvLnrW5hnOb", // Cobertura
      "rtiIArgs926gRS3j5z68", // Flat
    ];

    let properties = await PropertiesController.QueryPropertiesInRange(
      userLocation,
      rangeInKm,
      realEstateTypes
    );
    if (properties) {
      setData(properties);
    }
  }

  return (
    <div className="row mt-5">
      <div className="col-md-12 text-center">
        <h2 className="text-muted font-w700 border-botom">
          Apartamentos mais populares perto de você
        </h2>
        <hr
          className="border-4x border-warning"
          style={{ color: "#F36161", width: 124 }}
        />
      </div>

      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 row">
          {data &&
            data.map((item) => (
              <div key={item.id} className="col-md-4 pl-1 pr-3">
                <RealtyCard data={item} iconDescription={false} />
              </div>
            ))}
          {/* <div className="col-12 mt-3">
                        <img src={process.env.PUBLIC_URL + "/assets/media/promo.png"} style={{ width: "100%" }} />
                    </div> */}
        </div>
        <div className="col-1"></div>
      </div>
    </div>
  );
}
