export const StringHelper = {
  NormalizeString: function (str) {
    if (!str) {
      return str;
    }
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/[^\w\s]/g, "") // Remove punctuation and special characters
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .trim();
  },

  TruncateText: function (text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  },

  GenerateUniqueId: function () {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  },
};
