import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Field from "@/app/core/components/input/Field";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { AccountController } from "@/app/core/base/controllers/AccountController";
import { UserHelper } from "@/app/core/util/helpers/UserHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { AddressController } from "@/app/core/base/controllers/AddressController";
import { InviteController } from "@/app/core/base/controllers/InviteController";
import { Timestamp } from "firebase/firestore";

export default function SignIn(props) {
  const [data, setData] = useState({});
  const [realEstate, setReaEstate] = useState({});
  const [address, setAddress] = useState({});
  const [invite, setInvite] = useState({});
  const [route, setRoute] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkUrl();
  }, []);

  function checkUrl() {
    const searchParams = props.location?.search;

    if (searchParams) {
      const params = new URLSearchParams(searchParams);
      const invite = params.get("invite");

      if (invite) {
        loadInvite(invite);
      }
    }
  }

  async function loadInvite(invite) {
    InviteController.Get(invite, (res) => {
      if (res) {
        const date = new Date().getTime() * 1000;
        console.log("Rerotsdcn", res);
        if (res.expire <= date) {
          setRoute(9);
        } else {
          setInvite(res);
          setData({
            ...data,
            realEstate: res.realEstate,
            role: res.role,
            name: res.name,
            email: res.email,
            inviteId: invite,
            level: UserHelper.UserLevel.RealEstate,
          });
          setRoute(2);
        }
      }
    });
  }

  function handleChange(evt, type) {
    setData(OnDataChange(data, evt, type));
  }

  function handleRealChange(evt, type) {
    setReaEstate(OnDataChange(realEstate, evt, type));
  }

  async function registerUser(e) {
    e.preventDefault();
    let error = false,
      message = "";

    if (!data.name) {
      error = true;
      message += "Nome; ";
    }
    if (!data.email) {
      error = true;
      message += "Email; ";
    }
    if (!data.password) {
      error = true;
      message += "Senha; ";
    }
    if (!data.phone) {
      error = true;
      message += "Telefone; ";
    }

    if (error) {
      toast.error("Esta faltando preencher: " + message);
    } else {
      setLoading(true);
      await AccountController.CreateUser(
        { ...data, createdDate: Timestamp.now(), updatedDate: Timestamp.now() },
        (res) => {
          toast.success("Salvo com sucesso");
        }
      );
      setLoading(false);
    }
  }

  async function registerRealEstate(e) {
    e.preventDefault();
    let error = false,
      message = "";

    if (!data.name) {
      error = true;
      message += "Nome; ";
    }
    if (!data.email) {
      error = true;
      message += "Email; ";
    }
    if (!data.password) {
      error = true;
      message += "Senha; ";
    }
    if (!data.phone) {
      error = true;
      message += "Telefone; ";
    }

    if (!realEstate.name) {
      error = true;
      message += "Nome da imobiliária; ";
    }
    if (!realEstate.phone) {
      error = true;
      message += "Telefone; ";
    }
    if (!realEstate.taxId) {
      error = true;
      message += "CNPJ; ";
    }
    if (!realEstate.postalCode) {
      error = true;
      message += "CEP; ";
    }

    if (error) {
      toast.error("Esta faltando preencher: " + message);
    } else {
      if (!UserHelper.ValidaCNPJ(realEstate.taxId)) {
        toast.error(Message.CNPJInvalid);
      } else {
        setLoading(true);
        await AccountController.CreateRealEstate(
          data,
          {
            ...address,
            ...realEstate,
            createdDate: Timestamp.now(),
            updatedDate: Timestamp.now(),
          },
          (res) => {
            toast.success("Salvo com sucesso");
          }
        );
        setLoading(false);
      }
    }
  }

  function loadAddressByPostalCode(cep) {
    AddressController.GetAddress(cep, (res) => {
      setAddress(res);
    });
  }

  function steps() {
    switch (route) {
      case 1:
        return (
          <form
            className="js-validation-signin"
            onSubmit={(e) => registerUser(e)}
          >
            <p className="text-center text-uppercase text-muted">
              Cadastro de proprietário
            </p>
            <div className="py-3">
              <Field
                name="name"
                onChange={(evt) => handleChange(evt)}
                title={"Nome"}
                value={data && data.name}
                type={FieldTypeObj.Text}
              />
              <Field
                name="email"
                onChange={(evt) => handleChange(evt)}
                title={"Email"}
                value={data && data.email}
                type={FieldTypeObj.Text}
              />
              <Field
                name="password"
                onChange={(evt) => handleChange(evt)}
                title={"Senha"}
                value={data && data.password}
                type={FieldTypeObj.Password}
              />
              <Field
                name="phone"
                onChange={(evt) =>
                  handleChange({
                    target: {
                      name: evt.target.name,
                      value: evt.target.value.replace(/[^\d]+/g, ""),
                    },
                  })
                }
                title={"Telefone"}
                value={data && data.phone}
                type={FieldTypeObj.Mask}
                mask={"(99) 99999-99999"}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-block btn-hero-lg btn-hero-warning"
              >
                <i
                  className={
                    loading === true
                      ? "fa fa-circle-notch fa-spin"
                      : "fa fa-fw fa-sign-in-alt mr-1"
                  }
                />{" "}
                Cadastrar
              </button>
              <p className="mt-3 mb-0 text-center">
                <a
                  className="btn btn-sm btn-dual mb-3 btn-block d-lg-inline-block mb-1"
                  onClick={() => setRoute(0)}
                >
                  <i className="fa fa-arrow-left text-muted mr-1" /> Voltar
                </a>
                <a
                  className="btn btn-sm btn-light btn-block d-lg-inline-block mb-1"
                  onClick={() => (window.location = "#/auth")}
                >
                  <i className="fa fa-plus text-muted mr-1" /> Ja tem conta?
                  Login
                </a>
              </p>
            </div>
          </form>
        );
      case 2:
        return (
          <form
            className="js-validation-signin"
            onSubmit={(e) => registerUser(e)}
          >
            <p className="text-center text-uppercase text-muted">
              Cadastro de corretor
            </p>
            <div className="py-3">
              <Field
                name="name"
                onChange={(evt) => handleChange(evt)}
                title={"Nome"}
                value={data && data.name}
                type={FieldTypeObj.Text}
              />
              <Field
                name="creci"
                onChange={(evt) => handleChange(evt)}
                title={"CRECI"}
                value={data && data.creci}
                type={FieldTypeObj.Text}
              />
              <Field
                name="doc"
                onChange={(evt) =>
                  handleChange({
                    target: {
                      name: evt.target.name,
                      value: evt.target.value.replace(/[^\d]+/g, ""),
                    },
                  })
                }
                title={"CPF"}
                value={data && data.doc}
                type={FieldTypeObj.Mask}
                mask={"999.999.999-99"}
              />
              <Field
                name="email"
                onChange={(evt) => handleChange(evt)}
                title={"Email"}
                value={data && data.email}
                type={FieldTypeObj.Text}
              />
              <Field
                name="password"
                onChange={(evt) => handleChange(evt)}
                title={"Senha"}
                value={data && data.password}
                type={FieldTypeObj.Password}
              />
              <Field
                name="phone"
                onChange={(evt) =>
                  handleChange({
                    target: {
                      name: evt.target.name,
                      value: evt.target.value.replace(/[^\d]+/g, ""),
                    },
                  })
                }
                title={"Telefone"}
                value={data && data.phone}
                type={FieldTypeObj.Mask}
                mask={"(99) 99999-99999"}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-block btn-hero-lg btn-hero-warning"
              >
                <i
                  className={
                    loading === true
                      ? "fa fa-circle-notch fa-spin"
                      : "fa fa-fw fa-sign-in-alt mr-1"
                  }
                />{" "}
                Cadastrar
              </button>
              <p className="mt-3 mb-0 text-center">
                <a
                  className="btn btn-sm btn-dual mb-3 btn-block d-lg-inline-block mb-1"
                  onClick={() => setRoute(0)}
                >
                  <i className="fa fa-arrow-left text-muted mr-1" /> Voltar
                </a>
                <a
                  className="btn btn-sm btn-light btn-block d-lg-inline-block mb-1"
                  onClick={() => (window.location = "#/auth")}
                >
                  <i className="fa fa-plus text-muted mr-1" /> Ja tem conta?
                  Login
                </a>
              </p>
            </div>
          </form>
        );
      case 3:
        return (
          <form
            className="js-validation-signin"
            onSubmit={(e) => registerRealEstate(e)}
          >
            <p className="text-center text-uppercase text-muted">
              Cadastro de Imobiliária
            </p>
            <div className="py-3">
              <p className="text-muted text-center mb-0 mt-0">
                Dados da imobiliária
              </p>
              <Field
                name="name"
                onChange={(evt) => handleRealChange(evt)}
                title={"Nome da imobiliária"}
                value={realEstate && realEstate.name}
                type={FieldTypeObj.Text}
              />
              <Field
                name="phone"
                onChange={(evt) =>
                  handleRealChange({
                    target: {
                      name: evt.target.name,
                      value: evt.target.value.replace(/[^\d]+/g, ""),
                    },
                  })
                }
                title={"Telefone comercial"}
                value={realEstate && realEstate.phone}
                type={FieldTypeObj.Mask}
                mask={"(99) 9999-99999"}
              />
              <Field
                name="taxId"
                onChange={(evt) =>
                  handleRealChange({
                    target: {
                      name: evt.target.name,
                      value: evt.target.value.replace(/[^\d]+/g, ""),
                    },
                  })
                }
                title={"CNPJ"}
                value={realEstate && realEstate.taxId}
                type={FieldTypeObj.Mask}
                mask={"99.999.999/9999-99"}
              />
              <div className="row">
                <div className="col-7">
                  <Field
                    name="postalCode"
                    onChange={(evt) => {
                      handleRealChange({
                        target: {
                          name: evt.target.name,
                          value: evt.target.value.replace(/[^\d]+/g, ""),
                        },
                      });
                      if (evt.target.value.replace(/\D/g, "").length === 8) {
                        loadAddressByPostalCode(
                          evt.target.value.replace(/\D/g, "")
                        );
                      }
                    }}
                    title={"CEP"}
                    value={realEstate && realEstate.postalCode}
                    type={FieldTypeObj.Mask}
                    mask={"99999-999"}
                  />
                </div>
                <div className="col-5">
                  <a
                    className="btn btn-dual btn-block mt-4"
                    href="https://buscacepinter.correios.com.br/app/endereco/"
                    target="_blank" rel="noreferrer"
                  >
                    Não sabe seu CEP?
                  </a>
                </div>
              </div>

              <p className="text-muted text-center mb-0 mt-4">Seus dados</p>
              <Field
                name="name"
                onChange={(evt) => handleChange(evt)}
                title={"Nome"}
                value={data && data.name}
                type={FieldTypeObj.Text}
              />
              <Field
                name="doc"
                onChange={(evt) =>
                  handleChange({
                    target: {
                      name: evt.target.name,
                      value: evt.target.value.replace(/[^\d]+/g, ""),
                    },
                  })
                }
                title={"CPF"}
                value={data && data.doc}
                type={FieldTypeObj.Mask}
                mask={"999.999.999-99"}
              />
              <Field
                name="email"
                onChange={(evt) => handleChange(evt)}
                title={"Email"}
                value={data && data.email}
                type={FieldTypeObj.Text}
              />
              <Field
                name="password"
                onChange={(evt) => handleChange(evt)}
                title={"Senha"}
                value={data && data.password}
                type={FieldTypeObj.Password}
              />
              <Field
                name="phone"
                onChange={(evt) =>
                  handleChange({
                    target: {
                      name: evt.target.name,
                      value: evt.target.value.replace(/[^\d]+/g, ""),
                    },
                  })
                }
                title={"Telefone"}
                value={data && data.phone}
                type={FieldTypeObj.Mask}
                mask={"(99) 99999-99999"}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-block btn-hero-lg btn-hero-warning"
              >
                <i
                  className={
                    loading === true
                      ? "fa fa-circle-notch fa-spin"
                      : "fa fa-fw fa-sign-in-alt mr-1"
                  }
                />{" "}
                Cadastrar
              </button>
              <p className="mt-3 mb-0 text-center">
                <a
                  className="btn btn-sm btn-dual mb-3 btn-block d-lg-inline-block mb-1"
                  onClick={() => setRoute(0)}
                >
                  <i className="fa fa-arrow-left text-muted mr-1" /> Voltar
                </a>
                <a
                  className="btn btn-sm btn-light btn-block d-lg-inline-block mb-1"
                  onClick={() => (window.location = "#/auth")}
                >
                  <i className="fa fa-plus text-muted mr-1" /> Ja tem conta?
                  Login
                </a>
              </p>
            </div>
          </form>
        );
      case 9:
        return (
          <div class="px-3 py-5 text-center text-sm-left">
            <div
              class="display-1 text-warning font-w700 js-appear-enabled animated fadeIn"
              data-toggle="appear"
            >
              Oops
            </div>
            <h1
              class="h2 font-w700 text-muted mt-5 mb-3 js-appear-enabled animated fadeInUp"
              data-toggle="appear"
              data-class="animated fadeInUp"
              data-timeout="300"
            >
              Convite expirado!
            </h1>
            <h2
              class="h3 font-w400 text-muted-75 mb-5 js-appear-enabled animated fadeInUp"
              data-toggle="appear"
              data-class="animated fadeInUp"
              data-timeout="450"
            >
              Entre em contato com a pessoa que o convidou para emitir um novo
              convite
            </h2>
            <div
              class="js-appear-enabled animated fadeInUp"
              data-toggle="appear"
              data-class="animated fadeInUp"
              data-timeout="600"
            >
              <a class="btn btn-hero-light" href="/">
                <i class="fa fa-arrow-left mr-1"></i> Voltar para o site
              </a>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  function processRoute(step) {
    setRoute(step);
    let role = 0;
    if (step === 1) {
      role = UserHelper.UserLevel.User;
    } else if (step === 2) {
      role = UserHelper.UserLevel.Realtor;
    } else if (step === 3) {
      role = UserHelper.UserLevel.RealEstate;
    }
    setData({ ...data, level: role });
  }

  return (
    <div id="page-container">
      <main id="main-container">
        <div>
          <div className="row no-gutters bg-primary-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a
                    className="link-fx font-w700 font-size-h1"
                    href="javascript:void(0)"
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/media/logo.png"}
                      style={{ maxWidth: "150px" }}
                    />
                  </a>
                </div>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-8">
                    {route === 0 ? (
                      <div>
                        <p className="text-center text-uppercase text-muted">
                          Deseja se cadastrar como?
                        </p>
                        <button
                          onClick={() => processRoute(1)}
                          className="btn btn-warning btn-block"
                        >
                          Usuário / Proprietário
                        </button>
                        <button
                          onClick={() => processRoute(2)}
                          className="btn btn-dark btn-block"
                        >
                          Corretor
                        </button>
                        <button
                          onClick={() => processRoute(3)}
                          className="btn btn-secondary btn-block"
                        >
                          Imobiliária
                        </button>

                        <button
                          type="submit"
                          className="btn btn-block mt-5 btn-dual"
                        >
                          <i
                            className={
                              loading === true
                                ? "fa fa-circle-notch fa-spin"
                                : "fa fa-fw fa-sign-in-alt mr-1"
                            }
                          />{" "}
                          Já tem conta? Login
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {route !== 0 && steps()}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center bg-image"
              style={{
                backgroundImage: "url('assets/media/photos/bg-intro.jpg')",
              }}
            >
              <div className="p-3">
                <p className="display-4 font-w700 text-white mb-3">
                  Bem Vindo!
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Habitaki &copy;{" "}
                  <span className="js-year-copy">
                    {new Date().getFullYear()}
                    -Desenvolvido pela{" "}
                    <a
                      className="text-white"
                      href="https://navigate.one"
                      target="_blank" rel="noreferrer"
                    >
                      Navigate
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer className="toastr" />
    </div>
  );
}
