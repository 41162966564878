import { toast } from "react-toastify";
import { Firebase } from "@/app/core/base/BaseFirebase";
import { ErrorHelper } from "../../util/helpers/ErrorHelper";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  getDoc,
  query,
  where,
  deleteDoc,
  orderBy,
  startAt,
  endAt,
  Timestamp,
  getCountFromServer,
  count,
  writeBatch,
  increment,
  limit,
} from "firebase/firestore";
import Storage from "../../util/Storage";
import * as geofire from "geofire-common";
import { FireHelper } from "../../util/helpers/FireHelper";
import algoliasearch from "algoliasearch/lite";
import { DateHelper } from "../../util/helpers/DateHelper";
import { RealtyHelper } from "../../util/helpers/RealtyHelper";

export const PropertiesController = {
  db: getFirestore(Firebase),

  ToFirestoreProperty: function (data) {
    let updatedData = {
      id: data.id,
      description: data.description,
      title: data.title,
      type: data.type,
      userId: data.userId,
      value: data.value,
      // Address components
      postalCode: data.postalCode,
      street: data.street,
      number: data.number,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state,
      formattedAddress: data.formattedAddress,
      // Continuação
      asset: data.asset,
      automaticRefusal: data.automaticRefusal,
      bathrooms: data.bathrooms,
      // favoritesCount: data.favoritesCount, // Controlado pelo FavoriteController
      // visitsCount: data.visitsCount // Função RecordVisit somente
      // lastVisitDate: data.lastVisitDate // Função RecordVisit somente
      meters: data.meters,
      metersTotal: data.metersTotal,
      minimalValue: data.minimalValue,
      parkingLots: data.parkingLots,
      plan: data.plan,
      purpose: data.purpose,
      realEstate: data.realEstate,
      realEstateType: data.realEstateType,
      status: data.status,
      costs: Array.isArray(data.costs)
        ? data.costs.map((cost) => ({
            name: cost.name,
            value: cost.value,
          }))
        : [],
      images: Array.isArray(data.images)
        ? data.images.map((img) => ({
            url: img.url,
            name: img.name,
            position: img.position,
            highlight: img.highlight,
            contentType: img.contentType,
            fullPath: img.fullPath,
            bucket: img.bucket,
          }))
        : [],
      imagesCount: Array.isArray(data.images) ? data.images.length : 0,
      stories: Array.isArray(data.stories)
        ? data.stories.map((story) => ({
            url: story.url,
            name: story.name,
            position: story.position,
            contentType: story.contentType,
            fullPath: story.fullPath,
            bucket: story.bucket,
          }))
        : [],
      storiesCount: Array.isArray(data.stories) ? data.stories.length : 0,
      amenities: Array.isArray(data.amenities)
        ? data.amenities.map((amenity) => ({
            id: amenity.id,
            name: amenity.name,
            count: amenity.count,
            optionals: Array.isArray(amenity.optionals)
              ? amenity.optionals.map((optional) => ({
                  id: optional.id,
                  name: optional.name,
                  active: optional.active,
                  message: optional.message,
                  describe: optional.describe,
                  highlight: optional.highlight,
                  description: optional.description,
                  icon: optional.icon,
                }))
              : [],
            createdDate: amenity.createdDate ?? Timestamp.now(),
            updatedDate: Timestamp.now(),
          }))
        : [],
      amenitiesIds: Array.isArray(data.amenities)
        ? data.amenities.map((amenity) => amenity.id)
        : [],
      amenitiesOptionalsIds:
        data.amenities && Array.isArray(data.amenities)
          ? data.amenities
              .filter((amenity) => Array.isArray(amenity.optionals))
              .flatMap((amenity) => amenity.optionals)
              .filter((optional) => optional.active === true)
              .map((optional) => optional.id)
          : [],
      coordinates: data.coordinates
        ? {
            geohash: data.coordinates.geohash,
            lat: data.coordinates.lat,
            lng: data.coordinates.lng,
          }
        : null,
      createdDate: data.createdDate ?? Timestamp.now(),
      updatedDate: Timestamp.now(),
    };
    return FireHelper.ToFirestoreDoc(updatedData);
  },

  Insert: async function (data) {
    try {
      const coll = collection(this.db, "properties");
      const result = await addDoc(coll, this.ToFirestoreProperty(data));
      return result.id;
    } catch (e) {
      console.log("PropertiesController insert error", e);
      toast.error(ErrorHelper.HandleMessage(e));
      return null;
    }
  },

  Update: async function (data) {
    try {
      const propertyRef = doc(this.db, "properties", data.id);
      await updateDoc(propertyRef, this.ToFirestoreProperty(data));
      return propertyRef.id;
    } catch (e) {
      console.log("PropertiesController update error", e);
      toast.error(ErrorHelper.HandleMessage(e));
      return null;
    }
  },

  Get: async function (id) {
    const docSnap = await getDoc(doc(this.db, "properties", id));
    if (docSnap.exists()) {
      let data = docSnap.data();
      data.id = docSnap.id;
      return data;
    } else {
      return null;
    }
  },

  List: async function (res) {
    const querySnapshots = await getDocs(
      collection(this.db, "properties"),
      limit(1000)
    );
    const results = querySnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
  },

  ListByUser: async function (res) {
    const userData = Storage.getUserData();
    const queryProperties = query(
      collection(this.db, "properties"),
      where("userId", "==", userData.uid)
    );
    const querySnapshots = await getDocs(queryProperties);
    const results = querySnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
  },

  ListUserProperties: async function (userId, res) {
    const queryProperties = query(
      collection(this.db, "properties"),
      where("userId", "==", userId)
    );
    const querySnapshots = await getDocs(queryProperties);
    const results = querySnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
  },

  ListByRealEstate: async function (res) {
    const userData = Storage.getUserData();
    const queryProperties = query(
      collection(this.db, "properties"),
      where("realEstate", "==", userData.realEstate)
    );
    const querySnapshots = await getDocs(queryProperties);
    const results = querySnapshots.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    res(results);
  },

  ListRealEstateByAngency: async function (realEstate, res) {
    if (realEstate) {
      const queryProperties = query(
        collection(this.db, "properties"),
        where("realEstate", "==", realEstate)
      );
      const querySnapshots = await getDocs(queryProperties);
      const results = querySnapshots.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      res(results);
    }
  },

  ListByIds: async function (propertiesIds) {
    let propertiesCollection = collection(this.db, "properties");
    let querySnapshots = await getDocs(
      // query(
      //   propertiesCollection,
      //   propertiesIds.map((pId) => where("id", "==", pId))
      // )
      query(propertiesCollection, where("id", "in", propertiesIds))
    );
    return querySnapshots.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  },

  QueryProperties: async function ({
    queryString,
    types,
    withStories,
    minPrice,
    maxPrice,
    rooms,
    minArea,
    maxArea,
    parkingLots,
    bathrooms,
    amenitiesOptionalsIds,
    realEstateTypesIds,
    orderBy,
    hitsPerPage,
  }) {
    let filters = [];

    if (Array.isArray(types))
      filters.push(`(${types.map((value) => `type = ${value}`).join(" OR ")})`);

    if (withStories) filters.push(`storiesCount > 0`);
    if (minPrice) filters.push(`value >= ${minPrice}`);
    if (maxPrice) filters.push(`value <= ${maxPrice}`);
    if (rooms) filters.push(`rooms:${rooms}`);
    if (minArea) filters.push(`metersTotal >= ${minArea}`);
    if (maxArea) filters.push(`metersTotal <= ${maxArea}`);
    if (parkingLots) filters.push(`parkingLots >= ${parkingLots}`);
    if (bathrooms) filters.push(`bathrooms >= ${bathrooms}`);

    if (
      Array.isArray(amenitiesOptionalsIds) &&
      amenitiesOptionalsIds.length > 0
    ) {
      filters.push(
        `(${amenitiesOptionalsIds
          .map((value) => `amenitiesOptionalsIds:${value}`)
          .join(" AND ")})`
      );
    }
    if (Array.isArray(realEstateTypesIds) && realEstateTypesIds.length > 0) {
      filters.push(
        `(${realEstateTypesIds
          .map((value) => `realEstateType:${value}`)
          .join(" OR ")})`
      );
    }

    const searchClient = algoliasearch(
      "Z1II48E5CS",
      "070f1e8ed35532ebdf13422104c63f2d"
    );

    let index;
    if (orderBy === "asc") {
      index = searchClient.initIndex("properties_price_asc");
    } else if (orderBy === "desc") {
      index = searchClient.initIndex("properties_price_desc");
    } else {
      index = searchClient.initIndex("properties");
    }

    // Join all filters into a single filter string
    const filterString = filters.length > 0 ? filters.join(" AND ") : "";

    // console.log("FilterString: ", filterString);

    const { hits } = await index.search(queryString || "", {
      filters: filterString,
      hitsPerPage: hitsPerPage || 100,
    });

    return hits.map((doc) => ({ ...doc, id: doc.objectID }));
  },

  QueryPropertiesInRange: async function (
    userLocation,
    rangeInKm,
    realEstateTypes
  ) {
    // Geo queries
    // https://firebase.google.com/docs/firestore/solutions/geoqueries#web_1

    const center = [userLocation.lat, userLocation.lng];
    const radiusInM = rangeInKm * 1000; // Convert kilometers to meters

    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    const bounds = geofire.geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
      const q = query(
        collection(this.db, "properties"),
        where("realEstateType", "in", realEstateTypes),
        orderBy("coordinates.geohash"),
        orderBy("favoritesCount", "desc"),
        startAt(b[0]),
        endAt(b[1])
      );

      promises.push(getDocs(q));
    }

    // Collect all the query results together into a single list
    const snapshots = await Promise.all(promises);
    const matchingDocs = [];

    for (const snap of snapshots) {
      for (const doc of snap.docs) {
        const lat = doc.get("coordinates.lat");
        const lng = doc.get("coordinates.lng");

        // We have to filter out a few false positives due to GeoHash
        // accuracy, but most will match
        const distanceInKm = geofire.distanceBetween([lat, lng], center);
        const distanceInM = distanceInKm * 1000;
        if (distanceInM <= radiusInM) {
          matchingDocs.push(doc);
        }
      }
    }

    return matchingDocs.map((doc) => ({ ...doc.data(), id: doc.id }));
  },

  Delete: async function (id, res) {
    await deleteDoc(doc(this.db, "properties", id));
    res();
  },

  RecordVisit: async function (propertyId) {
    try {
      const userData = Storage.getUserData();
      if (!userData) {
        return;
      }

      const visitsCollectionRef = collection(this.db, "visits");
      const visitDocRef = doc(visitsCollectionRef);
      const propertyDocRef = doc(this.db, "properties", propertyId);

      const batch = writeBatch(this.db);

      batch.set(visitDocRef, {
        propertyId: propertyId,
        userId: userData.uid,
        createdDate: Timestamp.now(),
      });

      batch.update(propertyDocRef, {
        visitsCount: increment(1),
        lastVisitDate: Timestamp.now(),
      });

      await batch.commit();

      console.log("Visit recorded successfully with batch");
    } catch (error) {
      console.error("Error recording visit with batch: ", error);
    }
  },

  GetOptionalsPropertiesCount: async function () {
    // Get all categories documents
    const categoriesColl = await getDocs(collection(this.db, "categories"));

    // Extract all optional IDs from categories
    const optionalsIds = categoriesColl.docs
      .filter((doc) => doc.data().optionals)
      .flatMap((doc) => doc.data().optionals.map((optional) => optional.id));

    // Create an array of promises for counting properties containing each optional ID
    const promises = optionalsIds.map((optionalId) =>
      getCountFromServer(
        query(
          collection(this.db, "properties"),
          where("amenitiesOptionalsIds", "array-contains", optionalId)
        )
      )
    );

    // Await all promises and map the results to counts
    const countResults = (await Promise.all(promises)).map(
      (querySnapshot) => querySnapshot.data().count
    );

    // Create map of entries with optionalId and count
    const entriesMap = new Map(
      optionalsIds.map((id, index) => [id, countResults[index]])
    );

    return entriesMap;
  },

  // Relatórios do administrador
  GetTotalCount: async function () {
    const coll = collection(this.db, "properties");
    const snapshot = await getCountFromServer(coll);
    return snapshot.data().count;
  },

  GetAdminReportsByDate: async function (startDate, endDate) {
    const propertiesQuery = query(
      collection(this.db, "properties"),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        aluguel: 0,
        venda: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(propertiesQuery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        const type = doc.data().type;
        switch (type) {
          case RealtyHelper.AdType.Buy:
            currentValue.venda += 1;
            break;
          case RealtyHelper.AdType.Rent:
            currentValue.aluguel += 1;
            break;
          case RealtyHelper.AdType.Both:
          default:
            currentValue.aluguel += 1;
            currentValue.venda += 1;
            break;
        }
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },

  GetAdminTotalStatusesByDate: async function (startDate, endDate) {
    const statusObj = RealtyHelper.Status;
    const countQueries = Object.keys(statusObj)
      .map((key) => statusObj[key])
      .map((status) =>
        getCountFromServer(
          query(
            collection(this.db, "properties"),
            where("updatedDate", ">=", Timestamp.fromDate(startDate)),
            where("updatedDate", "<=", Timestamp.fromDate(endDate)),
            where("status", "==", status)
          )
        )
      );

    const countResults = (await Promise.all(countQueries)).map(
      (snap) => snap.data().count
    );

    // Create map of entries with key and count
    const report = new Map(
      Object.keys(statusObj).map((key, index) => [key, countResults[index]])
    );

    return report;
  },

  GetAdminStatusesReportsByDate: async function (startDate, endDate) {
    const propertiesQuery = query(
      collection(this.db, "properties"),
      where("updatedDate", ">=", Timestamp.fromDate(startDate)),
      where("updatedDate", "<=", Timestamp.fromDate(endDate))
    );

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        pendente: 0,
        aberto: 0,
        concluidos: 0,
        bloqueados: 0,
        cancelados: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    // Process the documents to group by month
    (await getDocs(propertiesQuery)).forEach((doc) => {
      const updatedDate = doc.data().updatedDate.toDate();
      const year = updatedDate.getFullYear();
      const month = updatedDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        const status = doc.data().status;
        switch (status) {
          case RealtyHelper.Status.FillingIn:
            currentValue.pendente += 1;
            break;
          case RealtyHelper.Status.Ongoing:
            currentValue.aberto += 1;
            break;
          case RealtyHelper.Status.Done:
            currentValue.concluidos += 1;
            break;
          case RealtyHelper.Status.Canceled:
            currentValue.cancelados += 1;
            break;
          case RealtyHelper.Status.Blocked:
            currentValue.bloqueados += 1;
            break;
          default:
            console.log("Warning: Unknown property status", status);
            break;
        }
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },

  // Relatórios da imobiliária
  GetRealEstatePropertiesCount: async function (realEstateId) {
    const userData = Storage.getUserData();
    if (!userData || !realEstateId) {
      return 0;
    }
    const coll = query(
      collection(this.db, "properties"),
      where("realEstate", "==", realEstateId)
    );
    const snapshot = await getCountFromServer(coll);
    return snapshot.data().count;
  },

  GetRealEstatePropertiesVisitsCount: async function (realEstateId) {
    const userData = Storage.getUserData();
    if (!userData || !realEstateId) {
      return 0;
    }
    const coll = query(
      collection(this.db, "properties"),
      where("realEstate", "==", realEstateId)
    );
    const snapshot = await getDocs(coll);
    const sum = snapshot.docs.reduce(
      (acc, doc) => acc + (doc.data().visitsCount ?? 0),
      0
    );
    return sum;
  },

  GetRealEstatePropertiesVisitsReport: async function (
    realEstateId,
    startDate,
    endDate
  ) {
    console.log("RealEstate: ", realEstateId);
    const userData = Storage.getUserData();
    if (!userData || !realEstateId) {
      return [];
    }

    // Initialize a dictionary to hold counts for each month-year in the range
    const countsByMonth = {};

    // Generate all months between startDate and endDate
    let currentMonth = DateHelper.GetFirstDayOfMonthByDate(startDate);
    while (currentMonth <= endDate) {
      const year = currentMonth.getFullYear();
      const month = currentMonth.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;
      countsByMonth[monthYear] = {
        year: year,
        month: month,
        visitsCount: 0,
      };
      currentMonth = DateHelper.AddMonths(currentMonth, 1);
    }

    const propertiesSnap = await getDocs(
      query(
        collection(this.db, "properties"),
        where("realEstate", "==", realEstateId),
        where("lastVisitDate", ">=", Timestamp.fromDate(startDate)),
        where("lastVisitDate", "<=", Timestamp.fromDate(endDate))
      )
    );

    if (propertiesSnap.empty) {
      return [];
    }

    const visitsquery = query(
      collection(this.db, "visits"),
      where(
        "propertyId",
        "in",
        propertiesSnap.docs.map((doc) => doc.id)
      ),
      where("createdDate", ">=", Timestamp.fromDate(startDate)),
      where("createdDate", "<=", Timestamp.fromDate(endDate))
    );

    // Process the documents to group by month
    (await getDocs(visitsquery)).forEach((doc) => {
      const createdDate = doc.data().createdDate.toDate();
      const year = createdDate.getFullYear();
      const month = createdDate.getMonth();
      const monthYear = `${year}-${month.toString().padStart(2, "0")}`;

      // Increment count for the corresponding month
      const currentValue = countsByMonth[monthYear];
      if (currentValue !== undefined) {
        currentValue.visitsCount += 1;
      }
    });

    // Convert countsByMonth to an array of objects
    const result = Object.keys(countsByMonth).map((key) => ({
      ...countsByMonth[key],
    }));

    return result;
  },
};
