import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import BaseService from "@/app/core/base/BaseService";
import { cols } from "./ColumnHelper";

export default function SearchComponent({ location }) {
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setTableLoading(true);
    BaseService(
      {
        method: "GET",
        url: "http://demo5182122.mockable.io/hasa-doc"
      },
      res => {
        setTableLoading(false);
        setData(res);
      },
      err => {
        setTableLoading(false);
        console.log(err);
      }
    );
  }, [refreshData]);

  function editAction(data) {
    console.log(data);
  }

  return (
    <div className="content">
      <div className="row">
        <Table
          title="Em andamento"
          loading={tableLoading || false}
          data={data}
          columns={cols}
          refreshData={() => setRefreshData(!refreshData)}
          viewAction
        />
      </div>
    </div>
  );
}
