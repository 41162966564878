import {
    Timestamp,
    FieldValue,
    FieldPath,
} from "firebase/firestore";

export const FireHelper = {
    /**
     * Converts Firestore-specific data types in an object to native JavaScript types.
     * 
     * @param {any} obj - The object or value to convert. Can be a Date, FieldValue, Timestamp, or any other type.
     * @returns {any} - The converted object with Firestore-specific types replaced by native types.
     */
    FromFirestoreDoc: function (obj) {
        if (obj instanceof Date) return obj;
        if (obj instanceof FieldValue) return obj;
        if (obj instanceof FieldPath) return obj;
        if (obj instanceof Timestamp) return obj.toDate();
        if (typeof obj !== 'object' || obj === null) return obj;

        return Object.keys(obj).reduce((acc, key) => {
            const value = this.ToFirestoreDoc(obj[key]);
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, Array.isArray(obj) ? [] : {});
    },

    /**
     * Converts native JavaScript data types in an object to Firestore-specific types.
     * 
     * @param {any} obj - The object or value to convert. Can be a Date, FieldValue, or any other type.
     * @returns {any} - The converted object with native types replaced by Firestore-specific types.
     */
    ToFirestoreDoc: function (obj) {
        if (obj instanceof Date) return Timestamp.fromDate(obj);
        if (obj instanceof FieldValue) return obj;
        if (obj instanceof FieldPath) return obj;
        if (obj instanceof Timestamp) return obj;
        if (typeof obj !== 'object' || obj === null) return obj;

        return Object.keys(obj).reduce((acc, key) => {
            const value = this.ToFirestoreDoc(obj[key]);
            if (value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, Array.isArray(obj) ? [] : {});
    },
}