import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import MainComponent from "./main";
import Layout from "@/app/core/layouts/main";
import Storage from "@/app/core/util/Storage";

const ProjectShell = (props) =>
  Storage.isAuthenticated() ? (
    <Layout footer={false} {...props}>
      {renderRoutes(props.route.routes, props)}
    </Layout>
  ) : (
    <Redirect to="/" />
  );

const Redirecter = () => <Redirect to="/error/404" />;

const FavoritosRouter = {
  path: "/favoritos",
  component: ProjectShell,
  routes: [
    {
      path: "/favoritos",
      exact: true,
      component: MainComponent,
    },
    {
      path: "*",
      component: Redirecter,
    },
  ],
};

export default FavoritosRouter;
