import Storage from "@/app/core/util/Storage";
import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loading from "@/app/core/layouts/main/components/Loading";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { DataHelper, OnDataChange } from "@/app/core/util/helpers/DataHelper";
import InputMask from "react-input-mask";
import { AddressController } from "@/app/core/base/controllers/AddressController";
import Field from "@/app/core/components/input/Field";
import { RealEstateTypeController } from "@/app/core/base/controllers/RealEstateTypeController";
import { PlansController } from "@/app/core/base/controllers/PlanController";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { FileController } from "@/app/core/base/controllers/FileController";
import GetBaseUrl from "@/app/core/base/BaseAddress";
import UploadButton from "@/app/core/layouts/main-components/files/UploadButton";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CategoriesController } from "@/app/core/base/controllers/CategoriesController";
import * as geofire from "geofire-common";
import { CurrencyHelper } from "@/app/core/util/helpers/CurrencyHelper";
import { StringHelper } from "@/app/core/util/helpers/StringHelper";

export default function AnunciarImovelComponent(props) {
  const date = new Date();
  const userData = Storage.getUserData();

  const [loading, setLoading] = useState(false);
  const [realEstateTypes, setRealEstateTypes] = useState([]);
  const [realEstateTypeOptions, setRealEstateTypeOptions] = useState([]);
  const [plans, setPlans] = useState([]);
  const [cost, setCost] = useState({});
  const [step, setStep] = useState(1);
  const [amenities, setAmenities] = useState([]);
  const [change, setChange] = useState(date.getTime());
  const [data, setData] = useState({
    parkingLots: 0,
    bathrooms: 0,
    type: RealtyHelper.AdType.Buy,
    purpose: RealtyHelper.Purpose.Residential,
    automaticRefusal: false,
    status: RealtyHelper.Status.FillingIn,
    costs: [],
    images: [],
    stories: [],
    amenities: [],
    userId: userData.uid,
    ...DataHelper.BaseModal,
  });
  // const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    loadBaseInfo();
    loadAmenities();
  }, []);

  useEffect(() => {
    if (amenities && data.amenities) {
      updateAmenitiesState(amenities, data.amenities);
    }

    if (realEstateTypes.length > 0) {
      if (data?.purpose) {
        handleRealEstateTypePurpose(data.purpose);
      } else {
        handleRealEstateTypePurpose(RealtyHelper.Purpose.Residential);
      }
    }
  }, [data, realEstateTypes]);

  function loadBaseInfo() {
    PlansController.List((res) => setPlans(res));
    RealEstateTypeController.List((res) => {
      setRealEstateTypes(res);
      loadProperty();
    });
  }

  function loadAmenities() {
    CategoriesController.List((categories) => {
      setAmenities(categories);
      updateAmenitiesState(categories, data.amenities);
    });
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  async function loadProperty() {
    const searchParams = new URLSearchParams(props.location?.search);
    const propertyId = searchParams.get("propertyId");

    if (propertyId) {
      await getProperty(propertyId);
    }
  }

  async function getProperty(propertyId) {
    if (!propertyId) return;

    const property = await PropertiesController.Get(propertyId);

    if (property) {
      setData(property);
    }
  }

  function updateAmenitiesState(amenities, propertyAmenities) {
    if (!Array.isArray(amenities)) {
      throw new Error("amenities must be an array");
    }
    if (!Array.isArray(propertyAmenities)) {
      throw new Error("propertyAmenities must be an array");
    }
    if (amenities.length === 0) {
      return;
    }

    // Create a lookup map for property amenities by ID
    const propertyAmenitiesMap = new Map(
      propertyAmenities.map((amenity) => [amenity.id, amenity])
    );

    // Map over the original amenities and update based on property amenities
    const updatedAmenities = amenities.map((originalAmenity) => {
      const propertyAmenity = propertyAmenitiesMap.get(originalAmenity.id);

      if (propertyAmenity) {
        // Map over the original optionals and update the active state
        const updatedOptionals = originalAmenity.optionals.map(
          (originalOptional) => {
            const propertyOptional = propertyAmenity.optionals.find(
              (opt) => opt.id === originalOptional.id
            );

            return {
              ...originalOptional,
              active:
                propertyOptional?.active !== undefined
                  ? propertyOptional.active
                  : originalOptional.active,
              description: propertyOptional?.description,
            };
          }
        );

        return {
          ...originalAmenity,
          optionals: updatedOptionals,
        };
      }

      // Return the original amenity if there's no match in propertyAmenities
      return originalAmenity;
    });

    setAmenities(updatedAmenities);
  }

  function handleRealEstateTypePurpose(realEstatePurpose) {
    setRealEstateTypeOptions([
      ...realEstateTypes.filter((x) => x.purpose === realEstatePurpose),
    ]);
  }

  function handleChange(evt, type) {
    if (evt.target.name === "purpose") {
      setData({ ...OnDataChange(data, evt, type), realEstateType: null });
    } else {
      setData({ ...OnDataChange(data, evt, type) });
    }
  }

  function loadAddressByPostalCode(cep) {
    AddressController.GetAddress(cep, (res) => {
      setData({ ...data, ...res });
    });
  }

  async function goSecond() {
    // Helper function to validate data fields
    function validateData(data) {
      const requiredFields = [
        { key: "realEstateType", label: "Tipo de imóvel" },
        { key: "postalCode", label: "CEP" },
        { key: "city", label: "Cidade" },
        { key: "state", label: "Estado" },
        { key: "street", label: "Rua" },
        { key: "number", label: "Número" },
        { key: "meters", label: "Área útil (m²)" },
        { key: "description", label: "Descrição" },
        { key: "value", label: "Valor" },
      ];

      const missingFields = requiredFields
        .filter(
          (field) =>
            data[field.key] === undefined ||
            data[field.key] === "" ||
            data[field.key] === 0
        )
        .map((field) => field.label);

      return missingFields;
    }

    // Validate data
    const missingFields = validateData(data);

    if (missingFields.length > 0) {
      toast.error(
        `Os seguintes campos obrigatórios não foram preenchidos: ${missingFields.join(
          "; "
        )};`
      );
      return;
    }

    // Prepare newData object
    const newData = {
      ...data,
      stories: data.stories || [],
      images: data.images || [],
      realEstate: userData.realEstate,
      userId: userData.uid,
    };

    // Get geocode data
    let addressResp;
    try {
      addressResp = await AddressController.GetGeocodeData({
        postalCode: data.postalCode,
        city: data.city,
        state: data.state,
        street: data.street,
        number: data.number,
        neighborhood: data.neighborhood,
      });
    } catch (error) {
      toast.error("Erro ao obter dados de geocodificação.");
      return;
    }

    if (addressResp) {
      newData.formattedAddress = addressResp.formattedAddress;
      newData.coordinates = {
        geohash: geofire.geohashForLocation([
          addressResp.location.lat,
          addressResp.location.lng,
        ]),
        lat: addressResp.location.lat,
        lng: addressResp.location.lng,
      };
    }

    // Save data
    try {
      if (!newData.id) {
        const propertyId = await PropertiesController.Insert(newData);
        window.location = `${GetBaseUrl()}#/anunciar-imovel?propertyId=${propertyId}`;
        setData({ ...newData, id: propertyId });
      } else {
        const propertyId = await PropertiesController.Update(newData);
        window.location = `${GetBaseUrl()}#/anunciar-imovel?propertyId=${propertyId}`;
        setData({ ...newData, id: propertyId });
      }
      setStep(step + 1);
    } catch (error) {
      toast.error("Erro ao salvar propriedades.");
    }
  }

  async function selectPlan(plan) {
    const updatedData = {
      ...data,
      plan: plan.id,
      stories: data.stories || [], // Ensure stories is an array, default if undefined
    };

    setData(updatedData);
    setStep(step + 1);

    await PropertiesController.Update(updatedData);
  }

  async function removePicture(item, index) {
    if (window.confirm(Message.DeleteConfirm)) {
      const updatedData = {
        ...data,
        images: data.images.filter((_, imgIndex) => imgIndex !== index),
      };

      setData(updatedData);
      notifyChange();

      await PropertiesController.Update(updatedData);
      console.log("Updated images:", updatedData.images);
    }
  }

  async function promoPicture(iten, index) {
    let newData = data;
    newData.images[index].highlight = !newData.images[index].highlight;
    setData(newData);
    await PropertiesController.Update(newData);
    notifyChange();
  }

  async function uploadImage(files) {
    setLoading(true);

    try {
      const uploadedImages = await Promise.all(
        files.map(async (file, index) => {
          console.log("Uploading file:", file.name);

          const pathRef = `imoveis/${data.id}/${file.name}`;
          const metadata = await FileController.UploadFile(pathRef, file);

          if (metadata) {
            return {
              url: metadata.url,
              name: metadata.name,
              contentType: metadata.contentType,
              fullPath: metadata.fullPath,
              bucket: metadata.bucket,
              position: data.images.length + index + 1,
              highlight: index === 0, // Highlight the first uploaded image
            };
          }

          return null;
        })
      );

      // Filter out any null values in case of errors
      const validUploadedImages = uploadedImages.filter(
        (image) => image && image !== null
      );

      // Update state with new images
      const updatedData = {
        ...data,
        images: [...(data.images || []), ...validUploadedImages],
      };

      setData(updatedData);
      notifyChange();

      // Update the backend with the new image list
      await PropertiesController.Update(updatedData);
    } catch (error) {
      // General error handling
      toast.error("Erro ao atualizar imagens. Tente novamente.");
      console.error("Error uploading images:", error);
    } finally {
      setLoading(false);
    }
  }

  async function uploadStories(files) {
    setLoading(true);

    try {
      const uploadedStories = await Promise.all(
        files.map(async (file, index) => {
          console.log("Uploading file:", file.name);

          const pathRef = `imoveis/${data.id}/${file.name}`;
          const metadata = await FileController.UploadFile(pathRef, file);

          if (metadata) {
            return {
              url: metadata.url,
              name: metadata.name,
              contentType: metadata.contentType,
              fullPath: metadata.fullPath,
              bucket: metadata.bucket,
              position: data.stories.length + index + 1,
            };
          }

          return null;
        })
      );

      // Filter out any null values in case of errors
      const validUploadedStories = uploadedStories.filter(
        (story) => story && story !== null
      );

      // Update state with new stories
      const updatedData = {
        ...data,
        stories: [...(data.stories || []), ...validUploadedStories],
      };

      setData(updatedData);
      notifyChange();

      // Update the backend with the new story list
      await PropertiesController.Update(updatedData);
    } catch (error) {
      // General error handling
      toast.error("Erro ao atualizar stories. Tente novamente.");
      console.error("Error uploading stories:", error);
    } finally {
      setLoading(false);
    }
  }

  function selectAmenity(evt, amenityIndex, optionalIndex) {
    const { checked } = evt.target;

    // Create a deep copy of the amenities array to avoid direct mutation
    const updatedAmenities = amenities.map((amenity, i) => {
      if (i === amenityIndex) {
        return {
          ...amenity,
          optionals: amenity.optionals.map((optional, j) => {
            if (j === optionalIndex) {
              return { ...optional, active: checked };
            }
            return optional;
          }),
        };
      }
      return amenity;
    });

    console.log(
      "Updated optional: ",
      updatedAmenities[amenityIndex].optionals[optionalIndex]
    );

    setAmenities(updatedAmenities);
    notifyChange();
  }

  function handleAmenityOptionalChange(evt, type, amenityIndex, optionalIndex) {
    const updatedAmenities = amenities.map((amenity, i) => {
      if (i === amenityIndex) {
        return {
          ...amenity,
          optionals: amenity.optionals.map((optional, j) => {
            if (j === optionalIndex) {
              return OnDataChange(optional, evt, type); // Adjusted based on the assumption of `OnDataChange` signature
            }
            return optional;
          }),
        };
      }
      return amenity;
    });

    setAmenities(updatedAmenities);
    notifyChange();
  }

  function saveCost(e) {
    e.preventDefault();
    if (!cost.name || !cost.value) {
      toast.error(Message.PendingData);
    } else {
      const newData = { ...data, costs: [...data.costs, cost] };
      setData(newData);
      setCost({
        name: "",
        value: 0,
      });
      notifyChange();
      PropertiesController.Update(newData);
    }
  }

  function handleCostChange(evt, type) {
    setCost(OnDataChange(cost, evt, type));
  }

  async function endRegistration() {
    const ERROR_MESSAGES = {
      MINIMUM_VALUE: "Informe o Valor mínimo aceito.",
      ASSET: "Informe se aceita algum ativo na troca.",
    };

    // Validate the automatic refusal data
    if (data.automaticRefusal) {
      let errors = [];

      if (data.minimalValue === undefined) {
        errors.push(ERROR_MESSAGES.MINIMUM_VALUE);
      }
      if (data.asset === undefined) {
        errors.push(ERROR_MESSAGES.ASSET);
      }

      if (errors.length > 0) {
        toast.error(errors.join(" "));
        return;
      }
    }

    // Prepare new data and update
    const newData = {
      ...data,
      amenities,
      status: RealtyHelper.Status.Ongoing,
    };

    try {
      await PropertiesController.Update(newData);
      setData(newData);
      setStep((prevStep) => prevStep + 1);
    } catch (err) {
      console.error("Failed to update property:", err);
      toast.error("Failed to update property. Please try again later.");
    }
  }

  function endPictureStep() {
    if (data.images.length < 5) {
      toast.error("Envie ao menos 5 fotos do imóvel para continuar");
    } else {
      setStep(step + 1);
    }
  }

  function currentStep() {
    switch (step) {
      case 1:
        return (
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-8 col-xl-8 d-flex align-items-center p-2 px-sm-0">
              <div className="block block-bordered block-content mt-4">
                <h3>Vamos cadastrar o seu imóvel</h3>
                <p>
                  O cadastro é rápido e fácil, se não tiver todas as informçaões
                  poderá editar depois
                </p>

                <h4>O que você deseja fazer?</h4>
                <div className="form-group row gutters-tiny items-push">
                  <div className="col-6">
                    <div className="custom-control custom-block custom-control-primary">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="db-travel-house-type-private"
                        name="db-travel-house-type"
                        checked={data?.type === RealtyHelper.AdType.Buy}
                        onClick={() =>
                          handleChange(
                            {
                              target: {
                                name: "type",
                                value: RealtyHelper.AdType.Buy,
                              },
                            },
                            FieldTypeObj.Number
                          )
                        }
                      />
                      <label
                        className={`custom-control-label ${
                          data && data.type === RealtyHelper.AdType.Buy
                            ? "bg-warning text-white"
                            : ""
                        }`}
                        for="db-travel-house-type-private"
                      >
                        <span className="d-block text-center">Vender</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="custom-control custom-block custom-control-primary">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="db-travel-house-type-shared"
                        name="db-travel-house-type"
                        checked={data?.type === RealtyHelper.AdType.Rent}
                        onClick={() =>
                          handleChange(
                            {
                              target: {
                                name: "type",
                                value: RealtyHelper.AdType.Rent,
                              },
                            },
                            FieldTypeObj.Number
                          )
                        }
                      />
                      <label
                        className={`custom-control-label ${
                          data && data.type === RealtyHelper.AdType.Rent
                            ? "bg-warning text-white"
                            : ""
                        }`}
                        for="db-travel-house-type-shared"
                      >
                        <span className="d-block text-center">Alugar</span>
                      </label>
                    </div>
                  </div>
                </div>

                <h4>Qual o tipo do seu imóvel?</h4>
                <div className="form-group row gutters-tiny items-push">
                  <div className="col-6">
                    <div className="custom-control custom-block custom-control-primary">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="realtyType-private"
                        name="realtyType"
                        checked={
                          data?.purpose === RealtyHelper.Purpose.Residential
                        }
                        onClick={() =>
                          handleChange(
                            {
                              target: {
                                name: "purpose",
                                value: RealtyHelper.Purpose.Residential,
                              },
                            },
                            FieldTypeObj.Number
                          )
                        }
                      />
                      <label
                        className={`custom-control-label ${
                          data &&
                          data.purpose === RealtyHelper.Purpose.Residential
                            ? "bg-warning text-white"
                            : ""
                        }`}
                        for="realtyType-private"
                      >
                        <span className="d-block text-center">Residencial</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="custom-control custom-block custom-control-primary">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="realtyType-shared"
                        name="realtyType"
                        checked={
                          data?.purpose === RealtyHelper.Purpose.Comercial
                        }
                        onClick={() =>
                          handleChange(
                            {
                              target: {
                                name: "purpose",
                                value: RealtyHelper.Purpose.Comercial,
                              },
                            },
                            FieldTypeObj.Number
                          )
                        }
                      />
                      <label
                        className={`custom-control-label ${
                          data &&
                          data.purpose === RealtyHelper.Purpose.Comercial
                            ? "bg-warning text-white"
                            : ""
                        }`}
                        for="realtyType-shared"
                      >
                        <span className="d-block text-center">Comercial</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <select
                    className="form-control"
                    name="realEstateType"
                    value={data && data.realEstateType}
                    onChange={(evt) => handleChange(evt)}
                  >
                    {realEstateTypeOptions &&
                      realEstateTypeOptions.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                  </select>
                </div>

                <h4>Qual o endereço do seu imóvel?</h4>

                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <span>CEP</span>
                      <InputMask
                        mask="99999-999"
                        className="form-control"
                        name="postalCode"
                        placeholder="00000-000"
                        value={data && data.postalCode}
                        onChange={(event) => {
                          handleChange({
                            target: {
                              value: event.target.value.replace(/\D/g, ""),
                              name: event.target.name,
                            },
                          });
                          if (
                            event.target.value.replace(/\D/g, "").length === 8
                          ) {
                            loadAddressByPostalCode(
                              event.target.value.replace(/\D/g, "")
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <a
                      className="btn btn-dual mt-4"
                      href="https://buscacepinter.correios.com.br/app/endereco/"
                      target="_blank" rel="noreferrer"
                    >
                      Não sabe seu CEP?
                    </a>
                  </div>

                  <div className="col-8">
                    <Field
                      name={"city"}
                      disabled={true}
                      value={data && data.city}
                      onChange={(evt) => handleChange(evt)}
                      title={"Cidade"}
                      type={FieldTypeObj.Text}
                    />
                  </div>
                  <div className="col-4">
                    <Field
                      name={"state"}
                      disabled={true}
                      value={data && data.state}
                      onChange={(evt) => handleChange(evt)}
                      title={"Estado"}
                      type={FieldTypeObj.Text}
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      name={"neighborhood"}
                      disabled={true}
                      value={data && data.neighborhood}
                      onChange={(evt) => handleChange(evt)}
                      title={"Bairro"}
                      type={FieldTypeObj.Text}
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      name={"street"}
                      value={data && data.street}
                      onChange={(evt) => handleChange(evt)}
                      title={"Endereço"}
                      type={FieldTypeObj.Text}
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name={"number"}
                      value={data && data.number}
                      onChange={(evt) => handleChange(evt)}
                      title={"Número"}
                      type={FieldTypeObj.Text}
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name={"complement"}
                      value={data && data.complement}
                      hint="Exemplo: Apartamento 16, Bloco A."
                      onChange={(evt) => handleChange(evt)}
                      title={"Complemento"}
                      type={FieldTypeObj.Text}
                    />
                  </div>
                  <div className="col-12">
                    <h4>Dados gerais do imóvel</h4>
                  </div>
                  <div className="col-6">
                    <Field
                      name={"meters"}
                      value={(data && data.meters) ?? 0}
                      onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
                      placeholder="Area em m²"
                      title={"Área útil (m²)"}
                      type={FieldTypeObj.Number}
                    />
                  </div>
                  <div className="col-6">
                    <Field
                      name={"metersTotal"}
                      value={(data && data.metersTotal) ?? 0}
                      onChange={(evt) => handleChange(evt, FieldTypeObj.Number)}
                      placeholder="Area em m²"
                      title={"Área Total (Opcional)"}
                      type={FieldTypeObj.Number}
                    />
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <span>Número de vagas:</span>
                      <br />
                      <button
                        onClick={() =>
                          setData({
                            ...data,
                            parkingLots: data.parkingLots === 1 ? 0 : 1,
                          })
                        }
                        className={
                          data &&
                          `btn m-2 ml-0 btn-rounded ${
                            data.parkingLots === 1 ? "btn-dark" : "btn-white"
                          }`
                        }
                      >
                        1
                      </button>
                      <button
                        onClick={() =>
                          setData({
                            ...data,
                            parkingLots: data.parkingLots === 2 ? 0 : 2,
                          })
                        }
                        className={
                          data &&
                          `btn m-2 btn-rounded ${
                            data.parkingLots === 2 ? "btn-dark" : "btn-white"
                          }`
                        }
                      >
                        2
                      </button>
                      <button
                        onClick={() =>
                          setData({
                            ...data,
                            parkingLots: data.parkingLots === 3 ? 0 : 3,
                          })
                        }
                        className={
                          data &&
                          `btn m-2 btn-rounded ${
                            data.parkingLots === 3 ? "btn-dark" : "btn-white"
                          }`
                        }
                      >
                        3
                      </button>
                      <button
                        onClick={() =>
                          setData({
                            ...data,
                            parkingLots: data.parkingLots === 4 ? 0 : 4,
                          })
                        }
                        className={
                          data &&
                          `btn m-2 btn-rounded ${
                            data.parkingLots === 4 ? "btn-dark" : "btn-white"
                          }`
                        }
                      >
                        4+
                      </button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <span>Número de banheiros:</span>
                      <br />
                      <button
                        onClick={() =>
                          setData({
                            ...data,
                            bathrooms: data.bathrooms === 1 ? 0 : 1,
                          })
                        }
                        className={
                          data &&
                          `btn m-2 ml-0 btn-rounded ${
                            data.bathrooms === 1 ? "btn-dark" : "btn-white"
                          }`
                        }
                      >
                        1
                      </button>
                      <button
                        onClick={() =>
                          setData({
                            ...data,
                            bathrooms: data.bathrooms === 2 ? 0 : 2,
                          })
                        }
                        className={
                          data &&
                          `btn m-2 btn-rounded ${
                            data.bathrooms === 2 ? "btn-dark" : "btn-white"
                          }`
                        }
                      >
                        2
                      </button>
                      <button
                        onClick={() =>
                          setData({
                            ...data,
                            bathrooms: data.bathrooms === 3 ? 0 : 3,
                          })
                        }
                        className={
                          data &&
                          `btn m-2 btn-rounded ${
                            data.bathrooms === 3 ? "btn-dark" : "btn-white"
                          }`
                        }
                      >
                        3
                      </button>
                      <button
                        onClick={() =>
                          setData({
                            ...data,
                            bathrooms: data.bathrooms === 4 ? 0 : 4,
                          })
                        }
                        className={
                          data &&
                          `btn m-2 btn-rounded ${
                            data.bathrooms === 4 ? "btn-dark" : "btn-white"
                          }`
                        }
                      >
                        4+
                      </button>
                    </div>
                  </div>
                  <div className="col-12">
                    <Field
                      name={"title"}
                      value={data && data.title}
                      hint={"Até 120 caracteres"}
                      maxLenght={120}
                      onChange={(evt) => handleChange(evt)}
                      placeholder="Título do anúncio"
                      title={"Título do seu anúncio"}
                      type={FieldTypeObj.Text}
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      name={"description"}
                      value={data && data.description}
                      hint={"Até 3.000 caracteres"}
                      maxLenght={3000}
                      onChange={(evt) => handleChange(evt)}
                      placeholder="Descrição do anúncio"
                      title={"Descrição"}
                      type={FieldTypeObj.TextArea}
                    />
                  </div>
                  <div className="col-12">
                    <h4>Qual o valor do seu imóvel?</h4>
                  </div>
                  <div className="col-12">
                    <Field
                      name={"value"}
                      value={data && data.value}
                      onChange={(evt) =>
                        handleChange(evt, FieldTypeObj.Currency)
                      }
                      placeholder="R$ 0,00"
                      title={
                        data && data.type === RealtyHelper.AdType.Buy
                          ? "Valor do imóvel"
                          : "Valor do aluguel"
                      }
                      type={FieldTypeObj.Currency}
                    />
                  </div>

                  <div className="col-12">
                    <button
                      className="btn btn-block btn-warning mb-4"
                      onClick={() => goSecond()}
                    >
                      Próximo passo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-8 col-xl-8 d-flex align-items-center p-2 px-sm-0">
              <div className="block block-bordered block-content mt-4">
                <h3>Agora vamos definir o melhor plano para você</h3>
                <p>Você pode começar com o plano gratuito</p>

                <div className="">
                  {plans &&
                    plans.map((item) => (
                      <div
                        key={item.id}
                        className="col-md-6 col-xl-5 js-appear-enabled animated fadeInDown"
                        data-toggle="appear"
                        data-className="animated fadeInDown"
                        data-timeout="150"
                      >
                        <div className="block block-rounded block-bordered block-themed text-center">
                          <div className="block-header bg-muted">
                            <h3 className="block-title">{item.name}</h3>
                          </div>
                          <div className="block-content bg-body-light">
                            <div className="py-2">
                              <p className="h1 font-w700 mb-2">
                                {item.value &&
                                  CurrencyHelper.Format(item.value)}
                              </p>
                              <p className="h6 text-muted">por mês</p>
                            </div>
                          </div>
                          <div className="block-content">
                            <div className="py-2">
                              {item.optionals &&
                                item.optionals.map((opt, index) => (
                                  <p key={`${item.id}_optional_${index}`}>
                                    <strong>{opt.name}</strong>
                                  </p>
                                ))}
                            </div>
                          </div>
                          <div className="block-content block-content-full bg-body-light">
                            <button
                              className="btn btn-hero-warning px-4"
                              onClick={() => selectPlan(item)}
                            >
                              <i className="fa fa-check mr-1"></i> Selecionar
                              plano
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="col-12">
                    <button
                      className="btn btn-block btn-secondary mb-4"
                      onClick={() => setStep(step - 1)}
                    >
                      Voltar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-10 col-xl-10 d-flex align-items-center p-2 px-sm-0">
              <div className="block block-bordered block-content mt-4">
                <h3>Agora vamos definir as imagens do anúncio</h3>
                <p>
                  Quanto mais detalhes tiver, melhor será a apresentação para os
                  clientes
                </p>

                <div className="row mt-3">
                  <div className="col-12 mb-4">
                    <h4 className="mb-0">
                      Adicione até 20 fotos do seu imóvel
                    </h4>
                    <small className="mt-0">
                      Você pode selecionar as principais
                    </small>
                    <br />
                    <UploadButton
                      accept="image/gif, image/jpeg, image/png"
                      title={"Selecionar fotos"}
                      upload={(files) => uploadImage(files)}
                    />{" "}
                    {loading ? (
                      <span className="">
                        <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {change &&
                    data &&
                    data.images &&
                    data.images.map((item, index) => (
                      <div
                        key={item.url}
                        className={`block block-rounded block-fx-pop col-md-4 ${
                          item.highlight
                            ? "border border-2x border-success"
                            : ""
                        }`}
                      >
                        <div className="block-content p-0 overflow-hidden">
                          <img
                            src={item.url}
                            alt=""
                            style={{
                              objectFit: "cover",
                              height: 246,
                              width: "100%",
                            }}
                          />
                        </div>
                        <div className="block-content block-content-full">
                          <div className="row">
                            <div className="col-6 p-0">
                              {item.highlight === true ? (
                                <button
                                  className="btn btn-sm btn-hero btn-noborder btn-danger btn-block"
                                  onClick={() => promoPicture(item, index)}
                                >
                                  Remover destaque
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-hero btn-noborder btn-success btn-block"
                                  onClick={() => promoPicture(item, index)}
                                >
                                  Destacar
                                </button>
                              )}
                            </div>
                            <div className="col-6 p-0">
                              <button
                                className="btn btn-sm btn-hero btn-noborder btn-dual btn-block"
                                onClick={() => removePicture(item, index)}
                              >
                                <i className="si si-close text-danger mr-1" />{" "}
                                Remover
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                  <div className="col-12 mt-3 mb-4">
                    <h4 className="mb-0">
                      Bora adicionar os stories do seu imóvel?
                    </h4>
                    <small className="mt-0">
                      Anúncios com stories aumentam a taxa de interessados
                    </small>
                    <br />
                    <UploadButton
                      accept="video/*"
                      title={"Selecionar videos"}
                      upload={(files) => uploadStories(files)}
                    />{" "}
                    {loading ? (
                      <span className="">
                        <i className="fa fa-2x fa-circle-notch fa-spin text-info" />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  {data &&
                    data.stories &&
                    data.stories.map((item, index) => (
                      <div
                        key={item.url}
                        className="block block-rounded block-fx-pop col-md-4"
                      >
                        <div className="block-content p-0 overflow-hidden">
                          <video
                            src={item.url}
                            className="img-fluid"
                            width={"100%"}
                            height={246}
                            controls={true}
                          />
                        </div>
                        <div className="block-content block-content-full">
                          <div className="row">
                            <div className="col-12">
                              <button
                                className="btn btn-sm btn-hero btn-noborder btn-dual btn-block"
                                onClick={() => removePicture(item)}
                              >
                                <i className="si si-close text-danger mr-1" />{" "}
                                Remover story
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="col-12"></div>
                  <div className="col-6">
                    <button
                      className="btn btn-block btn-secondary mb-4"
                      onClick={() => setStep(step - 1)}
                    >
                      Voltar
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-block btn-warning mb-4"
                      onClick={() => endPictureStep()}
                    >
                      Avançar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-8 col-xl-8 d-flex align-items-center p-2 px-sm-0">
              <div className="block block-bordered block-content mt-4">
                <h3>Agora vamos definir os Detalhes do anúncio</h3>
                <p>Falta pouco para o seu anúncio ser publicado!</p>

                <div className="row mt-3">
                  <div className="col-12 mt-3 mb-4">
                    <h4 className="mb-0">
                      Quais são os diferenciais desse imóvel?
                    </h4>
                    <small className="mt-0">
                      Selecione as comodidades do seu imóvel
                    </small>
                  </div>
                  {change &&
                    amenities &&
                    amenities.map((amenity, amenityIndex) => (
                      <div key={amenity.id} className="row ml-2 col-12">
                        <h6 className="col-12">{amenity.name}</h6>
                        {change &&
                          amenity.optionals &&
                          amenity.optionals.map((optional, optionalIndex) => (
                            <div
                              key={`${amenity.id}_${optional.id}`}
                              className="col-4"
                            >
                              <div className="form-group">
                                <div className="custom-control custom-switch mb-1">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`optional-${optionalIndex}-${amenity.id}`}
                                    name={`optional-${optionalIndex}-${amenity.id}`}
                                    checked={optional.active}
                                    onChange={(evt) =>
                                      selectAmenity(
                                        evt,
                                        amenityIndex,
                                        optionalIndex
                                      )
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    for={`optional-${optionalIndex}-${amenity.id}`}
                                  >
                                    {optional.name}
                                  </label>
                                  {console.log("Será?", optional.description)}
                                  {optional.active && optional.describe ? (
                                    <div className="form-group">
                                      <span>Descreva</span>
                                      <input
                                        className="form-control"
                                        value={
                                          (change &&
                                            amenity &&
                                            optional.active &&
                                            optional.description) ||
                                          ""
                                        }
                                        name={"description"}
                                        onChange={(evt) =>
                                          handleAmenityOptionalChange(
                                            evt,
                                            FieldTypeObj.Text,
                                            amenityIndex,
                                            optionalIndex
                                          )
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}

                  <div className="col-12 mt-5 mb-3">
                    <h4 className="mb-0">Quais são os custos do imóvel?</h4>
                    <small className="mt-0">Por exemplo o valor do IPTU</small>
                  </div>
                  <form className="col-12 row" onSubmit={(e) => saveCost(e)}>
                    <div className="col-5">
                      <Field
                        title={"Nome"}
                        name={"name"}
                        value={cost.name}
                        type={FieldTypeObj.Text}
                        onChange={(evt) =>
                          handleCostChange(evt, FieldTypeObj.Text)
                        }
                      />
                    </div>
                    <div className="col-5">
                      <Field
                        title={"Valor"}
                        name={"value"}
                        value={cost.value}
                        type={FieldTypeObj.Currency}
                        onChange={(evt) =>
                          handleCostChange(evt, FieldTypeObj.Currency)
                        }
                      />
                    </div>
                    <div className="col-2">
                      <button
                        className="btn btn-success btn-block mt-4"
                        type="submit"
                      >
                        Adicionar
                      </button>
                    </div>
                  </form>
                  <div className="col-12">
                    {change &&
                      data &&
                      data.costs &&
                      data.costs.map((item) => (
                        <div
                          key={StringHelper.GenerateUniqueId()}
                          className="block block-rounded block-bordered"
                        >
                          <div className="block-header">
                            <div className="block-title">
                              {item.name} - Valor:{" "}
                              {CurrencyHelper.Format(item.value)}
                            </div>
                            <div className="block-tools">
                              <button className="btn btn-dual">
                                <i className="si si-close text-danger" />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="col-12 mt-3 mb-3">
                    <div className="custom-control custom-switch mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`automaticRefusal`}
                        checked={data.automaticRefusal}
                        name={"automaticRefusal"}
                        onChange={(evt) =>
                          handleChange(evt, FieldTypeObj.Checkbox)
                        }
                      />
                      <label
                        className="custom-control-label"
                        for={`automaticRefusal`}
                      >
                        <h4 className="mb-0">
                          Deseja habilitar a negociação automática?
                        </h4>
                      </label>
                    </div>

                    <small className="mt-0">
                      Ela permite validar os interessados verificando o valor
                      que deseja investir antes de abrir o chat
                    </small>
                  </div>
                  {data.automaticRefusal ? (
                    <div className="col-12 mb-4 row">
                      <div className="col-md-6">
                        <Field
                          hint={
                            "Essa informação ficará escondida e o sistema de negociação tentará fazer com que o cliente contrate no valor mais justo"
                          }
                          title={"Qual o menor valor que aceitaria?"}
                          name={"minimalValue"}
                          defaultValue={data.value}
                          value={data.minimalValue}
                          type={FieldTypeObj.Currency}
                          onChange={(evt) =>
                            handleChange(evt, FieldTypeObj.Currency)
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          type={FieldTypeObj.Select}
                          name={"asset"}
                          value={data && data.asset}
                          title={"Aceita algum ativo como parte do pagamento?"}
                          options={[
                            { name: "Não", value: false },
                            { name: "Sim", value: true },
                          ]}
                          nameOption={"name"}
                          keyOption={"value"}
                          onChange={(evt) =>
                            handleChange(evt, FieldTypeObj.Bool)
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-6">
                    <button
                      className="btn btn-block btn-secondary mb-4"
                      onClick={() => setStep(step - 1)}
                    >
                      Voltar
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-block btn-warning mb-4"
                      onClick={() => endRegistration()}
                    >
                      Avançar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="row justify-content-center">
            <div className="col-sm-10 col-md-8 col-xl-8 d-flex align-items-center p-2 px-sm-0">
              <div className="block block-bordered block-content mt-5 pt-5 text-center">
                <i className="si si-check fa-5x text-success py-4 mt-8" />
                <br />
                <br />
                <h2 className="mb-0">Parabéns seu anúncio foi publicado!</h2>
                <p className="mt-0 pt-0">
                  Você pode acompanhar o andamento na página anúncios que em
                  breve os clientes entrarão em contato
                </p>
                <br />
                <a
                  className="btn btn-warning text-white btn-lg mb-5"
                  href="#/anuncios"
                >
                  Ver meus anúncios
                </a>
              </div>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  return (
    <>
      <div className="content">
        {step !== 5 ? (
          <div className="d-flex align-items-center justify-content-between ml-5 mr-5 mt-3">
            <div className="text-center">
              <button
                type="button"
                className={
                  step &&
                  `btn btn-rounded ${
                    step === 1 ? "btn-warning" : "btn-outline-warning"
                  }`
                }
                onClick={() => setStep(1)}
              >
                1
              </button>
              <br />
              <span>Sobre o imóvel</span>
            </div>
            <div className="text-center">
              <button
                type="button"
                className={
                  step &&
                  `btn btn-rounded ${
                    step === 2 ? "btn-warning" : "btn-outline-warning"
                  }`
                }
                onClick={() => setStep(2)}
              >
                2
              </button>
              <br />
              <span>Planos e Preços</span>
            </div>
            <div className="text-center">
              <button
                type="button"
                className={
                  step &&
                  `btn btn-rounded ${
                    step === 3 ? "btn-warning" : "btn-outline-warning"
                  }`
                }
                onClick={() => setStep(3)}
              >
                3
              </button>
              <br />
              <span>Fotos & vídeos</span>
            </div>
            <div className="text-center">
              <button
                type="button"
                className={
                  step &&
                  `btn btn-rounded ${
                    step === 4 ? "btn-warning" : "btn-outline-warning"
                  }`
                }
                onClick={() => setStep(4)}
              >
                4
              </button>
              <br />
              <span>Detalhes do anúncio</span>
            </div>
          </div>
        ) : (
          ""
        )}
        {step && currentStep()}
      </div>
      <Loading loading={loading} />
      <ToastContainer className="toastr" />
    </>
  );
}
