import React from "react";

export function ResumeCard({ title, preview, current, currentDiference, hint }) {

    return (
        <a
            className="border-left border-success border-3x block block-rounded block-link-pop"
            href="javascript:void(0)"
        >
            <div className="block-header">
                <div className="block-title">{title}</div>
                <div className="block-tools">
                    {hint !== undefined ?
                        <button className="btn btn-dual" title={hint}>
                            <i className="far fa-question-circle" />
                        </button>
                        : ""}
                <div className="ml-3 text-center">
                    <p className="font-size-h3 font-w300 mb-0 mr-3">
                        {current}
                    </p>
                    <p className="text-muted mb-0"></p>
                </div>
                </div>
            </div>
        </a>
    )
}