import { Timestamp } from "firebase/firestore"; // Assuming Firestore from Firebase

export function DateToPast(date) {
  let dta = Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60);

  if (dta < 60) {
    return `${Math.floor(dta)} minutos atrás`;
  } else {
    dta = dta / 60;

    if (Math.floor(dta) < 24) {
      return `${Math.floor(dta)} horas atrás`;
    } else {
      dta = dta / 24;
      return `${Math.floor(dta)} dias atrás`;
    }
  }
}

export function GetHours(dte) {
  const date = dte instanceof Timestamp ? dte.toDate() : new Date(dte);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${hours}:${minutes}`;
  return formatted;
}

export function DateIsNear(date) {
  let dta = Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60);
  return Math.floor(dta);
}

export function IsTodayDate(date) {
  let dta = Math.floor(Math.abs(new Date() - new Date(date)) / 1000 / 60);

  if (dta < 60) {
    // return Math.floor(dta); //minutos
    return true;
  } else {
    dta = dta / 60;

    if (Math.floor(dta) <= 12) {
      // return Math.floor(dta); //horas
      return true;
    } else {
      // dta = dta / 24;
      // return Math.floor(dta);//dias
      return false;
    }
  }
}

export function DateTranslate(dte) {
  const date = dte instanceof Timestamp ? dte.toDate() : new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month}/${year} ${hours}:${minutes}`;
  return formatted;
}

export function OnlyDateTranslate(dte) {
  const date = dte instanceof Timestamp ? dte.toDate() : new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month}/${year}`;
  return formatted;
}

export function DateTranslateSimple(dte) {
  const date = dte instanceof Timestamp ? dte.toDate() : new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${day}/${month} ${hours}:${minutes}`;
  return formatted;
}

export function DateTranslateToInput(dte) {
  const date = dte instanceof Timestamp ? dte.toDate() : new Date(dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const formatted = `${year}-${month}-${day}T12:00`;
  return formatted;
}

export function DateTranslateToTimestamp(dte) {
  let s = dte;
  let [dia, mes, ano, hora, minuto] = s.split(/[\/: ]/).map((v) => parseInt(v));

  let data = new Date(ano, mes - 1, dia, hora, minuto);
  return DateTranslateToInput(data);
}

export function DateTranslateFromTimestamp(dte) {
  var date = new Date(+dte);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const formatted = `${day}/${month}/${year}`;

  return formatted;
}

export function GetTimestamp(dte) {
  let s = dte;
  let [dia, mes, ano, hora, minuto] = s.split(/[\/: ]/).map((v) => parseInt(v));

  let data = new Date(ano, mes - 1, dia, hora, minuto);
  return data.getTime();
}

export function DateTimeNow() {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const formatted = `${year}-${month}-${day}T${
    hours < 10 ? `0${hours}` : hours
  }:${minutes}`;
  return formatted;
}

export function ProcessDates(list) {
  for (let s in list) {
    list[s].createdDate = DateTranslate(list[s].createdDate);
    list[s].updatedDate = DateTranslate(list[s].updatedDate);
  }
  return list;
}

export const ArrayMes = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

function getMesExtenso(mes) {
  return ArrayMes[mes];
}

export function GetMes() {
  const dataAtual = new Date();
  const mes = getMesExtenso(dataAtual.getMonth());

  return mes;
}

export function GetDia() {
  const dataAtual = new Date();
  const dia = dataAtual.getDate();

  return dia;
}

export function GetTerm(days) {
  var date = new Date();
  date.setDate(date.getDate() + days);
  return date;
}
