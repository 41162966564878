import {
  DateTranslateToInput,
  DateTranslateToTimestamp,
  GetTimestamp,
} from "./DateTranslater";

const Functions = {
  validateEmail: function (email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  validateDocument: function (document) {
    const documentWithoutDots = document.toString().replace(/[^\d]+/g, "");

    const validateCnpj = (cnpj) => {
      if (cnpj === null) {
        return true;
      }
      cnpj = cnpj.replace(/[^\d]+/g, "");
      if (cnpj === "") {
        return false;
      }

      if (cnpj.length !== 14) {
        return false;
      }

      if (
        cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999"
      ) {
        return false;
      }

      let size = cnpj.length - 2;
      let numbers = cnpj.substring(0, size);
      const digits = cnpj.substring(size);
      let sum = 0;
      let pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result.toString() !== digits.charAt(0)) {
        return false;
      }
      size = size + 1;
      numbers = cnpj.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) {
          pos = 9;
        }
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result.toString() !== digits.charAt(1)) {
        return false;
      }
      return true;
    };

    const validateCpf = (cpf) => {
      cpf = cpf.replace(/\D/g, "");
      if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false;
      }
      let result = true;
      [9, 10].forEach(function (j) {
        let soma = 0,
          r;
        cpf
          .split(/(?=)/)
          .splice(0, j)
          .forEach(function (e, i) {
            // tslint:disable-next-line:radix
            soma += parseInt(e) * (j + 2 - (i + 1));
          });
        r = soma % 11;
        r = r < 2 ? 0 : 11 - r;
        if (r.toString() !== cpf.substring(j, j + 1)) {
          result = false;
        }
      });
      return result;
    };

    //validation
    if (documentWithoutDots.length === 11) {
      return validateCpf(document);
    } else if (documentWithoutDots.length === 14) {
      return validateCnpj(document);
    } else {
      return false;
    }
    //END
  },

  ProcessFilter: function (param, data) {
    let olditens = data;
    let itens = [];
    if (param.direction === "ASC") {
      itens = olditens.sort(function (a, b) {
        if (a[param.orderBy] < b[param.orderBy]) {
          return -1;
        }
        if (a[param.orderBy] > b[param.orderBy]) {
          return 1;
        }
        return 0;
      });
    }
    if (param.direction === "DESC") {
      itens = olditens.sort(function (a, b) {
        if (a[param.orderBy] > b[param.orderBy]) {
          return -1;
        }
        if (a[param.orderBy] < b[param.orderBy]) {
          return 1;
        }
        return 0;
      });
    }

    if (param.page !== undefined) {
      let base = (param.page - 1) * 100;
      let max = param.page * 100 - 1;


      return itens.slice(base, max);
    } else {
      return itens;
    }
  },

  FilterPage: function (page, data) {
    let response = [];
    for (var i = (page - 1) * 100; i < page * 100; i++) {
      response.push(data[i]);
    }
    return response;
  },
};

export default Functions;
